/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetFields } from "redux/fieldSlice";
import TemplateBuilderMiddleInner from "./TemplateBuilderMiddleInner";
import BuilderPageContainer from "Components/BuilderPageContainer/BuilderPageContainer";
import CustomDropComponent from "Components/CustomDropComponent/CustomDropComponent";
import "./TemplateBuilderMiddleSide.less";

function TemplateBuilderMiddleSide({ currentPdfObject, setCurrentPdfObject, singleTemplate, children }) {
    const dispatch = useDispatch();
    const scrollPosition = useRef(null);

    useEffect(() => {
        dispatch(resetFields());
    }, []);

    const templateImages = singleTemplate?.attributes?.templateImages || [];

    const handleMouseEvents = (event, currentPageNo = 1) => {
        event.preventDefault();
        setCurrentPdfObject({ ...currentPdfObject, pageNumber: currentPageNo });
    };
    const scrollEvent = (e) => {
        scrollPosition.current = e.target.scrollTop;
    };
    return (
        <div onScrollCapture={scrollEvent} id="template-builder-middle-side-main-container">
            {templateImages.length > 0 &&
                templateImages.map((singleTemplateImage, index) => {
                    const currentPageNo = index + 1;
                    const imageUrl = singleTemplateImage?.images?.path;
                    const builderProps = { currentPageNo, handleMouseEvents, imageUrl, children };
                    const templateBuilderMiddleInnerProps = {
                        currentPageNo
                    };
                    const customDropComponentProps = {
                        currentPdfObject,
                        position: scrollPosition.current
                    };
                    return (
                        <BuilderPageContainer key={index} {...builderProps}>
                            <CustomDropComponent {...customDropComponentProps}>
                                <TemplateBuilderMiddleInner {...templateBuilderMiddleInnerProps} />
                            </CustomDropComponent>
                        </BuilderPageContainer>
                    );
                })}
        </div>
    );
}

export default memo(TemplateBuilderMiddleSide);
