import { createSlice } from "@reduxjs/toolkit";

import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";
import { defaultFieldObject } from "Components/TemplateBuilderWrapper/FormFields/constant";

const initialState = {
    fields: [],
    singleFieldId: null,
    scrollY: 0
    // queryFields: []
};

export const fieldSlice = createSlice({
    name: "fields",
    initialState,
    reducers: {
        updateQueryParamsToFields: (state, { payload }) => {
            let fieldCloneForUpdate = UtilService.deepClone(state.fields);

            //if payrate is exist we will use services

            let queryFields = UtilService.deepClone(payload);

            //custom services work
            const SERVICES = {
                CFC: "CFC",
                ISS: "ISS",
                HH: "HH",
                REH: "REH"
            };

            const queryNamesOfServices = {
                cfcRate: "cfcRate",
                issRate: "issRate",
                hhRate: "hhRate",
                rehRate: "rehRate",
                firstNameLastName: "firstNameLastName",
                lastNameFirstName: "lastNameFirstName"
            };

            const services = "services";

            const findServiceKey = queryFields?.find((singleQueryField) => singleQueryField.queryName === services);

            let findCfcRate;
            let findIssRate;
            let findHhRate;
            let findRehRate;

            if (findServiceKey) {
                const serviceValues = findServiceKey.value;
                findCfcRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.CFC)?.rate;
                findIssRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.ISS)?.rate;
                findHhRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.HH)?.rate;
                findRehRate = serviceValues?.find((singleService) => singleService.service_name === SERVICES.REH)?.rate;
            }

            //custom services work

            fieldCloneForUpdate.forEach(({ queryName, value }, index) => {
                //custom services work
                if (queryName === queryNamesOfServices.cfcRate) {
                    fieldCloneForUpdate[index].value = findCfcRate;
                }

                if (queryName === queryNamesOfServices.hhRate) {
                    fieldCloneForUpdate[index].value = findHhRate;
                }

                if (queryName === queryNamesOfServices.issRate) {
                    fieldCloneForUpdate[index].value = findIssRate;
                }

                if (queryName === queryNamesOfServices.rehRate) {
                    fieldCloneForUpdate[index].value = findRehRate;
                }

                //custom services work
                const obj = queryFields?.find((item) => item?.queryName === queryName);
                if (!!obj) {
                    if (
                        fieldCloneForUpdate[index].type !==
                        (FIELD_TYPES.signature || FIELD_TYPES.checkbox || FIELD_TYPES.radiobtn)
                    ) {
                        fieldCloneForUpdate[index].value = obj?.value;
                    }
                }
            });

            state.fields = fieldCloneForUpdate;
        },
        resetFields: (state) => {
            state.fields = [];
        },
        saveField: (state, { payload }) => {
            if (payload) {
                let fieldClone = UtilService.deepClone(state.fields);
                state.fields = [...fieldClone, payload];
            }
        },
        deleteField: (state, { payload }) => {
            let fieldClone = UtilService.deepClone(state.fields);
            const filteredFields = fieldClone.filter((singleField) => singleField.id !== payload.id);
            state.fields = filteredFields;
        },
        deleteFieldSigned: (state, { payload }) => {
            //delete signed field from fields array
            let fieldCloneForUpdate = UtilService.deepClone(state.fields);
            const filteredFields = fieldCloneForUpdate.filter((singleField) => singleField.id !== payload.id);
            fieldCloneForUpdate = filteredFields;

            //delete children field from original signature
            const originalSignatureFieldId = state.singleFieldId;

            const findOriginalFieldIndex = fieldCloneForUpdate.findIndex(
                (singleField) => singleField.id === originalSignatureFieldId
            );

            const filteredOriginalFieldChildrens = fieldCloneForUpdate[findOriginalFieldIndex].childrens.filter(
                (singleChildren) => singleChildren.id !== payload.id
            );

            fieldCloneForUpdate[findOriginalFieldIndex].childrens = filteredOriginalFieldChildrens;
            state.fields = fieldCloneForUpdate;
        },
        updateField: (state, { payload }) => {
            // console.log("deep clone");
            // console.time();
            const fieldCloneForUpdate = UtilService.deepClone(state.fields);
            // const fieldCloneForUpdate = [...state.fields];
            // console.timeEnd();

            // console.log("find field");
            // console.time();
            const findIndex = fieldCloneForUpdate.findIndex((singleField) => singleField.id === payload.id);
            // console.timeEnd();

            // console.log("update field");
            // console.time();
            if (findIndex !== -1) {
                fieldCloneForUpdate[findIndex] = {
                    ...fieldCloneForUpdate[findIndex],
                    ...payload
                };

                state.fields = fieldCloneForUpdate;
            }
            // console.timeEnd();
        },
        updateSignatureFormValue: (state, { payload }) => {
            const fieldCloneForUpdate = UtilService.deepClone(state.fields);
            const findIndex = fieldCloneForUpdate.findIndex((singleField) => singleField.id === payload.id);

            if (findIndex !== -1) {
                const findSignatureField = fieldCloneForUpdate[findIndex];
                fieldCloneForUpdate[findIndex] = {
                    ...findSignatureField,
                    ...payload
                };

                if (findSignatureField.childrens?.length > 0) {
                    findSignatureField.childrens.forEach((singleChildren) => {
                        const findIndexChildren = fieldCloneForUpdate.findIndex(
                            (singleField) => singleField.id === singleChildren.id
                        );

                        const { currentDateTime } = payload;

                        if (findIndexChildren !== -1) {
                            fieldCloneForUpdate[findIndexChildren].value = currentDateTime;
                        }
                    });
                }
            }

            state.fields = fieldCloneForUpdate;
        },
        updateAllFields: (state, { payload }) => {
            console.log("this is called form redux");
            state.fields = payload;
        },
        saveSingleFieldId: (state, { payload }) => {
            state.singleFieldId = payload;
        },
        //checkbox work
        deleteCheckboxOption: (state, { payload }) => {
            const fieldClone = UtilService.deepClone(state.fields);

            const findMainCheckboxIndex = UtilService.findMainCheckboxIndex(payload.groupId, fieldClone);
            const filteredCheckbox = fieldClone[findMainCheckboxIndex].childrens.filter(
                (singleCheckBoxFilter) => singleCheckBoxFilter !== payload.id
            );
            fieldClone[findMainCheckboxIndex].childrens = filteredCheckbox;

            //delete checkbox and also delete it from its childrens array
            const filteredFields = fieldClone.filter((singleField) => singleField.id !== payload.id);
            state.fields = filteredFields;
        },
        addCheckboxOption: (state, { payload }) => {
            const singleField = payload;
            const { groupId } = singleField;
            let fieldClone = UtilService.deepClone(state.fields);
            const findMainCheckbox = UtilService.findMainCheckboxElement(groupId, fieldClone);

            const unique_id = UtilService.generateUniqueId();
            const multiplyValueOfyAxis = 5 * findMainCheckbox.childrens.length;
            const multiplyValueOfxAxis = 5 * findMainCheckbox.childrens.length;

            const fieldsData = {
                ...defaultFieldObject,
                id: unique_id,
                type: FIELD_TYPES.checkbox,
                pageNumber: findMainCheckbox.pageNumber,
                groupId: groupId,
                x: findMainCheckbox.x + multiplyValueOfxAxis,
                y: findMainCheckbox.y + multiplyValueOfyAxis,
                value: false,
                width: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.width,
                height: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.height
            };

            //save fields
            fieldClone = [...fieldClone, fieldsData];

            //update field
            const fieldCloneForUpdate = [...fieldClone];
            const findIndex = fieldCloneForUpdate.findIndex(
                (singleFieldClone) => singleFieldClone.id === singleField.id
            );

            if (findIndex !== -1) {
                const findObject = fieldCloneForUpdate[findIndex];
                fieldCloneForUpdate[findIndex] = {
                    ...fieldCloneForUpdate[findIndex],
                    childrens: [...findObject.childrens, unique_id]
                };

                state.fields = fieldCloneForUpdate;
            }
        },

        addSignatureDateSigned: (state, { payload }) => {
            const { id: fieldIdParam } = payload;
            let fieldClone = UtilService.deepClone(state.fields);

            const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === fieldIdParam);

            const unique_id = UtilService.generateUniqueId();

            //add new array in fields
            const mainSignature = fieldClone[findIndex];
            const oldSignedChildrens = mainSignature.childrens;

            const calculateYAxisSpace =
                oldSignedChildrens.length === 0 ? mainSignature.height + 10 : mainSignature.height;

            const fieldsData = {
                ...defaultFieldObject,
                id: unique_id,
                type: FIELD_TYPES.signature_signed_date,
                pageNumber: fieldClone[findIndex].pageNumber,
                parentId: fieldClone[findIndex].id,
                groupId: mainSignature.groupId,
                x: mainSignature.x,
                y: mainSignature.y + calculateYAxisSpace * (oldSignedChildrens.length + 1),
                value: ""
            };

            fieldClone = [...fieldClone, fieldsData];

            //connect with date field

            const childrenDropdown = {
                id: unique_id
            };

            if (findIndex !== -1) {
                fieldClone[findIndex].childrens = [...fieldClone[findIndex].childrens, childrenDropdown];
            }

            state.fields = fieldClone;
        },
        //radio button work
        deleteRadioButtonOption: (state, { payload }) => {
            const fieldClone = UtilService.deepClone(state.fields);
            const findMainRadioIndex = UtilService.findMainRadioIndex(payload.groupId, fieldClone);
            const filteredRadio = fieldClone[findMainRadioIndex].childrens.filter(
                (singleRadioFilter) => singleRadioFilter !== payload.id
            );
            fieldClone[findMainRadioIndex].childrens = filteredRadio;

            //delete radio and also delete it from its childrens array
            const filteredFields = fieldClone.filter((singleField) => singleField.id !== payload.id);
            state.fields = filteredFields;
        },
        addRadioButtonOption: (state, { payload }) => {
            const singleField = payload;
            const { groupId } = singleField;
            let fieldClone = UtilService.deepClone(state.fields);
            const findMainRadio = UtilService.findMainRadioElement(groupId, fieldClone);

            const unique_id = UtilService.generateUniqueId();
            const multiplyValueOfyAxis = 5 * findMainRadio.childrens.length;
            const multiplyValueOfxAxis = findMainRadio.x + 5 * findMainRadio.childrens.length;

            const fieldsData = {
                ...defaultFieldObject,
                id: unique_id,
                type: FIELD_TYPES.radiobtn,
                pageNumber: findMainRadio.pageNumber,
                groupId: groupId,
                x: multiplyValueOfxAxis,
                y: findMainRadio.y + multiplyValueOfyAxis,
                value: false,
                width: FIELD_STATIC_WIDTH.RADIO_BUTTON.width,
                height: FIELD_STATIC_WIDTH.RADIO_BUTTON.height
            };

            //save fields
            fieldClone = [...fieldClone, fieldsData];

            //update field
            const fieldCloneForUpdate = [...fieldClone];
            const findIndex = fieldCloneForUpdate.findIndex(
                (singleFieldClone) => singleFieldClone.id === singleField.id
            );

            if (findIndex !== -1) {
                const findObject = fieldCloneForUpdate[findIndex];
                fieldCloneForUpdate[findIndex] = {
                    ...fieldCloneForUpdate[findIndex],
                    childrens: [...findObject.childrens, unique_id]
                };

                state.fields = fieldCloneForUpdate;
            }
        },
        radioOptionOnChangeChecked: (state, { payload }) => {
            const { checked, singleRepeaterField } = payload;

            const { groupId } = singleRepeaterField;
            const fieldClone = UtilService.deepClone(state.fields);

            const findMainRadio = UtilService.findMainRadioElement(groupId, fieldClone);
            const mainRadioChildrens = findMainRadio.childrens || [];

            if (mainRadioChildrens.length > 0) {
                mainRadioChildrens.forEach((singleField) => {
                    const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === singleField);
                    if (findIndex !== -1) {
                        fieldClone[findIndex].value = false;
                        fieldClone[findIndex].checked = false;
                    }
                });
            }

            const findIndex = fieldClone.findIndex(
                (singleFieldRedux) => singleFieldRedux.id === singleRepeaterField.id
            );

            if (findIndex !== -1) {
                fieldClone[findIndex].value = checked;
                fieldClone[findIndex].checked = checked;
                state.fields = fieldClone;
            }
        },
        radioOptionOnChangeCheckedEnvelopeView: (state, { payload }) => {
            const { singleRepeaterField, value } = payload;

            const { groupId } = singleRepeaterField;

            const fieldClone = UtilService.deepClone(state.fields);

            const findMainRadio = UtilService.findMainRadioElement(groupId, fieldClone);
            const mainRadioChildrens = findMainRadio.childrens || [];

            if (mainRadioChildrens.length > 0) {
                mainRadioChildrens.forEach((singleField) => {
                    const findIndex = fieldClone.findIndex((singleFieldRedux) => singleFieldRedux.id === singleField);
                    if (findIndex !== -1) {
                        fieldClone[findIndex].checked = false;
                        fieldClone[findIndex].value = false;
                    }
                });
            }

            const findIndex = fieldClone.findIndex(
                (singleFieldRedux) => singleFieldRedux.id === singleRepeaterField.id
            );

            if (findIndex !== -1) {
                fieldClone[findIndex].value = value;
                fieldClone[findIndex].checked = true;
                fieldClone[findIndex].validationStatus = "";
            }

            state.fields = fieldClone;
        },
        addDropdownOption: (state, { payload }) => {
            const { id: fieldIdParam } = payload;

            const findIndex = state.fields.findIndex((singleFieldRedux) => singleFieldRedux.id === fieldIdParam);

            const unique_id = UtilService.generateUniqueId();

            const childrenDropdown = {
                id: unique_id,
                option: "",
                value: ""
            };

            if (findIndex !== -1) {
                state.fields[findIndex].childrens = [...state.fields[findIndex].childrens, childrenDropdown];
            }
        },
        removeDropDownOption: (state, { payload }) => {
            const { parentFieldId, childrenFieldId } = payload;
            const findIndex = state.fields.findIndex((singleFieldRedux) => singleFieldRedux.id === parentFieldId);

            if (findIndex !== -1) {
                const childrensOfDropdown = [...state.fields[findIndex].childrens];

                const filteredDropdownChildrens = childrensOfDropdown.filter(
                    (singleChildrenDropdown) => singleChildrenDropdown.id !== childrenFieldId
                );

                state.fields[findIndex].childrens = filteredDropdownChildrens;
            }
        },
        updateDropDownOption: (state, { payload }) => {
            const { parentFieldId, childrenFieldId, updateValues } = payload;

            const fieldClone = UtilService.deepClone(state.fields);
            const parentFieldfindIndex = fieldClone.findIndex(
                (singleFieldRedux) => singleFieldRedux.id === parentFieldId
            );

            if (parentFieldfindIndex !== -1) {
                const childrensOfDropdown = fieldClone[parentFieldfindIndex].childrens;

                const findChildrenIndex = childrensOfDropdown.findIndex(
                    (singleChildrenDropdown) => singleChildrenDropdown.id === childrenFieldId
                );

                if (findChildrenIndex !== -1) {
                    childrensOfDropdown[findChildrenIndex] = {
                        ...childrensOfDropdown[findChildrenIndex],
                        ...updateValues
                    };
                }
                state.fields = fieldClone;
            }
        },
        scrollPosition: (state, { payload }) => {
            state.scrollY = payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    radioOptionOnChangeCheckedEnvelopeView,
    updateDropDownOption,
    addDropdownOption,
    radioOptionOnChangeChecked,
    saveSingleFieldId,
    saveField,
    deleteField,
    updateField,
    updateAllFields,
    addCheckboxOption,
    deleteCheckboxOption,
    deleteRadioButtonOption,
    addRadioButtonOption,
    removeDropDownOption,
    resetFields,
    scrollPosition,
    addSignatureDateSigned,
    deleteFieldSigned,
    updateQueryParamsToFields,
    updateSignatureFormValue
} = fieldSlice.actions;

export default fieldSlice.reducer;

