import React from "react";
import TemplateBuilderWrapper from "Components/TemplateBuilderWrapper/TemplateBuilderWrapper";
import TemplateBuilderMiddleSide from "./MiddleSide/TemplateBuilderMiddleSide";

function TemplateBuilderView() {
    return (
        <TemplateBuilderWrapper>
            <TemplateBuilderMiddleSide />
        </TemplateBuilderWrapper>
    );
}

export default TemplateBuilderView;
