/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, memo, Children, cloneElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TemplateBuilderRightSide from "./RightSide/TemplateBuilderRightSide";
import TemplateBuilderLeftSide from "./LeftSide/TemplateBuilderLeftSide";
import { defaultFieldObject } from "./FormFields/constant";
import { saveSingleFieldId, updateAllFields, updateField } from "redux/fieldSlice";
import { useQueryGetTemplateById } from "queries/template.queries";
import { FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";
import "./TemplateBuilderWrapper.less";

function TemplateBuilderWrapper({ children, isSignatureDeleteEnable = false, className = "" }, ...props) {
    const fields = useSelector((state) => state.fields.fields);
    const [loading, setLoading] = useState(false);
    const [currentPdfObject, setCurrentPdfObject] = useState({
        pageNumber: 1,
        imageUrl: ""
    });

    const { template_id } = useParams();
    const dispatch = useDispatch();

    const {
        reviewer,
        isFormEditible = false,
        showButtons = 0
    } = useMemo(() => UtilService.urlParameters(), [window.location.search]);

    const review = reviewer && JSON.parse(reviewer);
    // HOTFIX : Allow user to edit the form if send on email
    const isUserAllowedToEditForm = isFormEditible && JSON.parse(isFormEditible);
    const { data: singleTemplate, isFetching: getTemplateByIdLoading } = useQueryGetTemplateById(
        template_id,
        (data) => {
            if (getTemplateByIdLoading) {
                // const {attributes: {templateImages}} = data
                const firstTemplateImage = data?.attributes?.templateImages?.find(() => true)?.images?.path;

                const fullImageUrl = firstTemplateImage;
                setCurrentPdfObject({
                    ...currentPdfObject,
                    imageUrl: fullImageUrl
                });

                const tempTabData = data?.attributes?.templateDetail?.tabs;

                if (tempTabData) {
                    if (tempTabData.length > 0) {
                        const tabData = tempTabData.map((singleField) => {
                            return {
                                ...defaultFieldObject,
                                ...singleField
                            };
                        });

                        dispatch(updateAllFields(tabData));
                    }
                }
            }
        },
        isSignatureDeleteEnable
    );

    const trackPositions = useCallback(
        (data, singleFieldParam) => {
            dispatch(
                updateField({
                    id: singleFieldParam.id,
                    x: Math.floor(data.x),
                    y: Math.floor(data.y)
                })
            );
        },
        [fields]
    );

    const selectFieldHandler = (event, singleFieldParam) => {
        event.preventDefault();

        if (singleFieldParam.type === FIELD_TYPES.checkbox && singleFieldParam.mainCheckbox === false) {
            const cloneFields = UtilService.deepClone(fields);

            const filteredGroupId = cloneFields.filter(
                (singleField) => singleField.groupId === singleFieldParam.groupId
            );

            if (filteredGroupId.length > 1) {
                const findMainCheckboxObject = filteredGroupId.find(
                    (singleFieldCheckbox) => singleFieldCheckbox.mainCheckbox
                );
                dispatch(saveSingleFieldId(findMainCheckboxObject.id));
            } else {
                dispatch(saveSingleFieldId(singleFieldParam.id));
            }
        } else if (singleFieldParam.type === FIELD_TYPES.radiobtn) {
            const cloneFields = UtilService.deepClone(fields);

            const filteredGroupId = cloneFields.filter(
                (singleField) => singleField.groupId === singleFieldParam.groupId
            );

            if (filteredGroupId.length > 1) {
                const findMainRadioObject = filteredGroupId.find((singleFieldRadio) => singleFieldRadio.mainRadio);
                dispatch(saveSingleFieldId(findMainRadioObject.id));
            } else {
                dispatch(saveSingleFieldId(singleFieldParam.id));
            }
        } else {
            dispatch(saveSingleFieldId(singleFieldParam.id));
        }
    };

    const templateBuilderLeftSide = { currentPdfObject };

    const middleTemplateSideProps = {
        singleTemplate,
        currentPdfObject,
        setCurrentPdfObject,
        trackPositions,
        selectFieldHandler
    };

    const rightTemplateProps = {
        loading,
        setLoading,
        template_id,
        currentPdfObject
    };
    const colProps = parseInt(showButtons) === 1 ? { span: 24 } : { sm: 24, md: 17, lg: 17 };

    return (
        <div>
            <div className={`template-builder-view-container-new ${className} `}>
                <DndProvider backend={HTML5Backend}>
                    <Row gutter={30}>
                        {parseInt(showButtons) !== 1 && (
                            <Col sm={24} md={3} lg={3} className="left-side-col">
                                {!isSignatureDeleteEnable || !!review || isUserAllowedToEditForm ? (
                                    <TemplateBuilderLeftSide
                                        {...templateBuilderLeftSide}
                                        isSignatureDeleteEnable={isSignatureDeleteEnable}
                                    />
                                ) : null}
                            </Col>
                        )}
                        <Col {...colProps} className="pdf-middle-col">
                            {Children.map(children, (child) =>
                                cloneElement(child, {
                                    ...middleTemplateSideProps
                                })
                            )}
                        </Col>
                        {parseInt(showButtons) !== 1 && (
                            <Col sm={24} md={4} lg={4} className="right-side-col">
                                {!isSignatureDeleteEnable ? <TemplateBuilderRightSide {...rightTemplateProps} /> : null}
                            </Col>
                        )}
                    </Row>
                </DndProvider>
            </div>
        </div>
    );
}

export default memo(TemplateBuilderWrapper);
