export const QueryModuleNames = {
    categories: "categories",
    envelopes: "envelopes",
    envelopeAuth: "envelopeAuth",
    medias: "medias",
    templates: "templates",
    templateAuth: "templateAuth",
    users: "users",
    setting: "setting"
};

export const QueryNames = {
    categories: [QueryModuleNames.categories],
    envelopes: [QueryModuleNames.envelopes],
    medias: [QueryModuleNames.medias],
    templates: [QueryModuleNames.templates],
    users: [QueryModuleNames.users],
    setting: [QueryModuleNames.setting]
};

export const globalReactQueryGetOption = {
    refetchOnMount: "always"
};

export const PAGE_SIZE = 10;

export const PAGINATION_STALE_TIME = 5000;

