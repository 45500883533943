import { DEFAULT_FONT_SIZE, FIELD_TYPES } from "../../../util/util.constant";

export const defaultFieldObject = {
    id: 0,
    // x: 0,
    // y: 0,
    type: FIELD_TYPES.text,
    value: "",
    checked: false, //for checkbox / radio btn
    placeholder: "",
    pageNumber: 1,
    required: false,
    height: 25,
    width: 90,
    validationStatus: "",
    textColor: "black",
    fontFamily: "",
    fontSize: DEFAULT_FONT_SIZE,
    boldText: "",
    italicText: "",
    underLineText: "",
    disableDraggable: false,
    groupId: "",
    defaultValue: "",
    values: "",
    childrensLength: 0,
    mainCheckbox: false,
    childrens: [],
    currentDateTime: "",
    queryName: ""
};

export const fieldDraggleType = "field";

// minimum width/height
export const minFieldWidth = 20;
export const minFieldHeight = 13;

// maximum width/height
export const maxFieldHeight = 792;
export const maxFieldWidth = 612;
