import { useMutation } from "react-query";
import MediaService from "services/media.service";

function useQueryAddMedia() {
    const queryFn = async (payload) => await MediaService.singleFileUpload(payload);
    return useMutation(queryFn, {
        // onError: () => {
        //     notification.error({ message: "An error occurred while uploading the file" });
        // }
    });
}

export { useQueryAddMedia };
