/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef, useState } from "react";
import "./CustomDropdown.less";

const CustomDropdown = ({
    options,
    children,
    isDropdownOpen,
    setIsDropdownOpen,
    isResizeAllowed,
    verticalPosition
}) => {
    const dropdownRef = useRef(null);
    const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 612);

    console.log("isMobile", isMobileDevice);
    const handleOptionSelect = (event, option) => {
        event.stopPropagation();
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setIsDropdownOpen(false);
        }
    };

    const handleClick = (event) => {
        if (!isResizeAllowed) {
            setIsDropdownOpen(!isDropdownOpen);
        }

        event.preventDefault();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);
        window.addEventListener("resize", handleWindowResize);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [isMobileDevice]);

    const handleWindowResize = () => {
        const isMobile = window.innerWidth <= 612;
        setIsMobileDevice(isMobile);
    };

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <button className="dropdown-toggle" onClick={handleClick}>
                {children}
            </button>
            {isDropdownOpen && (
                <ul className={`dropdown-menu`}>
                    {options.map((option, index) => (
                        <li
                            key={index}
                            onClick={(event) => {
                                handleOptionSelect(event, option);
                            }}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default memo(CustomDropdown);

