import React, { memo, useState } from "react";
import { Button, Col, Row } from "antd";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomRadio from "../../CustomRadio/CustomRadio";
import CustomRadioGroup from "../../CustomRadioGroup/CustomRadioGroup";
import "./RecentSign.less";

function RecentSign({ getSignatureFromCreateSign, setIsModalOpen, allRecentSign, setSelectedRecentSignValue }) {
    const [selectedImgValue, setSelectedImgValue] = useState(null);
    const onChange = (e) => {
        setSelectedImgValue(e.target.value);
    };
    const createSignatureHandler = (event) => {
        event.preventDefault();
        setSelectedRecentSignValue(selectedImgValue);
        getSignatureFromCreateSign(selectedImgValue);
        setIsModalOpen(false);
    };
    return (
        <div className="recent-sign-container">
            <CustomRadioGroup onChange={onChange}>
                <Row>
                    {allRecentSign.length > 0 ? (
                        allRecentSign?.map((recentSigns, index) => {
                            return (
                                <Col key={index} sm={12} md={6} lg={6}>
                                    <CustomRadio value={recentSigns}>
                                        <span className="img-check" style={{ position: "relative" }}>
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <img
                                                src={recentSigns}
                                                alt="recentSigns"
                                                style={{ width: "100%", objectFit: "contain", height: "100%" }}
                                            />
                                        </span>
                                    </CustomRadio>
                                </Col>
                            );
                        })
                    ) : (
                        <Col sm={24} md={24} lg={24}>
                            <p className="empty-recent-sign">No recent signature</p>
                        </Col>
                    )}
                    {/* {allRecentSign.length > 0 ? (
                        allRecentSign?.map((recentSigns, index) => {
                            return (
                                <Col key={index} sm={12} md={6} lg={6}>
                                    <CustomRadio value={recentSigns}>
                                        <span className="img-check">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <img
                                                src={recentSigns?.value ? recentSigns?.value : recentSigns}
                                                alt={recentSigns?.value ? recentSigns?.id : recentSigns}
                                            />
                                        </span>
                                    </CustomRadio>
                                </Col>
                            );
                        })
                    ) : (
                        <Col sm={24} md={24} lg={24}>
                            <p className="empty-recent-sign">No recent signature</p>
                        </Col>
                    )} */}
                </Row>
            </CustomRadioGroup>
            <Row>
                <Col>
                    <Button type="primary" onClick={createSignatureHandler}>
                        Add Signature
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default memo(RecentSign);
