import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "antd";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomRadio from "../../CustomRadio/CustomRadio";
import CustomRadioGroup from "../../CustomRadioGroup/CustomRadioGroup";
import "./InitialSign.less";
import { UtilService } from "util/util.service";
import { FIELD_TYPES, FontFamilyTheme } from "util/util.constant";
import { useSelector } from "react-redux";

function InitialSign({ getSignatureFromCreateSign, setIsModalOpen, allRecentSign, setSelectedRecentSignValue }) {
    const signerFields = useSelector((state) => state.signerFields.signerFields);
    console.log("signerFields", signerFields);
    const [loading, setLoading] = useState(false);

    const [signName, setSignName] = useState("");
    const [, setGetParams] = useState({
        name: "",
        email: ""
    });
    const canvas = useRef("");
    const [imageUrl, setImageUrl] = useState(null);
    useEffect(() => {
        const { data } = UtilService.urlParameters();

        if (data) {
            const queryNameFields = UtilService.base64toArray(data);
            const findNameField = queryNameFields.find((singleField) => singleField.queryName === FIELD_TYPES.name);
            setGetParams({
                name: findNameField
                // name: "Muzammil Rafay AA"
            });
        }
    }, []);
    const onChange = (event) => {
        event.preventDefault();
        const value = event?.target?.value;
        setSignName(value);
    };

    const createSignatureHandler = (event) => {
        event.preventDefault();
        getSignatureFromCreateSign(imageUrl);
        setIsModalOpen(false);
    };

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const textImageUrl = await UtilService.generateTextWithCanvas(canvas, signName);
            setImageUrl(textImageUrl);
            setLoading(false);
        };
        load();
    }, [signName]);

    return (
        <div className="initial-sign-container">
            <Row>
                <Col>
                    <div style={{ display: "none" }}>
                        <canvas id="myCanvas" height="100px" ref={canvas}></canvas>
                    </div>
                    {/* <p className="signature-name">
                        {imageUrl ? (
                            <img src={imageUrl} alt="signfromname" height="auto" width="100%" />
                        ) : (
                            <div>&nbsp;</div>
                        )}
                    </p> */}
                </Col>
            </Row>
            <CustomRadioGroup onChange={onChange}>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <CustomRadio value={signerFields?.payload?.signerName} checked={true}>
                            <span
                                className="img-check"
                                style={{ fontFamily: FontFamilyTheme, fontWeight: "bolder", fontSize: 34 }}
                            >
                                <FontAwesomeIcon icon={faCircleCheck} />
                                {signerFields?.payload?.signerName}
                            </span>
                        </CustomRadio>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <CustomRadio
                            value={signerFields?.payload?.signerName
                                ?.split(" ")
                                ?.map((word) => word.charAt(0))
                                ?.join("")}
                        >
                            <span
                                className="img-check"
                                style={{ fontFamily: FontFamilyTheme, fontWeight: "bolder", fontSize: 34 }}
                            >
                                <FontAwesomeIcon icon={faCircleCheck} />
                                {signerFields?.payload?.signerName
                                    ?.split(" ")
                                    ?.map((word) => word.charAt(0))
                                    ?.join("")}
                            </span>
                        </CustomRadio>
                    </Col>
                </Row>
            </CustomRadioGroup>
            <Row>
                <Col>
                    <Button type="primary" loading={loading} onClick={createSignatureHandler}>
                        Add Signature
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default memo(InitialSign);

