import React, { memo } from "react";
import { Button } from "antd";

import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { saveField } from "redux/fieldSlice";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { UtilService } from "util/util.service";

function AddDropDown({ currentPdfObject }) {
    const dispatch = useDispatch();

    const generateCheckboxFields = () => {
        const unique_id = UtilService.generateUniqueId();
        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.dropdown,
            placeholder: FIELD_TYPES.dropdown,
            pageNumber: currentPdfObject.pageNumber,
            width: FIELD_STATIC_WIDTH.DROPDOWN.width,
            height: FIELD_STATIC_WIDTH.DROPDOWN.height,
            queryName: `${FIELD_TYPES.dropdown}-${unique_id}`,
            x: 0,
            y: 0
        };
    };

    const [, dragRef] = useDrag({
        type: fieldDraggleType,
        item: () => {
            return generateCheckboxFields();
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const onFieldFinish = () => {
        const savedFieldsData = generateCheckboxFields();

        dispatch(saveField(savedFieldsData));
    };
    return (
        <Button
            ref={dragRef}
            onClick={onFieldFinish}
            className="common-button"
            icon={<i className="paso-dropdown"></i>}
        >
            Dropdown
        </Button>
    );
}

export default memo(AddDropDown);
