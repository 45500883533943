import { Card, Input } from "antd";
import React, { memo, useState } from "react";
import Draggable from "react-draggable";
import { QUERY_KEYS_NAMES, DEFAULT_FONT_SIZE } from "util/util.constant";
import FormViewAutoComplete from "./FormViewAutoComplete";

const { TextArea } = Input;

function FormViewInput(props) {
    const { singleField, trackPositions, onTextChangeHandler, reviewer, disabled = false } = props;
    const { width, height, fontSize = DEFAULT_FONT_SIZE } = singleField;

    const [textValue, setTextValue] = useState(null);

    const textChangeHandler = (event) => {
        if (event?.target?.scrollHeight - 5 <= event?.target?.offsetHeight) {
            setTextValue(event?.target?.value);
        }
    };
    // console.log(
    //     "Math.abs(Math.floor(width - fontSize * height - 11))",
    //     Math.abs(Math.floor(width - fontSize * height - 11))
    // );

    function calculateTextLimit(width, height, fontSize) {
        // Assuming a monospaced font
        const charWidth = fontSize / 2; // Adjust this value based on your font

        // Calculate the maximum number of characters that can fit horizontally
        const maxCharsHorizontal = Math.floor(width / charWidth);

        // Calculate the maximum number of lines that can fit vertically
        const maxLinesVertical = Math.floor(height / fontSize);

        // Calculate the total maximum characters that can fit
        const maxCharacters = maxCharsHorizontal * maxLinesVertical;

        return maxCharacters;
    }
    const style = { width, height, ...(singleField?.fontSize && { fontSize }) };
    const textStyle = { height, width, minHeight: "auto", fontSize, lineHeight: 1 };
    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div className="box-draggable " style={style}>
                <Card style={style}>
                    {
                        // reviewer ? (
                        //     <p className="m-0 line-height">
                        //         <b>{singleField?.value}</b>
                        //     </p>
                        // ) :
                        Array.isArray(singleField?.value) ? (
                            <FormViewAutoComplete
                                onTextChangeHandler={textChangeHandler}
                                onBlurChangeHandler={(event) => {
                                    onTextChangeHandler(event, singleField);
                                }}
                                singleField={singleField}
                                value={singleField.value}
                                height={height}
                                width={width}
                            />
                        ) : (
                            <TextArea
                                typeof="text"
                                autoSize={false}
                                title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                                className="form-view-text-area"
                                style={reviewer ? { ...textStyle, pointerEvents: "none" } : textStyle}
                                onChange={(event) => {
                                    textChangeHandler(event);
                                    // console.log(event);
                                }}
                                onBlur={(event) => {
                                    onTextChangeHandler(event, singleField);
                                }}
                                status={singleField.validationStatus}
                                value={textValue ?? singleField.value}
                                disabled={disabled}
                            />
                        )
                    }
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewInput);

