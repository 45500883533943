import { Button, Col, message, Row } from "antd";
import React, { memo, useRef } from "react";
// import { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./CreateSign.less";

function CreateSign({ getSignatureFromCreateSign, setIsModalOpen, allRecentSign, setAllRecentSign }) {
    const sigCanvas = useRef();
    // const [imageURL, setImageURL] = useState(null);

    const create = () => {
        if (!sigCanvas.current.isEmpty()) {
            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            getSignatureFromCreateSign(URL);
            sigCanvas.current.clear();
            setIsModalOpen(false);
            setAllRecentSign([...allRecentSign, URL]);
        } else {
            message.warn("signature is empty");
        }
        // setImageURL(URL);
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        // setImageURL(null);
    };

    // const download = () => {
    //   if (imageURL) {
    //     const dlink = document.createElement("a");
    //     dlink.setAttribute("href", imageURL);
    //     dlink.setAttribute("download", "signature.png");
    //     dlink.click();
    //   }
    // };

    return (
        <div className="create-sign-container">
            <div className="create-sign-canvas-container">
                <SignatureCanvas penColor="black" canvasProps={{ width: 480, height: 200 }} ref={sigCanvas} />
            </div>
            <Row gutter={16}>
                <Col>
                    <Button className="create" onClick={create}>
                        Create
                    </Button>
                </Col>

                <Col>
                    <Button onClick={clearSignature}>Clear</Button>
                </Col>

                {/* <Col>
          <Button onClick={download}>Download</Button>
        </Col> */}
            </Row>
            <br />
            {/* {imageURL && (
        <img src={imageURL} alt="signature" className="signature" width={80} />
      )} */}
        </div>
    );
}

export default memo(CreateSign);

