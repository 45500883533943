import React, { memo, useState } from "react";
import { Modal, Tabs } from "antd";
import CreateSign from "./Tabs/CreateSign";
import UploadSign from "./Tabs/UploadSign";
import SignFromName from "./Tabs/SignFromName";
import RecentSign from "./Tabs/RecentSign";
import { SIGN_TABS_KEY } from "../../util/util.constant";
import "./CustomSignature.less";
import InitialSign from "./Tabs/InitialSign";
import { UtilService } from "util/util.service";
import useField from "redux/useField";

function CustomSignature({
    addSignature,
    signaturePopup,
    setSignaturePopup,
    getRecentSignatures,
    allRecentSign,
    setAllRecentSign,
    setSelectedRecentSignValue
}) {
    const { send_email = "" } = UtilService.urlParameters();
    const isSendEmailExist = Boolean(send_email);
    const [, setResetTabKey] = useState(null);
    const handleOk = () => {
        setSignaturePopup(false);
        setResetTabKey(SIGN_TABS_KEY.CreateSign);
    };

    const handleCancel = () => {
        setSignaturePopup(false);
        setResetTabKey(SIGN_TABS_KEY.CreateSign);
    };

    const onTabChange = (key) => {
        if (key === SIGN_TABS_KEY.RecentSign) {
            getRecentSignatures();
        }
    };

    const getSignatureFromCreateSign = (signature) => {
        addSignature(signature);
    };
    const tabItems = [
        {
            label: "Initial Sign",
            children: (
                <InitialSign
                    getSignatureFromCreateSign={getSignatureFromCreateSign}
                    setIsModalOpen={setSignaturePopup}
                    allRecentSign={allRecentSign}
                    setSelectedRecentSignValue={setSelectedRecentSignValue}
                />
            ),
            key: SIGN_TABS_KEY.InitialSign
        },
        {
            label: "Create Sign",
            children: (
                <CreateSign
                    getSignatureFromCreateSign={getSignatureFromCreateSign}
                    setIsModalOpen={setSignaturePopup}
                    setAllRecentSign={setAllRecentSign}
                    allRecentSign={allRecentSign}
                />
            ),
            key: SIGN_TABS_KEY.CreateSign
        },
        {
            label: "Upload Sign",
            children: (
                <UploadSign
                    getSignatureFromCreateSign={getSignatureFromCreateSign}
                    setIsModalOpen={setSignaturePopup}
                    setAllRecentSign={setAllRecentSign}
                    allRecentSign={allRecentSign}
                />
            ),
            key: SIGN_TABS_KEY.UploadSign
        },
        {
            label: "Sign From Name",
            children: (
                <SignFromName
                    getSignatureFromCreateSign={getSignatureFromCreateSign}
                    setIsModalOpen={setSignaturePopup}
                    setAllRecentSign={setAllRecentSign}
                    allRecentSign={allRecentSign}
                />
            ),
            key: SIGN_TABS_KEY.SignFromName
        },
        {
            label: "Recent Sign",
            children: (
                <RecentSign
                    getSignatureFromCreateSign={getSignatureFromCreateSign}
                    setIsModalOpen={setSignaturePopup}
                    allRecentSign={allRecentSign}
                    setSelectedRecentSignValue={setSelectedRecentSignValue}
                />
            ),
            key: SIGN_TABS_KEY.RecentSign
        }
    ];

    if (isSendEmailExist) {
        tabItems.splice(0, 1);
    }

    return (
        <div className="sigPadContainer">
            <Modal title="Signature" open={signaturePopup} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <Tabs defaultActiveKey={SIGN_TABS_KEY.InitialSign} items={tabItems} onChange={onTabChange} />
            </Modal>
        </div>
    );
}

export default memo(CustomSignature);

