import { Radio } from "antd";
import React, { memo } from "react";
import "./CustomRadioGroup.less";

const CustomRadioGroup = (props) => {
    return (
        <div className="custom-radio-group-container">
            <Radio.Group {...props}>{props.children}</Radio.Group>
        </div>
    );
};

export default memo(CustomRadioGroup);
