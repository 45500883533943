import { userInfo } from "./util.constant";

function isTokenExist() {
    const token = localStorage.getItem(userInfo.TOKEN);
    return Boolean(token);
}

function getTokenExist() {
    const token = localStorage.getItem(userInfo.TOKEN);
    if (token) {
        return token;
    }
    return null;
}

function getUserName() {
    const username = localStorage.getItem(userInfo.USERNAME);
    if (username) {
        return username;
    }
    return null;
}

const AuthService = {
    isTokenExist,
    getUserName,
    getTokenExist
};

export default AuthService;

