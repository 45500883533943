import React, { memo } from "react";

function FormViewSignatureImage({ width, height, singleField = { value: null, id: null }, onClick = () => {} }) {
    return (
        <div style={{ width, height }} className={`create-signature-container create-signature-container-no-border`}>
            <img
                src={singleField?.value}
                alt={singleField?.id}
                height="65"
                width="100"
                style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    width: "100%",
                    height: "100%"
                }}
                onClick={onClick}
                // style={{ width, height, }}
            />
        </div>
    );
}

export default memo(FormViewSignatureImage);

