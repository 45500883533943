import { Button, Col, Modal, Row, message } from "antd";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

function CustomSignatureModal({ signaturePopup, setSignaturePopup, addSignature }) {
    const sigCanvas = useRef();
    // const [imageURL, setImageURL] = useState(null);

    const handleOk = () => {
        setSignaturePopup(false);
    };

    const handleCancel = () => {
        setSignaturePopup(false);
    };

    const getSignatureFromCreateSign = (signature) => {
        addSignature(signature);
    };
    const create = () => {
        if (!sigCanvas.current.isEmpty()) {
            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            getSignatureFromCreateSign(URL);
            sigCanvas.current.clear();
            setSignaturePopup(false);
            // setAllRecentSign([...allRecentSign, URL]);
        } else {
            message.warn("signature is empty");
        }
        // setImageURL(URL);
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        // setImageURL(null);
    };

    return (
        <div className="sigPadContainer">
            <Modal title="Signature" open={signaturePopup} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <div className="create-sign-container">
                    <div className="create-sign-canvas-container">
                        <SignatureCanvas penColor="black" canvasProps={{ width: 480, height: 200 }} ref={sigCanvas} />
                    </div>
                    <Row gutter={16}>
                        <Col>
                            <Button className="create" onClick={create}>
                                Create
                            </Button>
                        </Col>

                        <Col>
                            <Button onClick={clearSignature}>Clear</Button>
                        </Col>
                    </Row>
                    <br />
                </div>
            </Modal>
        </div>
    );
}

export default CustomSignatureModal;
