import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { useDrag } from "react-dnd";
import { saveField } from "redux/fieldSlice";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { UtilService } from "util/util.service";

function AddSignatureField({ currentPdfObject, isSignatureDeleteEnable = false }) {
    const dispatch = useDispatch();

    const generateCheckboxFields = () => {
        const unique_id = UtilService.generateUniqueId();
        const groupId = UtilService.generateUniqueId();

        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.signature,
            placeholder: FIELD_TYPES.signature,
            pageNumber: currentPdfObject.pageNumber,
            width: FIELD_STATIC_WIDTH.SIGNATURE.width,
            height: FIELD_STATIC_WIDTH.SIGNATURE.height,
            groupId: `${FIELD_TYPES.signature} ${groupId}`,
            isFormViewSignature: isSignatureDeleteEnable,
            queryName: `${FIELD_TYPES.signature}-${unique_id}`,
            x: 0,
            y: 0
        };
    };

    const [, dragRef] = useDrag({
        type: fieldDraggleType,
        item: () => {
            return generateCheckboxFields();
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const onFieldFinish = () => {
        const clonedFieldData = generateCheckboxFields();
        dispatch(saveField(clonedFieldData));
    };

    return (
        <Button
            ref={dragRef}
            className="common-button"
            onClick={onFieldFinish}
            icon={<i className="paso-signature"></i>}
        >
            Signature
        </Button>
    );
}

export default memo(AddSignatureField);
