import React, { memo } from "react";
import { Form } from "antd";
import RepeaterFieldsForSignature from "Components/RepeaterFields/RepeaterFieldsForSignature";

function RightSignatureOption({ singleField }) {
    return (
        <div className="repeter-sec">
            <Form.Item>
                <RepeaterFieldsForSignature />
            </Form.Item>
        </div>
    );
}

export default memo(RightSignatureOption);
