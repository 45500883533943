import { Button, Space } from "antd";
import { UtilService } from "util/util.service";

const templateColumns = (editTemplateHandler, builderViewHandler, deleteTemplate, generateViewHandler, generateHTMLFormView) => {
    return [
        {
            title: "Id",
            key: "Id",
            sorter: true,
            render: (text) => text?.id
        },
        {
            title: "Name",
            key: "name",
            sorter: true,
            render: (text) => text?.attributes?.name
        },
        {
            title: "Created At",
            key: "createdAt",
            sorter: true,
            render: (text) => UtilService.convertDateToOurFormat(text?.attributes?.createdAt)
        },
        {
            title: "Updated At",
            key: "updatedAt",
            sorter: true,
            render: (text) => UtilService.convertDateToOurFormat(text?.attributes?.updatedAt)
        },
        {
            title: "Actions",
            key: "action",
            render: (_, record) => (
                <Space>
                    {/* <Button type="primary" onClick={(event) => editTemplateHandler(event, record)}>
                        Edit
                    </Button> */}
                    {record.attributes.templateImages?.length > 0 ?
                        <>
                            <Button type="primary" onClick={(event) => builderViewHandler(event, record)}>
                                Builder View
                            </Button>
                            <Button type="primary" onClick={(event) => generateViewHandler(event, record)}>
                                Generate Form View
                            </Button>
                        </>
                        :
                        <Button type="primary" onClick={(event) => generateHTMLFormView(event, record)}>
                            Generate HTML Form View
                        </Button>
                    }
                    <Button type="danger" onClick={(event) => deleteTemplate(event, record)}>
                        Delete
                    </Button>
                    <Button type="danger" onClick={(event) => window.open("/statichtmlPages/ip-form/ip-form.html")}>
                        Redirect
                    </Button>
                </Space>
            )
        }
    ];
};

export default templateColumns;
