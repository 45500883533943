import React, { useState, useRef, memo, useEffect, useLayoutEffect } from "react";
import { Button, Col, Input, Row } from "antd";
import { FontFamilyTheme } from "util/util.constant";
import "./SignFromName.less";
import { useSelector } from "react-redux";

function SignFromName({ getSignatureFromCreateSign, setIsModalOpen, allRecentSign, setAllRecentSign }) {
    const canvas = useRef("");
    const [imageUrl, setImageUrl] = useState(null);
    const [canvasTextWidth, setCanvasTextWidth] = useState();
    const signerFields = useSelector((state) => state.signerFields.signerFields);

    useLayoutEffect(() => {
        CreateSignature(signerFields?.payload?.signerName);
    }, []);

    const handleSignatureName = (event) => {
        event.preventDefault();
        const value = event?.target?.value;
        CreateSignature(value);
    };

    const CreateSignature = (value) => {
        const convasDomElement = canvas.current;
        const context = convasDomElement.getContext("2d");
        context.clearRect(0, 0, convasDomElement.width, convasDomElement.height);
        context.font = `bolder 35px ${FontFamilyTheme}`;
        context.fillText(value, 10, 50);
        const pngUrl = convasDomElement.toDataURL();
        setImageUrl(pngUrl);
        setCanvasTextWidth((canvas.width = context.measureText(value).width) + 40);
    };
    const createSignatureHandler = (event) => {
        event.preventDefault();
        getSignatureFromCreateSign(imageUrl);
        setAllRecentSign([...allRecentSign, imageUrl]);
        setIsModalOpen(false);
    };
    return (
        <div className="create-signature-from-name-container">
            <Row>
                <Col>
                    <p className="label-for-signature">Name: </p>
                </Col>
                <Col flex={50}>
                    <Input
                        placeholder="Name"
                        defaultValue={signerFields?.payload?.signerName}
                        onChange={handleSignatureName}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div style={{ display: "none" }}>
                        {/* <canvas id="myCanvas" width="480px" height="100px" ref={canvas}></canvas> */}
                        <canvas
                            id="myCanvas"
                            width={canvasTextWidth ? `${canvasTextWidth}` : "480px"}
                            height="100px"
                            ref={canvas}
                        ></canvas>
                    </div>
                    <p className="signature-name">
                        {imageUrl ? (
                            <img src={imageUrl} alt="signfromname" height="auto" width="100%" />
                        ) : (
                            <span>&nbsp;</span>
                        )}
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button type="primary" onClick={createSignatureHandler}>
                        Create Signature
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default memo(SignFromName);

