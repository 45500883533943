import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";

const mediaUrl = {
    singleUpload: `media/file`,
    multiUpload: `media/files`
};
async function singleFileUpload(payload) {
    const response = await ApiService.post(mediaUrl.singleUpload, payload);
    return ApiRequestUtil.handleApiRequest(response);
}

async function multiFileUpload(payload) {
    const response = await ApiService.post(mediaUrl.multiUpload, payload);
    return ApiRequestUtil.handleApiRequest(response);
}

const MediaService = {
    singleFileUpload,
    multiFileUpload
};

export default MediaService;
