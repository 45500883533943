const register = () => {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.register("/service-worker.js").catch(console.error);
    }
};

export const serviceWorkerRegistration = {
    register
};

