import React, { memo, useState } from "react";
import { Card } from "antd";
import Draggable from "react-draggable";
// import CustomSelect from "Components/CustomSelect/CustomSelect";
import "./FormViewDropdown.less";
import { DEFAULT_FONT_SIZE, QUERY_KEYS_NAMES } from "util/util.constant";
import { UtilService } from "util/util.service";
import CustomSelectHtml from "Components/CustomSelectHtml/CustomSelectHtml";

function FormViewDropdown(props) {
    const { singleField, trackPositions, onDatePickerChangeHandler, reviewer, disabled = false } = props;
    const [zIndexValue, setZindexValue] = useState(0);
    const { width, height, fontSize = DEFAULT_FONT_SIZE } = singleField;
    // const width = FIELD_STATIC_WIDTH.DROPDOWN.width;
    // const height = FIELD_STATIC_WIDTH.DROPDOWN.height;

    const dropdownChildrens = Boolean(singleField?.childrens)
        ? UtilService.deepfilterUniqueArray(singleField?.childrens, "value")
        : [];

    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div
                onMouseOver={() => {
                    setZindexValue(9);
                }}
                onMouseOut={() => {
                    setZindexValue(0);
                }}
                className="box-draggable "
                // style={{ width, height, zIndex: zIndexValue }}
                style={{ zIndex: zIndexValue, pointerEvents: disabled ? "none" : "auto" }}
            >
                {/* <Card style={{ width, height }}> */}
                <Card
                    style={{
                        ...(singleField?.fontSize && { fontSize })
                    }}
                >
                    {/* <CustomSelect
                        className="form-view-dropdown-css"
                        onChange={(value) => onDatePickerChangeHandler(value, singleField)}
                        status={singleField.validationStatus}
                        value={singleField?.value}
                        disabled={!!reviewer}
                        defaultValue={reviewer ? singleField?.value : ""}
                        style={{ width, height }}
                        options={dropdownChildrens?.map((items) => {
                            return {
                                label: items?.option,
                                value: items?.value
                            };
                        })}
                        dropdownRender={(menu) => <div className="custom-menu-render-class">{menu}</div>}
                    /> */}
                    <CustomSelectHtml
                        title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                        className={`form-view-dropdown-css ${singleField.validationStatus}`}
                        onChange={(e) => onDatePickerChangeHandler(e.target.value, singleField)}
                        status={singleField.validationStatus}
                        value={singleField?.value}
                        editable={!!reviewer}
                        defaultValue={reviewer ? singleField?.value : ""}
                        style={{ width, height, fontSize }}
                    >
                        <option selected disabled hidden></option>
                        {dropdownChildrens?.map((items, index) => (
                            <option value={items?.value} key={index}>
                                {items?.option}
                            </option>
                        ))}
                    </CustomSelectHtml>
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewDropdown);
