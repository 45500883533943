import React, { memo } from "react";
import "./CustomFormItem.less";

import { Form } from "antd";
const CustomFormItem = (props) => {
    return (
        <div className={`custom-form-item ${props.className ? props.className : ""}`}>
            <Form.Item {...props}>{props.children}</Form.Item>
        </div>
    );
};

export default memo(CustomFormItem);
