import { AutoComplete, Input } from "antd";
import React from "react";
import { QUERY_KEYS_NAMES } from "util/util.constant";
import "./FormViewAutoComplete.less";

const { TextArea } = Input;
function FormViewAutoComplete({
    value = [],
    onTextChangeHandler = () => {},
    singleField,
    height,
    width,
    onBlurChangeHandler = () => {}
}) {
    // const {value = []} = singleField;
    const options = value?.map((singleValue) => {
        return {
            value: singleValue
        };
    });
    return (
        <AutoComplete
            className="custom-auto-complete"
            style={{ height, width, minHeight: "auto" }}
            options={options}
            status={singleField.validationStatus}
            onChange={onTextChangeHandler}
            onBlur={(value) => onBlurChangeHandler(value, singleField, true)}
            filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        >
            <TextArea
                title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                className="form-view-text-area"
                style={{ height, width, minHeight: "auto" }}
                status={singleField.validationStatus}
            />
        </AutoComplete>
    );
}

export default FormViewAutoComplete;

