/* eslint-disable react-hooks/exhaustive-deps */
import { fieldDraggleType } from "Components/TemplateBuilderWrapper/FormFields/constant";
import React, { memo, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
// import { useDispatch } from "react-redux";
// import { saveField } from "redux/fieldSlice";
// import { useSelector } from "react-redux";
// import useWindowSize from "hooks/useWindowSize";
// import { UtilService } from "util/util.service";

function HTMLDropWrapper({ currentPdfObject, children, setFields }) {
    // console.log("position: ", position);
    // const dispatch = useDispatch();
    // const [screenWidth] = useWindowSize();
    const [axis, setAxis] = useState({
        xAxis: 0,
        yAxis: 0
    });
    // console.log("currentPdfObject...................", currentPdfObject);
    // const position = useSelector((state) => state.fields.scrollY);
    // const position = useSelector((state) => state.fields.scrollY);
    // const { reviewer } = useMemo(() => UtilService.urlParameters(), [window.location.search]);
    const [g, dropRef] = useDrop({
        accept: fieldDraggleType,
        drop: (item, monitor) => {
            // console.log("get: ", monitor.getItem());
            // console.log("item: ", item);
            const modifyFieldPass = {
                ...item,
                pageNumber: 0,
                x: axis.xAxis,
                y: axis.yAxis + window.scrollY
                // pagePosition: axis.yAxis
            };
            setFields((prev) => [...prev, modifyFieldPass]);
            // dispatch(saveField(modifyFieldPass));
            // console.log("window.scrollY: ", window.scrollY);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),

        hover: (item, monitor) => {
            const { x: xAxis, y: yAxis } = monitor.getSourceClientOffset();
            // console.log("yAxis: ", yAxis);
            // console.log("xAxis: ", xAxis);
            // const y =
            //     currentPdfObject.pageNumber === 1
            //         ? position / currentPdfObject.pageNumber
            //         : position - 1480.6 * (currentPdfObject.pageNumber - 1) + currentPdfObject.pageNumber;
            const x = xAxis - (window.innerWidth - 900) / 2;

            // console.log("xAxis: ", xAxis, "yAxis: ", xAxis);
            // console.log("yAxis: ", yAxis, "Conditions: ", (yAxis - 133.140625 + y) / 1.8, y);
            // console.log("I'm Y: ", (yAxis - 133.140625 + y) / 1.8);
            setAxis({ yAxis: yAxis - 56, xAxis: x });
        }
    });
    return (
        <div style={{ height: "100%", width: "100%", position: "relative" }} className="items-drop" ref={dropRef}>
            {children}
        </div>
    );
}

export default memo(HTMLDropWrapper);

