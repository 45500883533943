export const HTML_FORMS = {
    IP: `<!-- <!DOCTYPE html> -->
    <html lang="en">
    
    <head>
      <meta charset="utf-8" />
      <link rel="icon" href="/public/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Web site created using create-react-app" />
      <!-- <link rel="manifest" href="/public/manifest.json" /> -->
      <link rel="stylesheet" href=ip-form.css />
    </head>
    
    <body>
      <div class="page-no">1</div>
      <div class="page" id="page">
        <div class="container">
          <div class="header">
            <img src="../../images/ip-form-icon.png" />
            <div class="form-no">
              <span> 
                Form 2125 
              </span>
              <span>
              October 2022-E
              </span>
            </div>
          </div>
          <div class="main-heading">
            <span>Home and Community-based Service (HCS), Texas Home Living (TxHmL) and Community First Choice (CFC)</span>
            <span class="heading">Implementation Plan</span>
          </div>
    
          <div class="flex-container">
            <div class="input-container implementation-plan">
              <h6>Implementation Plan for:</h6>
              <input oninput="handleInputChange(this)" name="lastNameFirstName"/>
            </div>
            <div class="input-container">
              <h6>Client Assignment and Registration (CARE) System ID:</h6>
              <input oninput="handleInputChange(this)" name="careId"/>
            </div>
          </div>
          <div class="flex-container">
            <div class="input-container component-code">
              <h6>Component Code:</h6>
              <input oninput="handleInputChange(this)" name="componentCode"/>
            </div>
            <div class="input-container service-component">
              <h6>Service Component:</h6>
              <input oninput="handleInputChange(this)" />
            </div>
            <div class="input-container">
              <h6>Backup Plan Required:</h6>
              <div class="flex-container">
                <input type="radio" id="yes" name="backup-plan" onclick="handleRadioButton(this)">
                <label for="backup-plan">Yes</label><br>
                <input type="radio" id="no" name="backup-plan" onclick="handleRadioButton(this)">
                <label for="backup-plan">No</label>
              </div>
            </div>
            <div class="input-container">
              <h6>Date Plan Developed:</h6>
              <input oninput="handleInputChange(this)" type="date"/>
            </div>
          </div>
          <div class="flex-container">
            <div class="input-container individual-plan-of-care">
              <h6>Individual Plan of Care (IPC) Begin Date:</h6>
              <input oninput="handleInputChange(this)" type="date" name="ipcStarts" pattern="\d{2}-\d{2}-\d{4}"/>
            </div>
            <div class="input-container ipc-effective-date">
              <h6>IPC Effective Date:</h6>
              <input oninput="handleInputChange(this)" type="date" name="currentDate"/>
            </div>
            <div class="input-container">
              <h6>IPC End Date:</h6>
              <input oninput="handleInputChange(this)" type="date" name="ipcEnds"/>
            </div>
          </div>
          <table id="desired-outcome-table" class="common-table">
            <tr class="desired-outcome-heading">
              <th colspan="3">Desired Outcome(s) or Purpose(s) from Personal Directed Plan (PDP) Action
                Plan
                for this Service Component:
              </th>
            </tr>
            <tr>
              <td class="desired-outcome-text-area"><textarea rows="1"
                  oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
              <td class="table-action-button" id="add-button"><button onclick="handleAddLine(event)">+</button></td>
              <td class="table-action-button" id="remove-button"><button onclick="handleRemoveLine(event)">-</button></td>
            </tr>
            <tr>
              <td class="desired-outcome-text-area"><textarea rows="1"
                  oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
              <td class="table-action-button" id="add-button"><button onclick="handleAddLine(event)">+</button></td>
              <td class="table-action-button" id="remove-button"><button onclick="handleRemoveLine(event)">-</button></td>
            </tr>
          </table>
          <div class="additional-details">
            <div class="additional-details-heading">In Addition to the PDP, Development of Implementation Strategies based
              on
              (check all that apply):
            </div>
            <div class="checkbox-div flex-container">
              <input type="checkbox" id="conversation" name="conversation" value="conversation" onclick="handleCheckedButton(this)"/>
              <label for="conversation"> Conversation(s) with:</label>
              <input oninput="handleInputChange(this)" class="additional-details-input" />
            </div>
            <div class="checkbox-div flex-container">
              <input type="checkbox" id="observation" name="observation" value="observation" onclick="handleCheckedButton(this)"/>
              <label for="observation"> Observation:</label>
            </div>
            <div class="checkbox-div flex-container">
              <input type="checkbox" id="assessment" name="assessment" value="assessment" onclick="handleCheckedButton(this)"/>
              <label for="assessment">Formal Assessment(s):</label>
              <input oninput="handleInputChange(this)" class="additional-details-input" />
            </div>
          </div>
          <table id="implementation-strategy-table" class="common-table">
            <tr id="implementation-strategy-table-header">
              <th class="width-35">Implementation Strategy Objectives</th>
              <th>Start Date</th>
              <th>Target Completion</th>
              <th>Calculation of Units
                <i style="display: block; text-decoration-style: i;">(If applicable)</i>
              </th>
              <th>Total Units
                (Per Strategy)</th>
            </tr>
            <tr>
              <td style="width: 35%;"><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea>
              </td>
              <td><input type="date" oninput="handleInputChange(this)" /></td>
              <td><input type="date" oninput="handleInputChange(this)" /></td>
              <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
              <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
              <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td>
              <td class="table-action-button"><button onclick="handleRemoveLine(event)">-</button></td>
            </tr>
            <tr>
              <td style="width: 35%;"><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea>
              </td>
              <td><input type="date" oninput="handleInputChange(this)" /></td>
              <td><input type="date" oninput="handleInputChange(this)" /></td>
              <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
              <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
              <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td>
              <td class="table-action-button"><button onclick="handleRemoveLine(event)">-</button></td>
            </tr>
          </table>
          <div>
            <div class="implementation-strategy-table-extra-fields">
              <label>Total IPC Units Needed for this Service Component:</label>
              <span class="total-unit"> <input style="width: 80px;" type="number" oninput="handleInputChange(this)" /></span>
              <div class="empty-div-container"></div>
            </div>
          </div>
          <div class="implementation-strategy-table-extra-fields">
            <label>Requisition Fee <i>(if applicable)</i></label>
            <span class="total-unit"> <input style="width: 80px; height: 18px;" type="number" oninput="handleInputChange(this)" /></span>
            <div class="empty-div-container"></div>
          </div>
          <div class="audio-visual-technology-radio">
            <div style="font-size: 14px;">If using synchronous audio-visual technology for an allowable service, is consent
              obtained and documented in
              the
              individual’s
              record?----------------------------------------------------------------------------------------------------------
            </div>
            <div class="flex-container radio-btn">
              <input type="radio" id="yes" name="audio-visual" value="Yes" onclick="handleRadioButton(this)">
              <label for="Yes">Yes</label><br>
              <input type="radio" id="no" name="audio-visual" value="No" onclick="handleRadioButton(this)">
              <label for="No">No</label>
            </div>
          </div>
          <div class="signature-container">
            <div class="signature-container-heading">Signatures for Implementation Plan</div>
            <input type="radio" id="signature-sheet" name="signature-type" value="signature-sheet" onclick="handleRadioButton(this)">
            <label for="signature-sheet">Signature sheet for implementation plan(s) on file or</label><br>
            <input type="radio" id="signature" name="signature-type" value="signature" onclick="handleRadioButton(this)">
            <label for="signature">Signatures below</label>
            <div class="flex-container" style="margin-top: 4px;">
              <div class="signature-div">
                <div id="signature-container-1" class="signature-container-modal-open" onclick="openModal(this)"></div>
                <label>Signature of Individual</label>
              </div>
              <div class="signature-div">
                <div id="signature-container-2" class="signature-container-modal-open" onclick="openModal(this)"></div>
                <label>Signature of Legally Authorized Representative</label>
              </div>
            </div>
            <div class="flex-container">
              <div class="signature-div">
                <div id="signature-container-3" class="signature-container-modal-open" onclick="openModal(this)"></div>
                <label>Signature of Family Member or Advocate</label>
              </div>
              <div class="signature-div">
                <div id="signature-container-4" class="signature-container-modal-open" onclick="openModal(this)"></div>
                <label>Signature of HCS, TxHmL or CFC Provider Representative</label>
              </div>
            </div>
          </div>
          <div class="discontinuation-signature-box">
            <div class="signature-container-heading">Signatures for Discontinuation of Implementation Plan</div>
            <div class="flex-container">
              <div class="signature-div" style="flex: 2;">
                <div id="signature-container-5" class="signature-container-modal-open" onclick="openModal(this)"></div>
                <label>Signature of HCS, TxHmL, CFC Provider Representative, Individual or LAR</label>
              </div>
              <div class="signature-div">
                <input type="date" oninput="handleInputChange(this)">
                <label>Date</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    
      <!-- <div class="page-no">2</div>
      <div class="page" id="page">
        <div class="container">
          <div class="flex-container">
            <div class="input-container implementation-plan">
              <h6>Implementation Plan for:</h6>
              <input />
            </div>
            <div class="input-container">
              <h6>Client Assignment and Registration (CARE) System ID:</h6>
              <input />
            </div>
          </div>
          <div class="flex-container">
            <div class="input-container implementation-plan">
              <h6>Implementation Plan for:</h6>
              <input />
            </div>
            <div class="input-container">
              <h6>Client Assignment and Registration (CARE) System ID:</h6>
              <input />
            </div>
          </div>
          <div class="flex-container">
            <div class="input-container implementation-plan">
              <h6>Implementation Plan for:</h6>
              <input />
            </div>
            <div class="input-container">
              <h6>Client Assignment and Registration (CARE) System ID:</h6>
              <input />
            </div>
          </div>
          <div class="flex-container">
            <div class="input-container implementation-plan">
              <h6>Implementation Plan for:</h6>
              <input />
            </div>
            <div class="input-container">
              <h6>Client Assignment and Registration (CARE) System ID:</h6>
              <input />
            </div>
          </div>
          <div class="flex-container">
            <div class="input-container implementation-plan">
              <h6>Implementation Plan for:</h6>
              <input />
            </div>
            <div class="input-container">
              <h6>Client Assignment and Registration (CARE) System ID:</h6>
              <input />
            </div>
          </div>
        </div>
      </div> -->
    </body>
    
    </html>`,
    CNA: `<!-- <!DOCTYPE html> -->
    <html lang="en">
        <head>
            <meta charset="utf-8" />
            <title>Visionze | CNA Form</title> 
            <link rel="icon" href="../../images/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Web site created using create-react-app" />
            <!-- <link rel="manifest" href="/public/manifest.json" /> -->
            <link rel="stylesheet" href="style.css" />
        </head>
    
        <body>
            <!-- The Modal -->
            <div class="modal-backdrop" id="modalBackdrop"></div>
            <div id="signatureModal" style="display: none">
                <div class="modal-header">
                    <div class="modal-heading">Signature</div>
                    <button class="modal-close-button" onClick="closeModal()">X</button>
                </div>
                <div>
                    <canvas id="signatureCanvas" width="500" height="200"></canvas>
                </div>
                <div>
                    <button onClick="saveSignature(this)">Create</button>
                    <button onClick="clearCanvas()">Clear</button>
                </div>
            </div>
    
            <!-- Include Signature Pad script -->
            <script src="https://cdn.jsdelivr.net/npm/signature_pad@4.0.0/dist/signature_pad.umd.min.js"></script>
            <script type="application/javaScript" src="cna-form-script.js"></script>
            <div class="page-no">1</div>
            <div class="page" id="page">
                <div class="header">
                    <img src="../../images/ip-form-icon.png" />
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> December 2020-E </span>
                    </div>
                </div>
                <div class="container container-height">
                    <div class="main-heading">
                        <span class="heading">Comprehensive Nursing Assessment</span>
                        <span>To be performed by a Registered Nurse</span>
                    </div>
    
                    <div class="flex-container">
                        <div class="input-container implementation-plan">
                            <h6>Name of Individual</h6>
                            <input oninput="handleInputChange(this)" name="lastNameFirstName" />
                        </div>
                        <div class="input-container">
                            <h6>Date of Birth (MM/DD/YYYY)</h6>
                            <input type="date" oninput="handleInputChange(this)" name="dateOfBirth" />
                        </div>
                        <div class="input-container">
                            <h6>Today's Date (MM/DD/YYYY)</h6>
                            <input type="date" oninput="handleInputChange(this)" name="currentDate" />
                        </div>
                    </div>
    
                    <div class="head">
                        <h2>I. Review</h2>
                        <h4>Review of Health Care Team</h4>
                    </div>
    
                    <table class="mt-0 mb-12 w-100 review-form" id="review-form">
                        <tr id="implementation-strategy-table-header">
                            <th class="width-15"></th>
                            <th>Health Care Practitioners</th>
                            <th class="width-15">Date Last Seen</th>
                            <th>Comments</th>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Primary Care</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Psychiatrist</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Neurologist</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Dentist</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Optometrist</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><input type="date" oninput="handleInputChange(this)" /></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
                    </table>
    
                    <table class="mt-0 mb-12 w-100 review-form" id="natural-support">
                        <tr id="implementation-strategy-table-header">
                            <th>Natural Supports</th>
                            <th>Relationship</th>
                            <th>Telephone No.</th>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Client Responsible Adult (CRA)</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td class="bg-white">Guardian</td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                            <td><textarea rows="1" oninput="handleTextarea(this)"></textarea></td>
                        </tr>
                    </table>
    
                    <div class="head">
                        <h4>Health History: Psychiatric and Medical Diagnosis</h4>
                    </div>
    
                    <div class="additional-details cna mt-0">
                        <div class="checkbox-div flex-container">
                            <label for="axis-1">Axis I:</label>
                            <textarea class="w-100" oninput="handleTextarea(this)" id="axis-1"></textarea>
                        </div>
                    </div>
                    <div class="additional-details cna mt-0">
                        <div class="checkbox-div flex-container">
                            <label for="axis-2">Axis II:</label>
                            <textarea class="w-100" oninput="handleTextarea(this)" id="axis-2"></textarea>
                        </div>
                    </div>
                    <div class="additional-details cna mt-0">
                        <div class="checkbox-div flex-container">
                            <label for="axis-3">Axis III:</label>
                            <textarea class="w-100" oninput="handleTextarea(this)" id="axis-3"></textarea>
                        </div>
                    </div>
                    <div class="additional-details cna mt-0">
                        <div class="checkbox-div flex-container">
                            <label for="axis-4">Axis IV:</label>
                            <textarea class="w-100" oninput="handleTextarea(this)" id="axis-4"></textarea>
                        </div>
                    </div>
                    <div class="additional-details cna mt-0">
                        <div class="checkbox-div flex-container">
                            <label for="axis-5">Axis V:</label>
                            <textarea class="w-100" oninput="handleTextarea(this)" id="axis-5"></textarea>
                        </div>
                    </div>
                    <div class="additional-details cna mt-0">
                        <div class="checkbox-div flex-container">
                            <label for="axis-6">History of Major Medical/Surgical Occurrences:</label>
                            <textarea class="w-100" oninput="handleTextarea(this)" id="axis-6"></textarea>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">2</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 2 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="table-head">
                        <h2>Review of Current Medications</h2>
                        <h4>Include OTCs, vitamins and herbs</h4>
                    </div>
    
                    <table id="implementation-strategy-table" class="w-100">
                        <tr id="implementation-strategy-table-header">
                            <th>Medication</th>
                            <th style="width: 60px">Dose</th>
                            <th style="width: 60px">Freq.</th>
                            <th style="width: 100px">Route</th>
                            <th>Purpose/Rationale</th>
                            <th>Side Effects/Labs</th>
                        </tr>
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
    
                        <tr>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <td><textarea rows="1" oninput="updateTextAreaValueWithItsDimension(this)"></textarea></td>
                            <!-- <td class="table-action-button"><button onclick="handleAddLine(event,'isMultipleCell')">+</button></td> -->
                            <td class="table-action-button"><button onclick="handleRemoveLine(event)">X</button></td>
                        </tr>
                    </table>
    
                    <div class="button-wrapper">
                        <button class="btn addline-btn" onclick="handleAddLine(event)">Add Line</button>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>Allergies Food, Enviromental, etc.</h6>
                            <textarea rows="17" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">3</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 3 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head">
                        <h2>II. Current Status</h2>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6><b>Current medical and psychiatric history</b></h6>
                            <h6>
                                Briefly describe recent changes in health or behavioral status, hospitalizations, falls,
                                seizure activity, restraints, etc., within the past year.
                            </h6>
                            <textarea rows="11" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                What is of primary concern/greatest expressed needs of the individual, legally authorized
                                representative (LAR) or client's responsible adult (CRA) from their own perspective?
                            </h6>
                            <textarea rows="11" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Vital Signs</h4>
                    </div>
                    <div class="vital-signs">
                        <div class="input-container">
                            <h6>Blood Pressure</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
                        <div class="input-container">
                            <h6>Pulse</h6>
                            <div class="d-flex">
                                <div class="d-flex">
                                    <h6>Rate</h6>
                                    <input oninput="handleInputChange(this)" />
                                </div>
    
                                <div class="d-flex">
                                    <h6>Rhythm</h6>
                                    <input oninput="handleInputChange(this)" />
                                </div>
                            </div>
                        </div>
                        <div class="input-container">
                            <h6>Respirations</h6>
                            <div class="d-flex">
                                <div class="d-flex">
                                    <h6>Rate</h6>
                                    <input oninput="handleInputChange(this)" />
                                </div>
    
                                <div class="d-flex">
                                    <h6>Rhythm</h6>
                                    <input oninput="handleInputChange(this)" />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="flex-container">
                        <div class="input-container">
                            <h6>Temperature</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6>Pain Level</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6>Blood Sugar</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6>Weight</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6>Height</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container border-top-none component-code">
                            <h6><b>Comments</b></h6>
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Labs</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6><b>Briefly review ordered labs, dates and abnormal values within the past year.</b></h6>
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">4</div>
            <div class="page" id="page">
                <div id="date-header" class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 4 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="fall-risk-wrapper bordered">
                        <div class="left">
                            <h6>Has a fall risk assessment been completed?</h6>
    
                            <div>
                                <ul>
                                    <li>
                                        <input type="radio" id="no" name="fall-risk" onclick="handleRadioButton(this)" />
                                        <label for="fall-risk">No</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="yes" name="fall-risk" onclick="handleRadioButton(this)" />
                                        <label for="fall-risk">Yes (attached). Fall risk due to:</label><br />
                                        <ul class="sub-menu">
                                            <li>
                                                <input
                                                    type="checkbox"
                                                    id="neurological"
                                                    name="neurological"
                                                    value="neurological"
                                                    onclick="handleCheckedButton(this)"
                                                />
                                                <label for="neurological">Neurological</label>
                                            </li>
    
                                            <li>
                                                <input
                                                    type="checkbox"
                                                    id="musculoskeletal"
                                                    name="musculoskeletal"
                                                    value="musculoskeletal"
                                                    onclick="handleCheckedButton(this)"
                                                />
                                                <label for="musculoskeletal">Musculoskeletal</label>
                                            </li>
    
                                            <li>
                                                <input
                                                    type="checkbox"
                                                    id="unknown"
                                                    name="unknown"
                                                    value="unknown"
                                                    onclick="handleCheckedButton(this)"
                                                />
                                                <label for="unknown">Unknown</label>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right">
                            <div class="textarea-wrapper">
                                <div class="input-container component-code">
                                    <h6><b>Comments</b></h6>
                                    <textarea rows="9" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="head">
                        <h2>III. Review of Systems</h2>
                        <h4>Neurological</h4>
                    </div>
    
                    <div class="neurological bordered">
                        <div class="d-flex">
                            <b class="pl-5">Abnormal Involuntary Movement Scale (AIMS) Assessment:</b>
                            <ul class="d-flex">
                                <li>
                                    <input type="radio" id="no" name="movement-scales" onclick="handleRadioButton(this)" />
                                    <label for="movement-scales">Attached</label>
                                </li>
                                <li>
                                    <input type="radio" id="yes" name="movement-scales" onclick="handleRadioButton(this)" />
                                    <label for="movement-scales">Deferred</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>headaches</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="headaches"
                                            name="headaches"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="headaches" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Dizziness</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="dizziness"
                                            name="dizziness"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="dizziness" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Impaired balance / coordination</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="balance-coordination"
                                            name="balance-coordination"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="no"
                                            name="balance-coordination"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Medication side effects</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="medication-side-effects"
                                            name="medication-side-effects"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="no"
                                            name="medication-side-effects"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Pupils equal and reactive to light and accommodation</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pupils-equals"
                                            name="pupils-equals"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pupils-equals"
                                            name="pupils-equals"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Tremors</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="extrapyramidal-symptoms"
                                            name="extrapyramidal-symptoms"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="extrapyramidal-symptoms"
                                            name="extrapyramidal-symptoms"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Numbness/tingling/Paresthesia</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="numbness-tingling"
                                            name="numbness-tingling"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="numbness-tingling"
                                            name="numbness-tingling"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Paralysis</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="paralysis"
                                            name="paralysis"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="paralysis"
                                            name="paralysis"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Heat/cold reflex</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="heat-cold"
                                            name="heat-cold"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="heat-cold"
                                            name="heat-cold"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Extrapyramidal symptoms</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="tremors" name="tremors" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="tremors" name="tremors" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Seizures</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="seizues" name="seizues" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="seizues" name="seizues" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
                                <li class="no-border">
                                    <h6>Frequency</h6>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </li>
    
                                <li class="no-border">
                                    <h6>Duration</h6>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Petit Mal</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="petit-mal"
                                            name="petit-mal"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="petit-mal"
                                            name="petit-mal"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Absence</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="absence" name="absence" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="absence" name="absence" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Myoclonic (sporadic jerking)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="sporadic-jerking"
                                            name="sporadic-jerking"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="sporadic-jerking"
                                            name="sporadic-jerking"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Clonic (repetitive jerking)</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="clonic" name="clonic" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="clonic" name="clonic" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Tonic (muscle rigidity)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="muscle-rigidity"
                                            name="muscle-rigidity"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="muscle-rigidity"
                                            name="muscle-rigidity"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Atonic (loss of muscle tone)</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="atonic" name="atonic" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="atonic" name="atonic" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top">
                        <h6><b>Comments</b></h6>
                        <textarea class="pl-5" maxlength="6900" rows="14" oninput="handleTextarea(this)"></textarea>
                    </div>
    
                    <div class="head">
                        <h4>Eye, Ear, Nose and Throat</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered border-bottom">
                        <div class="wrapper">
                            <h6>Eye/Vision</h6>
                            <ul class="d-flex border-bottom">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="clear"
                                        name="clear"
                                        value="clear"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="clear">Clear</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="red"
                                        name="red"
                                        value="red"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="red">Red</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="right-impaired"
                                        name="right-impaired"
                                        value="right-impaired"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="right-impaired">Right Impaired</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="left-impaired"
                                        name="left-impaired"
                                        value="left-impaired"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="left-impaired">Left Impaired</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="adaptive-aid"
                                        name="adaptive-aid"
                                        value="adaptive-aid"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="adaptive-aid">Adaptive aid</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <h6>Ears/Hearing</h6>
                            <ul class="d-flex border-bottom">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="normal"
                                        name="normal"
                                        value="normal"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="normal">Normal</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="ringing"
                                        name="ringing"
                                        value="ringing"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="ringing">Ringing</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="right-impaired2"
                                        name="right-impaired2"
                                        value="right-impaired2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="right-impaired2">Right Impaired</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="left-impaired2"
                                        name="left-impaired2"
                                        value="left-impaired2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="left-impaired2">Left Impaired</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="adaptive-aid2"
                                        name="adaptive-aid2"
                                        value="adaptive-aid2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="adaptive-aid2">Adaptive aid</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <h6>Nose/Smell</h6>
                            <ul class="d-flex border-bottom">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="within-normal-limits"
                                        name="within-normal-limits"
                                        value="within-normal-limits"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="within-normal-limits">Within normal limits</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="smell"
                                        name="smell"
                                        value="smell"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="smell">Smell</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="intact"
                                        name="intact"
                                        value="intact"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="intact">Intact</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="not-intact"
                                        name="not-intact"
                                        value="not-intact"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="not-intact">Not Intact</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="nose-bleed"
                                        name="nose-bleed"
                                        value="nose-bleed"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="nose-bleed">Nose Bleed</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequent-sinus-congestion"
                                        name="frequent-sinus-congestion"
                                        value="frequent-sinus-congestion"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequent-sinus-congestion">Frequent sinus congestion</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">5</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 5 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="eye-ear-nose bordered">
                        <div class="wrapper">
                            <ul class="d-flex border-bottom">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequent-sinus"
                                        name="frequent-sinus"
                                        value="frequent-sinus"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequent-sinus">Frequent Sinus Infection</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <h6>Oral</h6>
                            <ul class="d-flex flex-wrap border-bottom">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="within-normal-limits1"
                                        name="within-normal-limits1"
                                        value="within-normal-limits1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="within-normal-limits1">Within normal limits</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="difficulty-chewing"
                                        name="difficulty-chewing"
                                        value="difficulty-chewing"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="difficulty-chewing">Difficulty chewing</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mouth-pain"
                                        name="mouth-pain"
                                        value="mouth-pain"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mouth-pain">Mouth pain</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="halitosis"
                                        name="halitosis"
                                        value="halitosis"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="halitosis">Halitosis</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="dentures"
                                        name="dentures"
                                        value="dentures"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="dentures">Dentures</label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        id="endentulous"
                                        name="endentulous"
                                        value="endentulous"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="endentulous">Endentulous</label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        id="involuntary-tongue-movement"
                                        name="involuntary-tongue-movement"
                                        value="involuntary-tongue-movement"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="involuntary-tongue-movement">Involuntary tongue movement</label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        id="dry-mouth-from-medications"
                                        name="dry-mouth-from-medications"
                                        value="dry-mouth-from-medications"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="dry-mouth-from-medications">Dry mouth from medications</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <h6>Throat</h6>
                            <ul class="d-flex flex-wrap border-bottom">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="within-normal-limits2"
                                        name="within-normal-limits2"
                                        value="within-normal-limits2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="within-normal-limits2">Within normal limits</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="sore-throats"
                                        name="sore-throats"
                                        value="sore-throats"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="sore-throats">Sore throats</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="difficulty-speaking"
                                        name="difficulty-speaking"
                                        value="difficulty-speaking"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="difficulty-speaking">Difficulty speaking</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="difficulty-swallowing"
                                        name="difficulty-swallowing"
                                        value="difficulty-swallowing"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="difficulty-swallowing">Difficulty swallowing</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="tonsil-enlargement"
                                        name="tonsil-enlargement"
                                        value="tonsil-enlargement"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="tonsil-enlargement">Tonsil enlargement</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="thyroid-enlargement"
                                        name="thyroid-enlargement"
                                        value="thyroid-enlargement"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="thyroid-enlargement">Thyroid enlargement</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="history-of-choking"
                                        name="history-of-choking"
                                        value="history-of-choking"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="history-of-choking">History of choking</label>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper d-flex swallow-study">
                            <div class="d-flex">
                                <b class="pl-5">Swallow Study:</b>
                                <ul class="d-flex">
                                    <li>
                                        <input
                                            type="radio"
                                            id="yes"
                                            name="swallow-study"
                                            onclick="handleRadioButton(this)"
                                        />
                                        <label for="swallow-study">Yes</label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            id="no"
                                            name="swallow-study"
                                            onclick="handleRadioButton(this)"
                                        />
                                        <label for="swallow-study">No</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="d-flex date-wrapper">
                                <label>Date:</label>
                                <input type="date" oninput="handleInputChange(this)" />
                            </div>
                        </div>
    
                        <div class="pl-5">
                            <div class="d-flex">
                                <label>Results:</label>
                                <input type="text" oninput="handleInputChange(this)" />
                            </div>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top mb-0">
                        <div class="input-container no-border-input component-code">
                            <label class="pl-5">Comments</label>
                            <textarea rows="3" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-10">
                        <h4>Cardiovascular</h4>
                    </div>
    
                    <div class="neurological bordered">
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Chest pain</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="chest-pain"
                                            name="chest-pain"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="chest-pain"
                                            name="chest-pain"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Edema</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="edema" name="edema" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="edema" name="edema" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>High/Low blood pressure</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="high-low-blood-pressure"
                                            name="high-low-blood-pressure"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="high-low-blood-pressure"
                                            name="high-low-blood-pressure"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                                <li class="no-border">
                                    <h6>Normal Range:</h6>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Cool/Numb extremities</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="cool-numb-extremities"
                                            name="cool-numb-extremities"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="cool-numb-extremities"
                                            name="cool-numb-extremities"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Activities of daily living (ADL) limitations</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="activities-of-daily-living-limitations"
                                            name="activities-of-daily-living-limitations"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="activities-of-daily-living-limitations"
                                            name="activities-of-daily-living-limitations"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Capillary refill less than or equal to two seconds</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="capillary-refill-less-than-or-equal-to-two-seconds"
                                            name="capillary-refill-less-than-or-equal-to-two-seconds"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="capillary-refill-less-than-or-equal-to-two-seconds"
                                            name="capillary-refill-less-than-or-equal-to-two-seconds"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Compression stockings</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="compression-stockings"
                                            name="compression-stockings"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="compression-stockings"
                                            name="compression-stockings"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top">
                        <h6><b>Comments</b></h6>
                        <textarea class="pl-5" rows="3" oninput="handleTextarea(this)"></textarea>
                    </div>
    
                    <div class="head">
                        <h4>Respiratory</h4>
                    </div>
    
                    <div class="neurological bordered">
                        <div class="d-flex">
                            <b class="pl-5">Breathing:</b>
                            <ul class="d-flex flex-wrap">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="slow"
                                        name="slow"
                                        value="slow"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="slow">Slow</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="normal"
                                        name="normal"
                                        value="normal"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="normal">Normal</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="rapid"
                                        name="rapid"
                                        value="rapid"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="rapid">Rapid</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="shallow"
                                        name="shallow"
                                        value="shallow"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="shallow">Shallow</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="painful"
                                        name="painful"
                                        value="painful"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="painful">Painful</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Short of breath</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="short-of-breath"
                                            name="short-of-breath"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="no"
                                            name="short-of-breath"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Wheezing</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="wheezing"
                                            name="wheezing"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="wheezing" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Cough</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="cough" name="cough" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="no" name="cough" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Productive</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="productive"
                                            name="productive"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="productive" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Feeding tube</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="feeding-tube"
                                            name="feeding-tube"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="feeding-tube"
                                            name="feeding-tube"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Positioning orders</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="positioning-orders"
                                            name="positioning-orders"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="positioning-orders"
                                            name="positioning-orders"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Aspiration history</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="aspiration-history"
                                            name="aspiration-history"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="aspiration-history"
                                            name="aspiration-history"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Pneumonia history</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pneumonia-history"
                                            name="pneumonia-history"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pneumonia-history"
                                            name="pneumonia-history"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Tracheostomy</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="tracheostomy"
                                            name="tracheostomy"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="tracheostomy"
                                            name="tracheostomy"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Continuous positive airway pressure (CPAP)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="continuous-positive-airway-pressure"
                                            name="continuous-positive-airway-pressure"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="continuous-positive-airway-pressure"
                                            name="continuous-positive-airway-pressure"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Inhalation agent</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="inhalation-agent"
                                            name="inhalation-agent"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="inhalation-agent"
                                            name="inhalation-agent"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <h6>Oxygen @</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="oxygen-at"
                                            name="oxygen-at"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="oxygen-at"
                                            name="oxygen-at"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top">
                        <h6><b>Comments</b></h6>
                        <textarea maxlength="6900" class="pl-5" rows="5" oninput="handleTextarea(this)"></textarea>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">6</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 6 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Gastrointestinal</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered">
                        <div class="wrapper pb-0">
                            <ul class="d-flex flex-wrap">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="gastrostomy"
                                        name="gastrostomy"
                                        value="gastrostomy"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="gastrostomy">Gastrostomy</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="jejunostomy"
                                        name="jejunostomy"
                                        value="jejunostomy"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="jejunostomy">Jejunostomy</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="no-tube"
                                        name="no-tube"
                                        value="no-tube"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="no-tube">No tube</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-grid grid-template-column-3">
                        <div class="input-container">
                            <h6>Bowel sounds</h6>
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
    
                        <div class="input-container">
                            <h6>Last bowel movement</h6>
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
    
                        <div class="input-container">
                            <h6>Bowel habits (frequency and description)</h6>
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="grid-3 bordered">
                        <ul class="ul-yn">
                            <li class="no-border">
                                <h6></h6>
                                <div class="d-flex">
                                    <span><b>Y</b></span>
                                    <span><b>N</b></span>
                                </div>
                            </li>
                            <li>
                                <h6>Continent</h6>
                                <div class="flex-container">
                                    <input type="radio" id="continent" name="continent" onclick="handleRadioButton(this)" />
    
                                    <input type="radio" id="no" name="continent" onclick="handleRadioButton(this)" />
                                </div>
                            </li>
    
                            <li>
                                <h6>Frequent nausea</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="frequent-nausea"
                                        name="frequent-nausea"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input type="radio" id="no" name="frequent-nausea" onclick="handleRadioButton(this)" />
                                </div>
                            </li>
    
                            <li>
                                <h6>Frequent vomiting</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="frequent-vomiting"
                                        name="frequent-vomiting"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="frequent-vomiting"
                                        name="frequent-vomiting"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>Indigestion</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="indigestion"
                                        name="indigestion"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="indigestion"
                                        name="indigestion"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>Heartburn</h6>
                                <div class="flex-container">
                                    <input type="radio" id="heartburn" name="heartburn" onclick="handleRadioButton(this)" />
    
                                    <input type="radio" id="heartburn" name="heartburn" onclick="handleRadioButton(this)" />
                                </div>
                            </li>
    
                            <li>
                                <h6>Appetite loss</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="appetite-loss"
                                        name="appetite-loss"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="appetite-loss"
                                        name="appetite-loss"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
                        </ul>
    
                        <ul class="ul-yn">
                            <li class="no-border">
                                <h6></h6>
                                <div class="d-flex">
                                    <span><b>Y</b></span>
                                    <span><b>N</b></span>
                                </div>
                            </li>
                            <li>
                                <h6>Reflux</h6>
                                <div class="flex-container">
                                    <input type="radio" id="reflux" name="reflux" onclick="handleRadioButton(this)" />
    
                                    <input type="radio" id="reflux" name="reflux" onclick="handleRadioButton(this)" />
                                </div>
                            </li>
    
                            <li>
                                <h6>Straining pain</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="straining-pain"
                                        name="straining-pain"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input type="radio" id="no" name="straining-pain" onclick="handleRadioButton(this)" />
                                </div>
                            </li>
    
                            <li>
                                <h6>Diarrhea</h6>
                                <div class="flex-container">
                                    <input type="radio" id="diarrhea" name="diarrhea" onclick="handleRadioButton(this)" />
    
                                    <input type="radio" id="diarrhea" name="diarrhea" onclick="handleRadioButton(this)" />
                                </div>
                            </li>
    
                            <li>
                                <h6>Odd stools</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="odd-stools"
                                        name="odd-stools"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="odd-stools"
                                        name="odd-stools"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>Hemorrhoids</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="hemorrhoids"
                                        name="hemorrhoids"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="hemorrhoids"
                                        name="hemorrhoids"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>independent toileting</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="independent-toileting"
                                        name="independent-toileting"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="independent-toileting"
                                        name="independent-toileting"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
                        </ul>
                        <ul class="ul-yn">
                            <li class="no-border">
                                <h6></h6>
                                <div class="d-flex">
                                    <span><b>Y</b></span>
                                    <span><b>N</b></span>
                                </div>
                            </li>
                            <li>
                                <h6>History of risk constipation</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="history-of-risk-constipation"
                                        name="history-of-risk-constipation"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="history-of-risk-constipation"
                                        name="history-of-risk-constipation"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>History of risk impaction</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="history-of-risk-impaction"
                                        name="history-of-risk-impaction"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="history-of-risk-impaction"
                                        name="history-of-risk-impaction"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>Bowel program</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="bowel-program"
                                        name="bowel-program"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="bowel-program"
                                        name="bowel-program"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
    
                            <li>
                                <h6>
                                    Medications influencing bowels (laxatives, antidiarrheals, iron, calcium,
                                    anticholinergics, etc.)
                                </h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="medications-influencing-bowels"
                                        name="medications-influencing-bowels"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="medications-influencing-bowels"
                                        name="medications-influencing-bowels"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="textarea-wrapper mb-0 pt-5 border-top">
                        <div class="input-container no-border-input component-code">
                            <label class="pl-5">Comments</label>
                            <textarea rows="10" maxlength="6100" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Musculoskeletal</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered">
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Pain</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="pain" name="pain" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="no" name="pain" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Weakness</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="weakness"
                                            name="weakness"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="weakness" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Stiffness</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="stiffness"
                                            name="stiffness"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="stiffness"
                                            name="stiffness"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Prosthesis</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="prosthesis"
                                            name="prosthesis"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="prosthesis" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Deformity</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="deformity"
                                            name="deformity"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="deformity" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Contractures</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="contractures"
                                            name="contractures"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="contractures"
                                            name="contractures"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Impaired range of motion</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="impaired-range-of-motion"
                                            name="impaired-range-of-motion"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="no"
                                            name="impaired-range-of-motion"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Impaired gait</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="impaired-gait"
                                            name="impaired-gait"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="no"
                                            name="impaired-gait"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Adaptive equipment</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="adaptive-equipment"
                                            name="adaptive-equipment"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="adaptive-equipment"
                                            name="adaptive-equipment"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper mb-0 pt-5 border-top">
                        <div class="input-container no-border-input component-code">
                            <label class="pl-5">Comments</label>
                            <textarea rows="10" maxlength="6900" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">7</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 7 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head">
                        <h4>Genitourinary</h4>
                    </div>
    
                    <div class="neurological bordered">
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Incontinent</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="incontinent"
                                            name="incontinent"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="incontinent" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Stress</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="stress" name="stress" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="no" name="stress" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>urge</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="urge" name="urge" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="urge" name="urge" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Bladder program</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="bladder-program"
                                            name="bladder-program"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="bladder-program"
                                            name="bladder-program"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Frequent urination</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="frequent-urination"
                                            name="frequent-urination"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="frequent-urination"
                                            name="frequent-urination"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Cloudy/dark urine</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="cloudy-dark-urine"
                                            name="cloudy-dark-urine"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="cloudy-dark-urine"
                                            name="cloudy-dark-urine"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Bloody urine</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="bloody-urine"
                                            name="bloody-urine"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="bloody-urine"
                                            name="bloody-urine"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Flank pain</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="flank-pain"
                                            name="flank-pain"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input type="radio" id="no" name="flank-pain" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>History of urinary tract infections</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="history-of-urinary-tract-infections"
                                            name="history-of-urinary-tract-infections"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="no"
                                            name="history-of-urinary-tract-infections"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Noctouria</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="noctouria"
                                            name="noctouria"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="noctouria"
                                            name="noctouria"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Discharge</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="discharge"
                                            name="discharge"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="discharge"
                                            name="discharge"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Itching</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="itching" name="itching" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="itching" name="itching" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Hemodialysis</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="hemodialysis"
                                            name="hemodialysis"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="hemodialysis"
                                            name="hemodialysis"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Peritoneal dialysis</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="peritoneal-dialysis"
                                            name="peritoneal-dialysis"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="peritoneal-dialysis"
                                            name="peritoneal-dialysis"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Sexually active</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="sexually-active"
                                            name="sexually-active"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="sexually-active"
                                            name="sexually-active"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Prostate issues</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="prostate-issues"
                                            name="prostate-issues"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="prostate-issues"
                                            name="prostate-issues"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Menstrual cycle regular</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="menstrual-cycle-regular"
                                            name="menstrual-cycle-regular"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="menstrual-cycle-regular"
                                            name="menstrual-cycle-regular"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <h6>Date of last menstrual period:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Menopausal</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="menopausal"
                                            name="menopausal"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="menopausal"
                                            name="menopausal"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <h6>If yes, date of onset:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="date"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top">
                        <h6><b>Comments</b></h6>
                        <textarea class="pl-5" maxlength="6500" rows="3" oninput="handleTextarea(this)"></textarea>
                    </div>
    
                    <div class="head">
                        <h4>Integumentary</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered">
                        <div class="d-flex">
                            <b class="pl-5">Skin Assessment:</b>
                            <ul class="d-flex">
                                <li>
                                    <input
                                        type="radio"
                                        id="attached"
                                        name="skin-assessment"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="skin-assessment">Attached</label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        id="deferred"
                                        name="skin-assessment"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="skin-assessment">Deferred</label>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper pb-0 d-flex">
                            <b class="pl-5">Skin:</b>
                            <ul class="d-flex flex-wrap">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="normal-integumentary"
                                        name="normal-integumentary"
                                        value="normal-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="normal-integumentary">Normal</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="moist-integumentary"
                                        name="moist-integumentary"
                                        value="moist-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="moist-integumentary">Moist</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="dry-integumentary"
                                        name="dry-integumentary"
                                        value="dry-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="dry-integumentary">Dry</label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        id="cyanotic-integumentary"
                                        name="cyanotic-integumentary"
                                        value="cyanotic-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="cyanotic-integumentary">Cyanotic</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="warm-integumentary"
                                        name="warm-integumentary"
                                        value="warm-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="warm-integumentary">Warm</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="pale-integumentary"
                                        name="pale-integumentary"
                                        value="pale-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="pale-integumentary">Pale</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="jaundice-integumentary"
                                        name="jaundice-integumentary"
                                        value="jaundice-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="jaundice-integumentary">Jaundice</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="cold-integumentary"
                                        name="cold-integumentary"
                                        value="cold-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="cold-integumentary">Cold</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="dusky-integumentary"
                                        name="dusky-integumentary"
                                        value="dusky-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="dusky-integumentary">Dusky</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="flushed-integumentary"
                                        name="flushed-integumentary"
                                        value="flushed-integumentary"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="flushed-integumentary">Flushed</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="grid-3 border-bottom">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Open wound</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="open-wound"
                                            name="open-wound"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="open-wound"
                                            name="open-wound"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Bruising</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="bruising"
                                            name="bruising"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="bruising"
                                            name="bruising"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Breakdown related to adaptive aids/prosthesis</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="breakdown-related"
                                            name="breakdown-related"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="breakdown-related"
                                            name="breakdown-related"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Rash</h6>
                                    <div class="flex-container">
                                        <input type="radio" id="rash" name="rash" onclick="handleRadioButton(this)" />
    
                                        <input type="radio" id="rash" name="rash" onclick="handleRadioButton(this)" />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Diaphoretic</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="diaphoretic"
                                            name="diaphoretic"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="diaphoretic"
                                            name="diaphoretic"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Risk for breakdown</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="risk-for-breakdown"
                                            name="risk-for-breakdown"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="risk-for-breakdown"
                                            name="risk-for-breakdown"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Blemished</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="blemished"
                                            name="blemished"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="blemished"
                                            name="blemished"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Poor skin turgor</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="poor-skin-turgor"
                                            name="poor-skin-turgor"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="poor-skin-turgor"
                                            name="poor-skin-turgor"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>History of breakdown</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="history-for-breakdown"
                                            name="history-for-breakdown"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="history-for-breakdown"
                                            name="history-for-breakdown"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="integumentary-img-wrapper border-top">
                        <div class="textarea-wrapper mb-0 pt-5">
                            <div class="input-container no-border-input component-code">
                                <label class="pl-5">Comments</label>
                                <textarea rows="9" maxlength="5100" oninput="handleTextarea(this)"></textarea>
                            </div>
                        </div>
                        <img class="body-img" src="https://visionze-assets.s3.amazonaws.com/assets/body-img.jpg" />
                    </div>
    
                    <div class="head pt-5">
                        <h4>Endocrine</h4>
                    </div>
    
                    <div class="neurological bordered">
                        <div class="grid-2">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Thyroid dysfunction</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thyroid-dysfunction"
                                            name="thyroid-dysfunction"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thyroid-dysfunction"
                                            name="thyroid-dysfunction"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Atypical antipsychotics or other medications affecting blood sugar</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="atypical-antipsychotics"
                                            name="atypical-antipsychotics"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="atypical-antipsychotics"
                                            name="atypical-antipsychotics"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Pre-Diabetic hypoglycemic/hyperglycemic episodes</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pre-diabetic-hypoglycemic"
                                            name="pre-diabetic-hypoglycemic"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pre-diabetic-hypoglycemic"
                                            name="pre-diabetic-hypoglycemic"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li class="no-border">
                                    <h6>Diabetes</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="diabetes"
                                            name="diabetes"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="diabetes"
                                            name="diabetes"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
    
                                    <div class="d-flex">
                                        <h6>If yes, type:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6>Management:</h6>
                                    <ul class="d-flex flex-wrap">
                                        <li class="no-border m-0">
                                            <input
                                                type="checkbox"
                                                id="diet-endorine"
                                                name="diet-endorine"
                                                value="diet-endorine"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="diet-endorine">Diet</label>
                                        </li>
    
                                        <li class="no-border m-0">
                                            <input
                                                type="checkbox"
                                                id="oral-medications-endorine"
                                                name="oral-medications-endorine"
                                                value="oral-medications-endorine"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="oral-medications-endorine">Oral Medications</label>
                                        </li>
    
                                        <li class="no-border m-0">
                                            <input
                                                type="checkbox"
                                                id="insulin-endorine"
                                                name="insulin-endorine"
                                                value="insulin-endorine"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="insulin-endorine">Insulin</label>
                                        </li>
    
                                        <li class="no-border m-0">
                                            <input
                                                type="checkbox"
                                                id="other-diabetes-endorine"
                                                name="other-diabetes-endorine"
                                                value="other-diabetes-endorine"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="other-diabetes-endorine"
                                                >Other injectable medication to manage diabetes</label
                                            >
                                        </li>
                                    </ul>
                                </li>
    
                                <li class="no-border m-0">
                                    <div class="d-flex">
                                        <h6>Desired blood sugar range:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top">
                        <h6><b>Comments</b></h6>
                        <textarea class="pl-5" maxlength="7050" rows="3" oninput="handleTextarea(this)"></textarea>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">8</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 8 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head pt-5">
                        <h2>IV. Additional Health Status Information</h2>
                        <h4>Immunizations: Date last received</h4>
                    </div>
    
                    <div class="flex-container">
                        <div class="input-container">
                            <h6 class="text-center">DPT</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6 class="text-center">TOPV</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6 class="text-center">HIB</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6 class="text-center">MMR</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6 class="text-center">TD</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6 class="text-center">TDS</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
    
                        <div class="input-container">
                            <h6 class="text-center">FLU SHOT</h6>
                            <input oninput="handleInputChange(this)" />
                        </div>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container component-code border-top-none">
                            <h6>Comments</h6>
                            <textarea rows="2" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Nutritional Assessment</h4>
                    </div>
                    <div class="bordered">
                        <div class="wrapper pb-0 d-flex">
                            <b class="pl-5">How nutrition is received:</b>
                            <ul class="d-flex flex-wrap">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="nutrition-oral"
                                        name="nutrition-oral"
                                        value="nutrition-oral"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="nutrition-oral">Oral</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="nutrition-via-jejunostomy"
                                        name="nutrition-via-jejunostomy"
                                        value="nutrition-via-jejunostomy"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="nutrition-via-jejunostomy">Via jejunostomy tube</label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        id="nutrition-via-gastrotomy"
                                        name="nutrition-via-gastrotomy"
                                        value="nutrition-via-gastrotomy"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="nutrition-via-gastrotomy">Via gastrotomy tube if residual</label>
                                    <input oninput="handleInputChange(this)" />
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="nutrition-other"
                                        name="nutrition-other"
                                        value="nutrition-other"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="nutrition-other">Other</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="nutritional-assessment">
                            <div class="d-flex">
                                <div class="d-flex">
                                    <label>Liquid consistency</label>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </div>
    
                                <div class="d-flex">
                                    <label>Therapeutic die</label>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </div>
                            </div>
    
                            <div class="d-flex">
                                <div class="d-flex">
                                    <label>Food texture</label>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </div>
    
                                <div class="d-flex">
                                    <label>Reason/date/ordered by</label>
                                    <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                                </div>
                            </div>
                        </div>
    
                        <div class="grid-2">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Recent weight change</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="recent-weight-change"
                                            name="recent-weight-change"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="recent-weight-change"
                                            name="recent-weight-change"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Recent changes in appetite/medication</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="recent-changes-in-appetite-medication"
                                            name="recent-changes-in-appetite-medication"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="recent-changes-in-appetite-medication"
                                            name="recent-changes-in-appetite-medication"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Satisfied with current weight</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="satisfied-with-current-weight"
                                            name="satisfied-with-current-weight"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="satisfied-with-current-weight"
                                            name="satisfied-with-current-weight"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Food use as a coping mechanism</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="food-use-as-a-coping-mechanism"
                                            name="food-use-as-a-coping-mechanism"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="food-use-as-a-coping-mechanism"
                                            name="food-use-as-a-coping-mechanism"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Assistive devices with eating</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="assistive-devices-with-eating"
                                            name="assistive-devices-with-eating"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="assistive-devices-with-eating"
                                            name="assistive-devices-with-eating"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>
                                        Use of medications that can cause difficulty swallowing (e.g., Abilify, other
                                        psychoactives)
                                    </h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="use-of-medications-that-can-cause-difficulty"
                                            name="use-of-medications-that-can-cause-difficulty"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="use-of-medications-that-can-cause-difficulty"
                                            name="use-of-medications-that-can-cause-difficulty"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Knowledge of four basic food groups</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="knowledge-of-four-basic-food-groups"
                                            name="knowledge-of-four-basic-food-groups"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="knowledge-of-four-basic-food-groups"
                                            name="knowledge-of-four-basic-food-groups"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Access to healthy/appropriate diet</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="access-to-healthy-appropriate-diet"
                                            name="access-to-healthy-appropriate-diet"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="access-to-healthy-appropriate-diet"
                                            name="access-to-healthy-appropriate-diet"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Dietary deficiencies</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="dietary-deficiencies"
                                            name="dietary-deficiencies"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="dietary-deficiencies"
                                            name="dietary-deficiencies"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Adequate fluid intake</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="adequate-fluid-intake"
                                            name="adequate-fluid-intake"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="adequate-fluid-intake"
                                            name="adequate-fluid-intake"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Nutritional supplements</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="nutritional-supplements"
                                            name="nutritional-supplements"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="nutritional-supplements"
                                            name="nutritional-supplements"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Interactions with medications and food</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="interactions-with-medications-and-food"
                                            name="interactions-with-medications-and-food"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="interactions-with-medications-and-food"
                                            name="interactions-with-medications-and-food"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul>
                                <li class="d-flex">
                                    <div class="d-flex">
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                        <label>Lbs.</label>
                                    </div>
                                    <ul class="d-flex">
                                        <li class="d-flex">
                                            <input
                                                type="checkbox"
                                                id="gain"
                                                name="gain"
                                                value="gain"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="gain">gain</label>
                                        </li>
                                        <li class="d-flex">
                                            <input
                                                type="checkbox"
                                                id="loss-over"
                                                name="loss-over"
                                                value="loss-over"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="loss-over">Loss Over</label>
                                        </li>
                                    </ul>
    
                                    <div>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <div class="d-flex">
                                        <label>Desired weight range:</label>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <div class="d-flex">
                                        <label>Number of meals/snacks per day:</label>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top mb-0">
                        <div class="input-container component-code no-border-input">
                            <h6>Comments</h6>
                            <textarea rows="2" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Sleep Patterns</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b
                                    >Average number of hours per night; difficulty falling asleep; number of times awake at
                                    night; number of naps during a day:</b
                                >
                            </h6>
                            <textarea rows="6" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Activity Level/Exercise</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <textarea rows="6" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">9</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 9 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head">
                        <h4>Substance Use/Abuse</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b
                                    >Caffeine, tobacco, alcohol, recreational drugs, history of non-compliance with
                                    prescribed medications:</b
                                >
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Home Life</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b>Satisfaction/Desires:</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Work/School/Day Activity</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b>Satisfaction/Desires:</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Social Life</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b>Satisfaction/Desires:</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Spiritual Life</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b>Satisfaction/Desires:</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Coping Skills</h4>
                    </div>
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head">
                        <h4>Mental Status</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered mental-status border-bottom">
                        <label class="pl-5">Appearance</label>
                        <div class="wrapper d-flex">
                            <h6><b>Posture:</b></h6>
                            <ul class="d-flex">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-normal"
                                        name="appearance-normal"
                                        value="appearance-normal"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-normal">Normal</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-rigid"
                                        name="appearance-rigid"
                                        value="appearance-rigid"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-rigid">Rigid</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-slouched"
                                        name="appearance-slouched"
                                        value="appearance-slouched"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-slouched">Slouched</label>
                                </li>
    
                                <li>
                                    <div class="d-flex">
                                        <div>
                                            <input
                                                type="checkbox"
                                                id="appearance-other"
                                                name="appearance-other"
                                                value="appearance-other"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="appearance-other">Other:</label>
                                        </div>
                                        <div>
                                            <input
                                                oninput="handleInputChange(this)"
                                                type="text"
                                                class="additional-details-input"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper d-flex">
                            <h6><b>Grooming and Dress:</b></h6>
                            <ul class="d-flex">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-appropriate"
                                        name="appearance-appropriate"
                                        value="appearance-appropriate"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-appropriate">Appropriate</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-inappropriate"
                                        name="appearance-inappropriate"
                                        value="appearance-inappropriate"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-inappropriate">Inappropriate</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-disheveled"
                                        name="appearance-disheveled"
                                        value="appearance-disheveled"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-disheveled">Disheveled</label>
                                </li>
    
                                <li>
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="appearance-neat"
                                            name="appearance-neat"
                                            value="appearance-neat"
                                            onclick="handleCheckedButton(this)"
                                        />
                                        <label for="appearance-neat">Neat</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper d-flex">
                            <h6><b>Facial Expression:</b></h6>
                            <ul class="d-flex">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-calm"
                                        name="appearance-calm"
                                        value="appearance-calm"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-calm">Calm</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-alert"
                                        name="appearance-alert"
                                        value="appearance-alert"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-alert">Alert</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-stressed"
                                        name="appearance-stressed"
                                        value="appearance-stressed"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-stressed">Stressed</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-perplexed"
                                        name="appearance-perplexed"
                                        value="appearance-perplexed"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-perplexed">Perplexed</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-tense"
                                        name="appearance-tense"
                                        value="appearance-tense"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-tense">Tense</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-dazed"
                                        name="appearance-dazed"
                                        value="appearance-dazed"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-dazed">Dazed</label>
                                </li>
                                <li>
                                    <div class="d-flex">
                                        <div>
                                            <input
                                                type="checkbox"
                                                id="appearance-other1"
                                                name="appearance-other1"
                                                value="appearance-other1"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="appearance-other1">Other:</label>
                                        </div>
                                        <div>
                                            <input
                                                oninput="handleInputChange(this)"
                                                type="text"
                                                class="additional-details-input"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper d-flex">
                            <h6><b>Eye contact:</b></h6>
                            <ul class="d-flex">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-eyes-not-open"
                                        name="appearance-eyes-not-open"
                                        value="appearance-eyes-not-open"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-eyes-not-open">Eyes not open</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-good-contact"
                                        name="appearance-good-contact"
                                        value="appearance-good-contact"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-good-contact">Good contact</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-avoids-contact"
                                        name="appearance-avoids-contact"
                                        value="appearance-avoids-contact"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-avoids-contact">Avoids contact</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-stares"
                                        name="appearance-stares"
                                        value="appearance-stares"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-stares">Stares</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper d-flex">
                            <h6><b>Speech Quality:</b></h6>
                            <ul class="d-flex">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-clear"
                                        name="appearance-clear"
                                        value="appearance-clear"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-clear">Clear</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-slow"
                                        name="appearance-slow"
                                        value="appearance-slow"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-slow">Slow</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-slurred"
                                        name="appearance-slurred"
                                        value="appearance-slurred"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-slurred">Slurred</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-loud"
                                        name="appearance-loud"
                                        value="appearance-loud"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-loud">Loud</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-rapid"
                                        name="appearance-rapid"
                                        value="appearance-rapid"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-rapid">Rapid</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-incoherent"
                                        name="appearance-incoherent"
                                        value="appearance-incoherent"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-incoherent">Incoherent</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="appearance-mute"
                                        name="appearance-mute"
                                        value="appearance-mute"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="appearance-mute">Mute</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
    
            <div class="page-no">10</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 10 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="eye-ear-nose bordered mental-status mood border-bottom">
                        <label class="pl-5">Mood</label>
                        <div class="wrapper">
                            <ul class="d-grid grid-template-column-4 w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-cooperative"
                                        name="mood-cooperative"
                                        value="mood-cooperative"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-cooperative">Cooperative</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-uncooperative"
                                        name="mood-uncooperative"
                                        value="mood-uncooperative"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-uncooperative">Uncooperative</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-depressed"
                                        name="mood-depressed"
                                        value="mood-depressed"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-depressed">Depressed</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-euphoric"
                                        name="mood-euphoric"
                                        value="mood-euphoric"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-euphoric">Euphoric</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid grid-template-column-4 w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-excited"
                                        name="mood-excited"
                                        value="mood-excited"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-excited">Excited</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-agitated"
                                        name="mood-agitated"
                                        value="mood-agitated"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-agitated">Agitated</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-anxious"
                                        name="mood-anxious"
                                        value="mood-anxious"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-anxious">Anxious</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-suspicious"
                                        name="mood-suspicious"
                                        value="mood-suspicious"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-suspicious">Suspicious</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid grid-template-column-4 w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-irritable"
                                        name="mood-irritable"
                                        value="mood-irritable"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-irritable">Irritable</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-scared"
                                        name="mood-scared"
                                        value="mood-scared"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-scared">Scared</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-hostile"
                                        name="mood-hostile"
                                        value="mood-hostile"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-hostile">Hostile</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="mood-angry"
                                        name="mood-angry"
                                        value="mood-angry"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="mood-angry">Angry</label>
                                </li>
                            </ul>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid w-100">
                                <li>
                                    <div class="d-flex">
                                        <div>
                                            <input
                                                type="checkbox"
                                                id="mood-other-describe"
                                                name="mood-other-describe"
                                                value="mood-other-describe"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="mood-other-describe">Other/Describe:</label>
                                        </div>
                                        <div class="w-50">
                                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Cognition</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered">
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6><b>Cognitive impairment</b></h6>
                                </li>
    
                                <li>
                                    <h6>Mild</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="cognitive-mild"
                                            name="cognitive-mild"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="cognitive-mild"
                                            name="cognitive-mild"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Moderate</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="cognitive-moderate"
                                            name="cognitive-moderate"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="cognitive-moderate"
                                            name="cognitive-moderate"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Severe</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="cognitive-severe"
                                            name="cognitive-severe"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="cognitive-severe"
                                            name="cognitive-severe"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Profound</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="cognitive-profound"
                                            name="cognitive-profound"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="cognitive-profound"
                                            name="cognitive-profound"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6><b>Oriented</b></h6>
                                </li>
    
                                <li>
                                    <h6>Person</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="oriented-person"
                                            name="oriented-person"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="oriented-person"
                                            name="oriented-person"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Place</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="oriented-place"
                                            name="oriented-place"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="oriented-place"
                                            name="oriented-place"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Time</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="oriented-time"
                                            name="oriented-time"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="oriented-time"
                                            name="oriented-time"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6><b>Attention span</b></h6>
                                </li>
    
                                <li>
                                    <h6>Easily distracted</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="attention-easily-distracted"
                                            name="attention-easily-distracted"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="attention-easily-distracted"
                                            name="attention-easily-distracted"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6><b>Memory</b></h6>
                                </li>
    
                                <li>
                                    <h6>Remote</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="memory-remote"
                                            name="memory-remote"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="memory-remote"
                                            name="memory-remote"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Recent</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="memory-recent"
                                            name="memory-recent"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="memory-recent"
                                            name="memory-recent"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Immediate recall</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="memory-immediate-recall"
                                            name="memory-immediate-recall"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="memory-immediate-recall"
                                            name="memory-immediate-recall"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6><b>Emotions</b></h6>
                                </li>
    
                                <li>
                                    <h6>Euphoric</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-euphoric"
                                            name="emotions-euphoric"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-euphoric"
                                            name="emotions-euphoric"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Happy</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-happy"
                                            name="emotions-happy"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-happy"
                                            name="emotions-happy"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Apathetic</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-apathetic"
                                            name="emotions-apathetic"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-apathetic"
                                            name="emotions-apathetic"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Sadness</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-sadness"
                                            name="emotions-sadness"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-sadness"
                                            name="emotions-sadness"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6><b></b></h6>
                                </li>
    
                                <li>
                                    <h6>Depressed</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-depressed"
                                            name="emotions-depressed"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-depressed"
                                            name="emotions-depressed"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Anxious</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-anxious"
                                            name="emotions-anxious"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-anxious"
                                            name="emotions-anxious"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Irritable</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-irritable"
                                            name="emotions-irritable"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-irritable"
                                            name="emotions-irritable"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <h6><b></b></h6>
                                </li>
    
                                <li>
                                    <h6>Hostile feelings</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-hostile-feelings"
                                            name="emotions-hostile-feelings"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-hostile-feelings"
                                            name="emotions-hostile-feelings"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Emotional lability</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-emotional-lability"
                                            name="emotions-emotional-lability"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-emotional-lability"
                                            name="emotions-emotional-lability"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Inappropriate affect</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="emotions-inappropriate-affect"
                                            name="emotions-inappropriate-affect"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="emotions-inappropriate-affect"
                                            name="emotions-inappropriate-affect"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Thoughts</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered">
                        <div class="grid-4">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Delusions</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-delusions"
                                            name="thoughts-delusions"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-delusions"
                                            name="thoughts-delusions"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>If yes: Grandeur</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-grandeur"
                                            name="thoughts-grandeur"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-grandeur"
                                            name="thoughts-grandeur"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Persecutory</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-persecutory"
                                            name="thoughts-persecutory"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-persecutory"
                                            name="thoughts-persecutory"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Somatic</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-somatic"
                                            name="thoughts-somatic"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-somatic"
                                            name="thoughts-somatic"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Other</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-other"
                                            name="thoughts-other"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-other"
                                            name="thoughts-other"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Hallucinations</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-hallucinations"
                                            name="thoughts-hallucinations"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-hallucinations"
                                            name="thoughts-hallucinations"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>If yes: Visual</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-visual"
                                            name="thoughts-visual"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-visual"
                                            name="thoughts-visual"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Auditory</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-auditory"
                                            name="thoughts-auditory"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-auditory"
                                            name="thoughts-auditory"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Tactile</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-tactile"
                                            name="thoughts-tactile"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-tactile"
                                            name="thoughts-tactile"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Olfactory</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-olfactory"
                                            name="thoughts-olfactory"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-olfactory"
                                            name="thoughts-olfactory"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Thought process</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-thought-process"
                                            name="thoughts-thought-process"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-thought-process"
                                            name="thoughts-thought-process"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>If yes: Coherent organized</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-coherent-organized"
                                            name="thoughts-coherent-organized"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-coherent-organized"
                                            name="thoughts-coherent-organized"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Logical</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-logical"
                                            name="thoughts-logical"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-logical"
                                            name="thoughts-logical"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Thought content</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-thought-content"
                                            name="thoughts-thought-content"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-thought-content"
                                            name="thoughts-thought-content"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>If yes: Phobias</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-phobias"
                                            name="thoughts-phobias"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-phobias"
                                            name="thoughts-phobias"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Hypochondria</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-hypochondria"
                                            name="thoughts-hypochondria"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-hypochondria"
                                            name="thoughts-hypochondria"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Antisocial urges</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-antisocial-urges"
                                            name="thoughts-antisocial-urges"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-antisocial-urges"
                                            name="thoughts-antisocial-urges"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Obsessions</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-obsessions"
                                            name="thoughts-obsessions"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-obsessions"
                                            name="thoughts-obsessions"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Suicidal ideations</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-suicidal-ideations"
                                            name="thoughts-suicidal-ideations"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-suicidal-ideations"
                                            name="thoughts-suicidal-ideations"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Homicidal ideations</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="thoughts-homicidal-ideations"
                                            name="thoughts-homicidal-ideations"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="thoughts-homicidal-ideations"
                                            name="thoughts-homicidal-ideations"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top mb-0 pt-5">
                        <div class="input-container no-border-input component-code">
                            <label class="pl-5">Comments</label>
                            <textarea rows="8" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">11</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 11 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head pt-5">
                        <h4>Challenging Behaviors</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered challenging-behaviors">
                        <ul class="ul-yn top">
                            <li class="no-border">
                                <h6>Are medications used to control any behaviors?</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="medications-used-to-control-y"
                                        name="medications-used-to-control"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="medications-used-to-control-y">Y</label>
    
                                    <input
                                        type="radio"
                                        id="medications-used-to-control-n"
                                        name="medications-used-to-control"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="medications-used-to-control-y">N</label>
                                </div>
                            </li>
    
                            <li class="no-border">
                                <h6>Currently has a formal Behavior Plan?</h6>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="medications-used-to-control-y"
                                        name="medications-used-to-control-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="medications-used-to-control-y">Y</label>
    
                                    <input
                                        type="radio"
                                        id="medications-used-to-control-n"
                                        name="medications-used-to-control-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="medications-used-to-control-y">N</label>
                                </div>
                            </li>
                        </ul>
                        <h4 class="pl-5 my-10">Use the following scales below for frequency and severity:</h4>
                        <h4 class="pl-10 my-10">
                            For frequency: 1 = less than once per month; 2 = 1 to 3 x month; 3 = 1 to 6 x week; 4 = 1 to 10
                            x day; and 5 = 1 or more x hour.
                        </h4>
                        <h4 class="pl-10 my-10">For severity: 1 = mild; 2 = moderate; 3 = severe; and 4 = critical</h4>
    
                        <div class="challenging-behaviors-table">
                            <table class="w-100">
                                <tr id="implementation-strategy-table-header">
                                    <th></th>
                                    <th>Frequency</th>
                                    <th>Severity</th>
                                    <th>Last Exhibited</th>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Hurtful to self</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Hurtful to others</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Destructive to property</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Pica</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Resists care</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Socially offensive/Disruptive Behavior</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Sexually inappropriate behavior</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">At risk behavior, such as</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white pl-25">Wandering</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white pl-25">Elopement</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white pl-25">Sexually aggressive behavior</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">History of suicide attempt</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
    
                                <tr>
                                    <td class="bg-white">Other serious behavior</td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                    <td>
                                        <input oninput="handleInputChange(this)" class="additional-details-input" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top mb-0 pt-5">
                        <div class="input-container no-border-input component-code">
                            <label class="pl-5">Comments</label>
                            <textarea rows="9" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Communication</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <div class="w-50 d-flex pl-5">
                                <label>Primary language:</label>
                                <input
                                    oninput="handleInputChange(this)"
                                    type="text"
                                    class="additional-details-input w-auto"
                                />
                            </div>
    
                            <div class="py-5 pl-5">
                                <label>Mark ways the individual commonly communicates.</label>
                            </div>
                        </div>
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Verbal</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-verbal"
                                            name="communication-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-verbal"
                                            name="communication-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Limited verbal</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-limited-verbal"
                                            name="communication-limited-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-limited-verbal"
                                            name="communication-limited-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Gestures</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-gestures"
                                            name="communication-gestures"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-gestures"
                                            name="communication-gestures"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Sign language</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-sign-language"
                                            name="communication-sign-language"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-sign-language"
                                            name="communication-sign-language"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Facial expressions</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-facial-expressions"
                                            name="communication-facial-expressions"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-facial-expressions"
                                            name="communication-facial-expressions"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Eye movement</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-eye-movement"
                                            name="communication-eye-movement"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-eye-movement"
                                            name="communication-eye-movement"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Paralinguistics (sounds)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-paralinguistics-sounds"
                                            name="communication-paralinguistics-sounds"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-paralinguistics-sounds"
                                            name="communication-paralinguistics-sounds"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Augmented communication device</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-augmented-communication"
                                            name="communication-augmented-communication"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-augmented-communication"
                                            name="communication-augmented-communication"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <h6>If yes, device type:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Touch</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-touch"
                                            name="communication-touch"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-touch"
                                            name="communication-touch"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Body language</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-body-language"
                                            name="communication-body-language"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-body-language"
                                            name="communication-body-language"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Acting out</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-acting-out"
                                            name="communication-acting-out"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-acting-out"
                                            name="communication-acting-out"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Head Banging</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-head-banging"
                                            name="communication-head-banging"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-head-banging"
                                            name="communication-head-banging"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Other behaviors (describe below)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="communication-other-behaviors-describe"
                                            name="communication-other-behaviors-describe"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="communication-other-behaviors-describe"
                                            name="communication-other-behaviors-describe"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">12</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 12 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="eye-ear-nose bordered communication">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <label>Mark ways that pain is communicated.</label>
                            </div>
                        </div>
                        <div class="grid-3">
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Verbal</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-verbal"
                                            name="pain-communication-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-verbal"
                                            name="pain-communication-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Limited verbal</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-limited-verbal"
                                            name="pain-communication-limited-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-limited-verbal"
                                            name="pain-communication-limited-verbal"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Gestures</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-gestures"
                                            name="pain-communication-gestures"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-gestures"
                                            name="pain-communication-gestures"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Sign language</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-sign-language"
                                            name="pain-communication-sign-language"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-sign-language"
                                            name="pain-communication-sign-language"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Facial expressions</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-facial-expressions"
                                            name="pain-communication-facial-expressions"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-facial-expressions"
                                            name="pain-communication-facial-expressions"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Eye movement</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-eye-movement"
                                            name="pain-communication-eye-movement"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-eye-movement"
                                            name="pain-communication-eye-movement"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Paralinguistics (sounds)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-paralinguistics-sounds"
                                            name="pain-communication-paralinguistics-sounds"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-paralinguistics-sounds"
                                            name="pain-communication-paralinguistics-sounds"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Augmented communication device</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-augmented-communication"
                                            name="pain-communication-augmented-communication"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-augmented-communication"
                                            name="pain-communication-augmented-communication"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <h6>If yes, device type:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
    
                            <ul class="ul-yn">
                                <li class="no-border">
                                    <h6></h6>
                                    <div class="d-flex">
                                        <span><b>Y</b></span>
                                        <span><b>N</b></span>
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Touch</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-touch"
                                            name="pain-communication-touch"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-touch"
                                            name="pain-communication-touch"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Body language</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-body-language"
                                            name="pain-communication-body-language"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-body-language"
                                            name="pain-communication-body-language"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Acting out</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-acting-out"
                                            name="pain-communication-acting-out"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-acting-out"
                                            name="pain-communication-acting-out"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Head Banging</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-head-banging"
                                            name="pain-communication-head-banging"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-head-banging"
                                            name="pain-communication-head-banging"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li>
                                    <h6>Other behaviors (describe below)</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-other-behaviors-describe"
                                            name="pain-communication-other-behaviors-describe"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-other-behaviors-describe"
                                            name="pain-communication-other-behaviors-describe"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="grid-2">
                            <ul class="ul-yn">
                                <li>
                                    <h6>Able to use pain scale</h6>
                                    <div class="flex-container">
                                        <input
                                            type="radio"
                                            id="pain-communication-able-to-use-pain-scale"
                                            name="pain-communication-able-to-use-pain-scale"
                                            onclick="handleRadioButton(this)"
                                        />
    
                                        <input
                                            type="radio"
                                            id="pain-communication-able-to-use-pain-scale"
                                            name="pain-communication-able-to-use-pain-scale"
                                            onclick="handleRadioButton(this)"
                                        />
                                    </div>
                                </li>
    
                                <li class="no-border">
                                    <div class="d-flex">
                                        <h6>If able to use pain scale, list type/name of pain scale:</h6>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper border-top mb-0 pt-5">
                        <div class="input-container no-border-input component-code">
                            <label class="pl-5">Comments</label>
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h2>V. Implementation Assessment</h2>
                        <h4>Health care and Decision Making Capacity</h4>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <label
                                    >The preceding review of functional capabilities, physical and cognitive status, and
                                    limitations indicate this individual's highest level of ability to make health care
                                    decisions.</label
                                >
                            </div>
                        </div>
                        <div class="wrapper">
                            <ul class="d-grid w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="health-decision-1"
                                        name="health-decision-1"
                                        value="health-decision-1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="health-decision-1"
                                        >Probably can make higher level decisions (such as whether to undergo or withdraw
                                        life sustaining treatments that require understanding the nature, probable
                                        consequences, burdens and risks of proposed treatment).</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="health-decision-2"
                                        name="health-decision-2"
                                        value="health-decision-2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="health-decision-2"
                                        >Probably can make limited decisions that require simple understanding, able to
                                        direct own health care, including delegated tasks.</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="health-decision-3"
                                        name="health-decision-3"
                                        value="health-decision-3"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="health-decision-3"
                                        >Probably can express agreement with decisions proposed by someone else.</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="health-decision-4"
                                        name="health-decision-4"
                                        value="health-decision-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="health-decision-4"
                                        >Cannot effectively participate in any kind of health care decision making.</label
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>
                            Support Systems: Discuss the adequacy, reliability, availability, ability to communicate
                            effectively.
                        </h4>
                    </div>
    
                    <table class="mb-12 w-100" id="support-systems-table">
                        <tr id="implementation-strategy-table-header">
                            <th></th>
                            <th style="width: 90px">
                                Adequate
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                </div>
                            </th>
                            <th style="width: 90px">
                                Reliable
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                </div>
                            </th>
                            <th style="width: 90px">
                                Available
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                </div>
                            </th>
                            <th style="width: 90px">
                                Efffective Communicator
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                </div>
                            </th>
                        </tr>
    
                        <tr>
                            <td class="bg-white pl-0 pb-0">
                                <div>
                                    <h6>CRA</h6>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="cra-adequate"
                                        name="cra-adequate"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="cra-adequate"
                                        name="cra-adequate"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="cra-reliable"
                                        name="cra-reliable"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="cra-reliable"
                                        name="cra-reliable"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="cra-available"
                                        name="cra-available"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="cra-available"
                                        name="cra-available"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="cra-effective"
                                        name="cra-effective"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="cra-effective"
                                        name="cra-effective"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="bg-white pl-0 pb-0">
                                <div>
                                    <h6>Host Home or Companion Care (HH/CC) Provider</h6>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="home-host-adequate"
                                        name="home-host-adequate"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="home-host-adequate"
                                        name="home-host-adequate"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="home-host-reliable"
                                        name="home-host-reliable"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="home-host-reliable"
                                        name="home-host-reliable"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="home-host-available"
                                        name="home-host-available"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="home-host-available"
                                        name="home-host-available"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="home-host-effective"
                                        name="home-host-effective"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="home-host-effective"
                                        name="home-host-effective"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="bg-white pl-0 pb-0">
                                <div>
                                    <h6>Guardian/Other</h6>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="guardian-adequate"
                                        name="guardian-adequate"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="guardian-adequate"
                                        name="guardian-adequate"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="guardian-reliable"
                                        name="guardian-reliable"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="guardian-reliable"
                                        name="guardian-reliable"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="guardian-available"
                                        name="guardian-available"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="guardian-available"
                                        name="guardian-available"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td>
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="guardian-effective"
                                        name="guardian-effective"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="guardian-effective"
                                        name="guardian-effective"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">13</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 13 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head">
                        <h4>Stability and Predictability and Need to Reassess</h4>
                    </div>
    
                    <table class="mb-12 w-100" id="stability-predictability-table">
                        <tr id="implementation-strategy-table-header">
                            <th>Health Topic</th>
                            <th style="width: 150px">
                                Is a long-term need non-fluctuating consistent?
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                </div>
                            </th>
                            <th style="width: 200px">
                                Status change possible, or likely to need regular nursing care
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                </div>
                            </th>
                            <th style="width: 150px">Frequency of RN reassessment</th>
                        </tr>
    
                        <tr>
                            <td class="bg-white p-0">
                                <div>
                                    <input class="w-100" oninput="handleInputChange(this)" type="text" />
                                </div>
                            </td>
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term"
                                        name="stability-predictability-long-term"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term"
                                        name="stability-predictability-long-term"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="stability-predictability-status-change"
                                        name="stability-predictability-status-change"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="stability-predictability-status-change"
                                        name="stability-predictability-status-change"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <input class="w-100" oninput="handleInputChange(this)" type="text" />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="bg-white p-0">
                                <div>
                                    <input class="w-100" oninput="handleInputChange(this)" type="text" />
                                </div>
                            </td>
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term1"
                                        name="stability-predictability-long-term1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term1"
                                        name="stability-predictability-long-term1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="stability-predictability-status-change1"
                                        name="stability-predictability-status-change1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="stability-predictability-status-change1"
                                        name="stability-predictability-status-change1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <input class="w-100" oninput="handleInputChange(this)" type="text" />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="bg-white p-0">
                                <div>
                                    <input class="w-100" oninput="handleInputChange(this)" type="text" />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term-2"
                                        name="stability-predictability-long-term-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term-2"
                                        name="stability-predictability-long-term-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term-2"
                                        name="stability-predictability-long-term-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="stability-predictability-long-term-2"
                                        name="stability-predictability-long-term-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <input class="w-100" oninput="handleInputChange(this)" type="text" />
                                </div>
                            </td>
                        </tr>
                    </table>
    
                    <div class="head">
                        <h4>Knowledge: Describe key health understandings/demonstrations</h4>
                    </div>
    
                    <table class="mb-12 w-100" id="health-understandings">
                        <tr id="implementation-strategy-table-header">
                            <th>Health Topic</th>
                            <th></th>
                            <th>
                                Individual
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                    <span>N/A</span>
                                </div>
                            </th>
                            <th>
                                CRA
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                    <span>N/A</span>
                                </div>
                            </th>
                            <th>
                                HH/CC
                                <div class="d-flex justify-content-around">
                                    <span>Y</span>
                                    <span>N</span>
                                    <span>N/A</span>
                                </div>
                            </th>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                        <h6>Knowledgeable</h6>
                    </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-1"
                                        name="health-understanding-knowledgeable-1-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-1"
                                        name="health-understanding-demonstrates-1-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-2"
                                        name="health-understanding-knowledgeable-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-2"
                                        name="health-understanding-demonstrates-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-3"
                                        name="health-understanding-knowledgeable-3-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-3"
                                        name="health-understanding-demonstrates-3-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-4"
                                        name="health-understanding-knowledgeable-4-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-4"
                                        name="health-understanding-demonstrates-4-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-5"
                                        name="health-understanding-knowledgeable-5-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-5"
                                        name="health-understanding-demonstrates-5-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-6"
                                        name="health-understanding-knowledgeable-6-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-6"
                                        name="health-understanding-demonstrates-6-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-7"
                                        name="health-understanding-knowledgeable-7-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-7"
                                        name="health-understanding-demonstrates-7-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Knowledgeable</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-knowledgeable-8"
                                        name="health-understanding-knowledgeable-8-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                        <td class="bg-white textarea-width pl-0 pb-0">
                        <div class="textarea-wrapper">
                            <textarea oninput="handleTextarea(this)"></textarea>
                        </div>
                    </td>
                    <td class="bg-white knowledge-column-width">
                                <h6>Demonstrates Technique</h6>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8-1"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
    
                            <td class="bg-white">
                                <div class="flex-container">
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8-2"
                                        onclick="handleRadioButton(this)"
                                    />
    
                                    <input
                                        type="radio"
                                        id="health-understanding-demonstrates-8"
                                        name="health-understanding-demonstrates-8-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">14</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 14 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="textarea-wrapper">
                        <div class="input-container component-code border-top-none">
                            <h6><b>Comments</b></h6>
                            <textarea rows="15" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Participants in Comprehensive Assessment (Must complete section A, B or C; and RN section)</h4>
                        <h4 class="pt-5">
                            <span class="underline">Option A:</span> In this situation, the individual does not have a
                            guardian/LAR and is able to make decisions regarding health care.
                        </h4>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <h4>To be completed by the Individual:</h4>
                            </div>
                        </div>
                        <div class="wrapper">
                            <ul class="d-grid w-100 pl-5 pr-5">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="participants-comprehensive-1"
                                        name="participants-comprehensive-1"
                                        value="participants-comprehensive-1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="participants-comprehensive-1"
                                        >I have participated in decisions about the overall management of my health care
                                        [§225.1(2)], can make all of my own decisions, am able to direct own health care,
                                        and</label
                                    >
                                    <ul class="d-grid w-100 pl-25">
                                        <li>
                                            <input
                                                type="radio"
                                                id="participants-comprehensive-2"
                                                name="participants-comprehensive-2"
                                                value="participants-comprehensive-2"
                                                onclick="handleRadioButton(this)"
                                            />
                                            <label for="participants-comprehensive-2"
                                                >will not be directing health maintenance activities (HMAs)
                                                [§225.8(2)(D)(i)],</label
                                            >
                                        </li>
                                        <li><b>or</b></li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="participants-comprehensive-3"
                                                name="participants-comprehensive-2"
                                                value="participants-comprehensive-3"
                                                onclick="handleRadioButton(this)"
                                            />
                                            <label for="participants-comprehensive-3"
                                                >agree to train unlicensed personnel in the performance of HMAs.</label
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
    
                        <div class="discontinuation-signature-box signature-wrapper">
                            <div class="d-grid grid-3">
                                <div class="signature-div">
                                    <input type="text" oninput="handleInputChange(this)" />
                                    <label>Printed Name</label>
                                </div>
                                <div class="signature-div" style="flex: 2">
                                    <div
                                        id="signature-container-6"
                                        class="signature-container-modal-open"
                                        onclick="openModal(this)"
                                    ></div>
                                    <label>Signature — Individual</label>
                                </div>
                                <div class="signature-div">
                                    <input type="date" oninput="handleInputChange(this)" />
                                    <label>Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4 class="pt-5">
                            <span class="underline">Option B:</span>In this situation, the individual cannot make decisions
                            regarding health care or has asked for assistance.
                        </h4>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <h4>To be completed by the CRA:</h4>
                            </div>
                        </div>
                        <div class="wrapper">
                            <ul class="d-grid w-100 pl-5 pr-5">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="participants-comprehensive-4"
                                        name="participants-comprehensive-4"
                                        value="participants-comprehensive-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="participants-comprehensive-4"
                                        >have participated in decisions about the overall management of health care.
                                        [§225.1(2)]</label
                                    >
                                    <ul class="d-grid w-100 pl-25">
                                        <li>
                                            <input
                                                type="radio"
                                                id="participants-comprehensive-5"
                                                name="participants-comprehensive-5"
                                                value="participants-comprehensive-5"
                                                onclick="handleRadioButton(this)"
                                            />
                                            <label for="participants-comprehensive-5"
                                                >I will be participating in decisions only, not directing care. No HMAs will
                                                be performed by unlicensed personnel.</label
                                            >
                                        </li>
                                        <li><b>or</b></li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="participants-comprehensive-6"
                                                name="participants-comprehensive-5"
                                                value="participants-comprehensive-6"
                                                onclick="handleRadioButton(this)"
                                            />
                                            <label for="participants-comprehensive-6"
                                                >I agree to train unlicensed personnel in the proper performance of tasks
                                                identified as HMAs, be present when the task is performed or, if not
                                                present, will have observed the unlicensed person perform the task and will
                                                be immediately accessible in person or by phone to the unlicensed personnel
                                                when the task is performed. [§225.8(2)(D) (ii)(I-II)]</label
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
    
                        <div class="discontinuation-signature-box signature-wrapper">
                            <div class="d-grid grid-3">
                                <div class="signature-div">
                                    <input type="text" oninput="handleInputChange(this)" />
                                    <label>Printed Name</label>
                                </div>
                                <div class="signature-div" style="flex: 2">
                                    <div
                                        id="signature-container-7"
                                        class="signature-container-modal-open"
                                        onclick="openModal(this)"
                                    ></div>
                                    <label>Signature — CRA</label>
                                </div>
                                <div class="signature-div">
                                    <input type="date" oninput="handleInputChange(this)" />
                                    <label>Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">15</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 15 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head pt-5">
                        <h4 class="pt-5">
                            <span class="underline">Option C:</span>In this situation, the individual cannot make decisions
                            regarding health care and does not have a single identified adult who is willing and able to
                            participate in decisions about the overall management of the individual's health care.
                            [§225.1(a)(2)]
                        </h4>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <ul class="d-grid w-100 pl-5 pr-5">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="participants-comprehensive-7"
                                        name="participants-comprehensive-7"
                                        value="participants-comprehensive-7"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="participants-comprehensive-7"
                                        >Provider Advocate Committee (PAC) will act as CRA (form attached).</label
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom mt-12">
                        <div class="wrapper py-5 pl-5">
                            <div>
                                <h4>Registered Nurse (RN)</h4>
                            </div>
                        </div>
                        <div class="wrapper py-5 pl-5">
                            <p>
                                I have developed this plan and retain accountability for delegated tasks. Each unlicensed
                                personnel's competency will be verified before allowing delegated tasks to be performed
                                without direct nursing supervision. An RN will be immediately accessible by phone to the
                                unlicensed personnel when the task is performed.
                            </p>
                        </div>
    
                        <div class="discontinuation-signature-box signature-wrapper">
                            <div class="d-grid grid-3">
                                <div class="signature-div">
                                    <input type="text" oninput="handleInputChange(this)" />
                                    <label>Printed Name</label>
                                </div>
                                <div class="signature-div" style="flex: 2">
                                    <div
                                        id="signature-container-8"
                                        class="signature-container-modal-open"
                                        onclick="openModal(this)"
                                    ></div>
                                    <label>Signature — RN</label>
                                </div>
                                <div class="signature-div">
                                    <input type="date" oninput="handleInputChange(this)" />
                                    <label>Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose communication mt-12">
                        <div class="wrapper py-5 pl-5">
                            <div>
                                <h4>Safe Administration of Medications</h4>
                            </div>
                        </div>
                        <div class="wrapper py-5 pl-5">
                            <p>
                                A comprehensive review of functional capabilities, physical and cognitive status,
                                limitations and natural supports rate this individual's ability to take his/her own
                                medications in a safe and appropriate manner according to the five Rights of Medication
                                Administration (correct person, medication [what, why], dose, time, route).
                            </p>
                        </div>
    
                        <div class="wrapper py-5 pl-5">
                            <div class="d-flex">
                                <h6 class="pl-0"><b>RN Delegation Worksheet:</b></h6>
                                <ul class="d-flex">
                                    <li>
                                        <input
                                            type="checkbox"
                                            id="rn-delegation-attached"
                                            name="rn-delegation-attached"
                                            value="rn-delegation-attached"
                                            onclick="handleCheckedButton(this)"
                                        />
                                        <label for="rn-delegation-attached">Attached</label>
                                    </li>
    
                                    <li>
                                        <input
                                            type="checkbox"
                                            id="rn-delegation-na"
                                            name="rn-delegation-na"
                                            value="rn-delegation-na"
                                            onclick="handleCheckedButton(this)"
                                        />
                                        <label for="rn-delegation-na">N/A</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <ul class="d-grid w-100 pl-5 pr-5">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="safe-administration-of-medications-1"
                                        name="safe-administration-of-medications-1"
                                        value="safe-administration-of-medications-1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="safe-administration-of-medications-1"
                                        ><b>Self-Administration of Medication.</b> Individual knows how to safely take each
                                        medication (what, why) dose, route, time of each medication. The individual is
                                        competent to safely self-administer medications independently or independently with
                                        ancillary aid provided to the individual in the individual's self-administered
                                        medication treatment or regimen, such as reminding an individual to take a
                                        medication at the prescribed time, opening and closing a medication container,
                                        pouring a predetermined quantity of liquid <br />
                                        <b>No RN Delegation is necessary.</b>[§225.1(a)(3)]
                                    </label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="safe-administration-of-medications-2"
                                        name="safe-administration-of-medications-2"
                                        value="safe-administration-of-medications-2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="safe-administration-of-medications-2"
                                        ><b
                                            >Administration of medication to an individual by a paid unlicensed person(s) to
                                            ensure that medications are received safely.</b
                                        >
                                        Administration of medications includes removal of an individual/unit dose from a
                                        previously dispensed, properly labeled container; verifying it with the medication
                                        order; giving the correct medication and the correct dose to the proper individual
                                        at the proper time by the proper route; and accurately recording the time and dose
                                        given. [TX BON §225.4(2)]. Check all that apply:
                                    </label>
                                </li>
    
                                <li class="pl-50">
                                    <input
                                        type="checkbox"
                                        id="safe-administration-of-medications-3"
                                        name="safe-administration-of-medications-3"
                                        value="safe-administration-of-medications-3"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="safe-administration-of-medications-3"
                                        ><b>CRA can safely direct as an HMA.</b>
                                        <br />
                                        <b>No RN delegation is necessary.</b>
                                        The individual has a single identified CRA whose knowledge, abilities and
                                        availability qualifies the administration of oral medications (by mouth or through a
                                        permanently placed feeding tube) as an HMA exempt from delegation and is appropriate
                                        per RN judgment. Medications may be administered for stable and predictable
                                        conditions (not initial doses and/or for acute conditions) without RN supervision
                                        provided that the CRA is willing, able and agrees in writing to train the unlicensed
                                        person(s) in performing the task at least once to assure competence and will be
                                        immediately accessible in person or by telecommunications to the unlicensed
                                        person(s) when the task is performed. [§225.4(8), §225.8]
                                    </label>
                                </li>
    
                                <li class="pl-50">
                                    <input
                                        type="checkbox"
                                        id="safe-administration-of-medications-4"
                                        name="safe-administration-of-medications-4"
                                        value="safe-administration-of-medications-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="safe-administration-of-medications-4"
                                        ><b>RN delegation necessary to ensure safe medication administration.</b> <br />
    
                                        RN can safely authorize unlicensed personnel to administer medications for stable
                                        and predictable conditions as defined in §225.4(11) not requiring nursing judgment.
                                        Competency of each unlicensed personnel, including the ability to recognize and
                                        inform the RN of client changes related to the task must be verified by RN. The six
                                        rights of delegation (the right task, the right person to whom the delegation is
                                        made, the right circumstances, the right direction and communication by the RN, the
                                        right supervision, and the right documentation) and all criteria at §225.9 must be
                                        met. CRA lacks knowledge, abilities and/or availability per §225.8 to direct as an
                                        HMA. Individual (if competent), CRA (if one exists) or Provider Advocate Committee
                                        (PAC) must approve the decision of the RN to delegate tasks in writing. See
                                        delegation criteria at §225.9, §225.10
                                    </label>
                                </li>
                            </ul>
                            <div class="d-flex pl-5 pr-5 pt-25">
                                <h6>Routes that may be delegated:</h6>
                                <input
                                    oninput="handleInputChange(this)"
                                    type="text"
                                    class="additional-details-input w-60 ml-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">16</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 16 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <ul class="pl-5 pr-5">
                                <li class="pl-50">
                                    <input
                                        type="checkbox"
                                        id="safe-administration-of-medications-5"
                                        name="safe-administration-of-medications-5"
                                        value="safe-administration-of-medications-5"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="safe-administration-of-medications-5"
                                        ><b
                                            >The RN has determined that delegation is not required because the
                                            parent/LAR/foster care provider can assume responsibility and accountability for
                                            the individual's health care.</b
                                        >
                                        The RN has considered the length of time the individual has been living in the home,
                                        the relationship of the individual and foster care provider, the supports available
                                        to the foster care provider, and has determined that the foster care provider can
                                        safely assume this responsibility. The RN will serve as a resource, consultant or
                                        educator, and will intervene when necessary to ensure safe and effective care.
                                        [§225.6(a)(3)] Documentation of subsequent interventions, including when additional
                                        follow-up is needed, will be a part of the RN's nursing care plan.
                                    </label>
                                </li>
    
                                <li class="pl-50">
                                    <input
                                        type="checkbox"
                                        id="safe-administration-of-medications-6"
                                        name="safe-administration-of-medications-6"
                                        value="safe-administration-of-medications-6"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="safe-administration-of-medications-6"
                                        ><b
                                            >The RN has determined that delegation is not required for oral, topical and
                                            metered dose inhalers.</b
                                        >
                                        <br />
    
                                        The RN has determined that the medications not being delegated to paid unlicensed
                                        personnel are for a stable or predictable condition. The RN or LVN, under the
                                        direction of an RN, has trained and determined the paid unlicensed personnel
                                        competency. [Human Resources Code, Chapter 161, Subchapter D]
                                    </label>
                                </li>
    
                                <li class="pl-50">
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="safe-administration-of-medications-7"
                                            name="safe-administration-of-medications-7"
                                            value="safe-administration-of-medications-7"
                                            onclick="handleCheckedButton(this)"
                                        />
                                        <label for="safe-administration-of-medications-7"
                                            ><b
                                                >Must be administered by a licensed nurse. Medications that may not be
                                                delegated</b
                                            >
                                            are:
                                        </label>
    
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input w-100"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose communication mt-12">
                        <div class="wrapper py-5 pl-5">
                            <div>
                                <h4>Nurse Supervision</h4>
                            </div>
                        </div>
                        <div class="wrapper py-5 pl-5">
                            <p>
                                For each unlicensed personnel, determine in consultation with the individual CRA, LAR or PAC
                                the level of supervision and frequency of supervisory visits, taking into account: the
                                stability of the individual's status; the training, experience and capability of the
                                unlicensed personnel to whom the nursing task is delegated; the nature of the nursing task
                                being delegated; the proximity and availability of the RN to the unlicensed person when the
                                task will be performed and the level of participation of the individual or CRA
                                [§225.9(a)(3)(A-E)]
                            </p>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered communication border-bottom">
                        <div class="wrapper">
                            <div class="d-flex mx-5">
                                <label style="width: max-content; display: flex"
                                    ><b style="width: max-content">Name of Unlicensed Personnel:</b></label
                                >
    
                                <input oninput="handleInputChange(this)" type="text" class="additional-details-input" />
                            </div>
    
                            <div class="py-5 pl-5">
                                <label
                                    >List all who were consulted in determining the level of nurse supervision for the above
                                    named unlicensed personnel:</label
                                >
                            </div>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="unlicensed-personnel-1"
                                        name="unlicensed-personnel-1"
                                        value="unlicensed-personnel-1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="unlicensed-personnel-1">Provider Advocate Committee (PAC)</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="unlicensed-personnel-2"
                                        name="unlicensed-personnel-2"
                                        value="unlicensed-personnel-2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="unlicensed-personnel-2">Legally Authorized Representative (LAR)</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="unlicensed-personnel-3"
                                        name="unlicensed-personnel-3"
                                        value="unlicensed-personnel-3"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="unlicensed-personnel-3">Client Responsible Adult (CRA)</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="unlicensed-personnel-4"
                                        name="unlicensed-personnel-4"
                                        value="unlicensed-personnel-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="unlicensed-personnel-4">Individual</label>
                                </li>
    
                                <li>
                                    <div class="d-flex">
                                        <div>
                                            <input
                                                type="checkbox"
                                                id="unlicensed-personnel-5"
                                                name="unlicensed-personnel-5"
                                                value="unlicensed-personnel-5"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="unlicensed-personnel-5">Other:</label>
                                        </div>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input w-auto"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered border-top-none communication">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <label
                                    >RN follow-up to monitor competency of the above named unlicensed personnel of the
                                    following delegated task(s):</label
                                >
                            </div>
                            <div class="pl-5">
                                <input
                                    oninput="handleInputChange(this)"
                                    type="text"
                                    class="additional-details-input w-70"
                                />
                            </div>
                        </div>
    
                        <div class="wrapper">
                            <div class="pl-5">
                                <label><b>Frequency of required RN monitoring:</b></label>
                            </div>
                            <ul class="d-grid w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="monitor-competency-1"
                                        name="monitor-competency-1"
                                        value="monitor-competency-1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="monitor-competency-1">once additionally within the first</label>
                                    <input
                                        oninput="handleInputChange(this)"
                                        type="text"
                                        class="additional-details-input w-auto"
                                    />
                                    <label for="monitor-competency-1">, then</label>
                                    <ul class="sub-menu">
                                        <li>
                                            <input
                                                type="checkbox"
                                                id="monitor-competency-2"
                                                name="monitor-competency-2"
                                                value="monitor-competency-2"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="monitor-competency-2">monthly</label>
                                        </li>
    
                                        <li>
                                            <input
                                                type="checkbox"
                                                id="monitor-competency-3"
                                                name="monitor-competency-3"
                                                value="monitor-competency-3"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="monitor-competency-3">quarterly</label>
                                        </li>
    
                                        <li>
                                            <input
                                                type="checkbox"
                                                id="monitor-competency-4"
                                                name="monitor-competency-4"
                                                value="monitor-competency-4"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="monitor-competency-4">once additionally within the year</label>
                                        </li>
    
                                        <li>
                                            <input
                                                type="checkbox"
                                                id="monitor-competency-5"
                                                name="monitor-competency-5"
                                                value="monitor-competency-5"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="monitor-competency-5">annually</label>
                                        </li>
                                    </ul>
                                </li>
    
                                <li>
                                    <div class="d-flex">
                                        <div>
                                            <input
                                                type="checkbox"
                                                id="monitor-competency-6"
                                                name="monitor-competency-6"
                                                value="monitor-competency-6"
                                                onclick="handleCheckedButton(this)"
                                            />
                                            <label for="monitor-competency-6">Other:</label>
                                        </div>
                                        <input
                                            oninput="handleInputChange(this)"
                                            type="text"
                                            class="additional-details-input w-auto"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered border-top-none communication">
                        <div class="wrapper">
                            <div class="pl-5">
                                <label><b>Frequency of additional RN or LVN monitoring:</b></label>
                            </div>
                            <ul class="d-grid w-100">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequency-of-additional-rn-1"
                                        name="frequency-of-additional-rn-1"
                                        value="frequency-of-additional-rn-1"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequency-of-additional-rn-1"
                                        >not applicable; no additional monitoring is needed</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequency-of-additional-rn-2"
                                        name="frequency-of-additional-rn-2"
                                        value="frequency-of-additional-rn-2"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequency-of-additional-rn-2">once additionally within the first</label>
                                    <input
                                        oninput="handleInputChange(this)"
                                        type="text"
                                        class="additional-details-input w-auto"
                                    />
                                    <label for="monitor-competency-1">, then</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">17</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 17 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="eye-ear-nose bordered communication">
                        <div class="wrapper">
                            <ul class="sub-menu">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequency-of-additional-rn-3"
                                        name="frequency-of-additional-rn-3"
                                        value="frequency-of-additional-rn-3"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequency-of-additional-rn-3">monthly</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequency-of-additional-rn-4"
                                        name="frequency-of-additional-rn-4"
                                        value="frequency-of-additional-rn-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequency-of-additional-rn-4">quarterly</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="frequency-of-additional-rn-5"
                                        name="frequency-of-additional-rn-5"
                                        value="frequency-of-additional-rn-5"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="frequency-of-additional-rn-5">once additionally within the year</label>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container component-code border-top-none">
                            <h6><b>Notes</b></h6>
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h2>VI. Summary</h2>
                        <h4>Summary/Clinical Impressions</h4>
                    </div>
    
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container component-code">
                            <h6>
                                <b>Strengths as related to health</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container border-top-none component-code">
                            <h6>
                                <b>Consultations recommended</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container border-top-none component-code">
                            <h6>
                                <b>Summary</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Nursing Service Plan</h4>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b>Concerns/Nursing Diagnoses</b>
                            </h6>
                            <textarea rows="5" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="head pt-5">
                        <h4>Intervention/Strategies</h4>
                    </div>
    
                    <table class="mb-12 w-100" id="stability-predictability-table-1">
                        <tr id="implementation-strategy-table-header">
                            <th class="bg-white">Implementation Strategy Objectives</th>
                            <th class="bg-white" style="width: 120px">Start Date</th>
                            <th class="bg-white" style="width: 120px">Target Completion</th>
                            <th class="bg-white" style="width: 120px">Calculation of Units (if applicable)</th>
                            <th class="bg-white" style="width: 120px">Total Units (per strategy)</th>
                        </tr>
    
                        <tr>
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                            <td class="bg-white p-0">
                                <input type="date" oninput="handleInputChange(this)" />
                            </td>
    
                            <td class="bg-white p-0">
                                <input type="date" oninput="handleInputChange(this)" />
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                            <td class="bg-white p-0">
                                <input type="date" oninput="handleInputChange(this)" />
                            </td>
    
                            <td class="bg-white p-0">
                                <input type="date" oninput="handleInputChange(this)" />
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                            <td class="bg-white p-0">
                                <input type="date" oninput="handleInputChange(this)" />
                            </td>
    
                            <td class="bg-white p-0">
                                <input type="date" oninput="handleInputChange(this)" />
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
    
                            <td class="bg-white p-0">
                                <div>
                                    <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">18</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 18 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="head pt-5">
                        <h4>Total Nursing Units Needed</h4>
                    </div>
    
                    <div class="flex-container mb-12">
                        <div class="input-container pl-0 pr-0 pb-0">
                            <h6>RN</h6>
                            <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                        </div>
                        <div class="input-container pl-0 pr-0 pb-0">
                            <h6>RN Specialized</h6>
                            <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                        </div>
                        <div class="input-container pl-0 pr-0 pb-0">
                            <h6>LVN</h6>
                            <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                        </div>
                        <div class="input-container pl-0 pr-0 pb-0">
                            <h6>LVN Specialized</h6>
                            <textarea class="w-100" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper">
                        <div class="input-container component-code">
                            <h6>
                                <b>Desired Outcomes/Goals</b>
                            </h6>
                            <textarea rows="6" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose communication mt-12">
                        <div class="discontinuation-signature-box signature-wrapper">
                            <div class="d-grid grid-3 pl-0 pr-0">
                                <div class="signature-div">
                                    <input type="text" oninput="handleInputChange(this)" />
                                    <label>Printed Name and Credentials</label>
                                </div>
                                <div class="signature-div" style="flex: 2">
                                    <div
                                        id="signature-container-9"
                                        class="signature-container-modal-open"
                                        onclick="openModal(this)"
                                    ></div>
                                    <label>Signature</label>
                                </div>
                                <div class="signature-div">
                                    <input type="date" oninput="handleInputChange(this)" />
                                    <label>Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="head pt-10">
                        <h4 class="text-center"><b>Review of Comprehensive Nursing Assessment by RN:</b></h4>
                        <h5 class="mb-12 mt-0">
                            Note: The nursing assessment must be reviewed at least annually to verify information remains
                            current and decisions remain appropriate.
                        </h5>
                    </div>
    
                    <div class="eye-ear-nose bordered communication">
                        <div class="wrapper">
                            <div class="w-50 d-flex pl-5">
                                <label>Date of Review:</label>
                                <input
                                    oninput="handleInputChange(this)"
                                    type="date"
                                    class="additional-details-input w-auto"
                                />
                            </div>
    
                            <div class="py-5 pl-5">
                                <label>Purpose (must check one):</label>
                            </div>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid w-100">
                                <li>
                                    <input
                                        type="radio"
                                        id="comprehensive-nursing-1"
                                        name="comprehensive-nursing-1"
                                        value="comprehensive-nursing-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="comprehensive-nursing-1"
                                        >review of a temporary or permanent change in the individual's physical health,
                                        support system, mental status, social functioning, ability to perform activities of
                                        daily living or health maintenance activities, or medication or treatment
                                        regimen;</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="radio"
                                        id="comprehensive-nursing-2"
                                        name="comprehensive-nursing-1"
                                        value="comprehensive-nursing-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="comprehensive-nursing-2"
                                        >review assessments, documentation and decisions made by a previous RN; or</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="radio"
                                        id="comprehensive-nursing-3"
                                        name="comprehensive-nursing-1"
                                        value="comprehensive-nursing-3"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="comprehensive-nursing-3"
                                        >annual review of assessments, documentation and decisions to verify information
                                        remains current and decisions remain appropriate.</label
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container border-top-none component-code">
                            <h6>
                                <b>Description of Review:</b>
                            </h6>
                            <textarea rows="4" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container border-top-none component-code">
                            <h6>
                                <b>Action Taken by RN:</b>
                            </h6>
                            <textarea rows="4" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered border-top-none communication border-bottom">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <label>Change(s) in Nursing Service Plan:</label>
                            </div>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid w-100 pl-25">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="comprehensive-nursing-4"
                                        name="comprehensive-nursing-4"
                                        value="comprehensive-nursing-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="comprehensive-nursing-4">No change required</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="comprehensive-nursing-5"
                                        name="comprehensive-nursing-5"
                                        value="comprehensive-nursing-5"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="comprehensive-nursing-5">Nursing service plan revisions:</label>
                                    <input
                                        oninput="handleInputChange(this)"
                                        type="text"
                                        class="additional-details-input w-50"
                                    />
                                </li>
                            </ul>
                        </div>
    
                        <div class="discontinuation-signature-box signature-wrapper">
                            <div class="d-flex pl-0 pr-0 w-70 ml-auto mr-auto">
                                <div class="signature-div" style="flex: 2">
                                    <div
                                        id="signature-container-10"
                                        class="signature-container-modal-open"
                                        onclick="openModal(this)"
                                    ></div>
                                    <label>Signature</label>
                                </div>
                                <div class="signature-div">
                                    <input type="date" oninput="handleInputChange(this)" />
                                    <label>Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
            <div class="page-no">19</div>
            <div class="page" id="page">
                <div class="date-header">
                    <div class="form-no">
                        <span> Form 8584 </span>
                        <span> Page 19 / 12-2020-E </span>
                    </div>
    
                    <div class="flex-wrapper">
                        <div class="d-flex">
                            <label>Individual:</label>
                            <input oninput="handleInputChange(this)" class="additional-details-input" />
                        </div>
                        <div class="d-flex">
                            <label>Date:</label>
                            <input type="date" oninput="handleInputChange(this)" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="eye-ear-nose bordered communication">
                        <div class="wrapper">
                            <!-- <div class="w-50 d-flex pl-5">
                            <label>Date of Review:</label>
                            <input oninput="handleInputChange(this)" type="date" class="additional-details-input w-auto" />
                        </div> -->
    
                            <div class="py-5 pl-5">
                                <label>Purpose (must check one):</label>
                            </div>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid w-100">
                                <li>
                                    <input
                                        type="radio"
                                        id="comprehensive-nursing-2-1"
                                        name="comprehensive-nursing-2-1"
                                        value="comprehensive-nursing-2-1"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="comprehensive-nursing-2-1"
                                        >review of a temporary or permanent change in the individual's physical health,
                                        support system, mental status, social functioning, ability to perform activities of
                                        daily living or health maintenance activities, or medication or treatment
                                        regimen;</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="radio"
                                        id="comprehensive-nursing-2-2"
                                        name="comprehensive-nursing-2-1"
                                        value="comprehensive-nursing-2-2"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="comprehensive-nursing-2-2"
                                        >assessments, documentation and decisions made by a previous RN; or</label
                                    >
                                </li>
    
                                <li>
                                    <input
                                        type="radio"
                                        id="comprehensive-nursing-2-3"
                                        name="comprehensive-nursing-2-1"
                                        value="comprehensive-nursing-2-3"
                                        onclick="handleRadioButton(this)"
                                    />
                                    <label for="comprehensive-nursing-2-3"
                                        >annual review of assessments, documentation and decisions to verify information
                                        remains current and decisions remain appropriate.</label
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container border-top-none component-code">
                            <h6>
                                <b>Description of Review:</b>
                            </h6>
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="textarea-wrapper mb-0">
                        <div class="input-container border-top-none component-code">
                            <h6>
                                <b>Action Taken by RN:</b>
                            </h6>
                            <textarea rows="10" oninput="handleTextarea(this)"></textarea>
                        </div>
                    </div>
    
                    <div class="eye-ear-nose bordered border-top-none communication border-bottom">
                        <div class="wrapper">
                            <div class="py-5 pl-5">
                                <label>Change(s) in Nursing Service Plan:</label>
                            </div>
                        </div>
    
                        <div class="wrapper">
                            <ul class="d-grid w-100 pl-25">
                                <li>
                                    <input
                                        type="checkbox"
                                        id="comprehensive-nursing-2-4"
                                        name="comprehensive-nursing-2-4"
                                        value="comprehensive-nursing-2-4"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="comprehensive-nursing-2-4">No change required</label>
                                </li>
    
                                <li>
                                    <input
                                        type="checkbox"
                                        id="comprehensive-nursing-2-5"
                                        name="comprehensive-nursing-2-5"
                                        value="comprehensive-nursing-2-5"
                                        onclick="handleCheckedButton(this)"
                                    />
                                    <label for="comprehensive-nursing-2-5">Nursing service plan revisions:</label>
                                    <input
                                        oninput="handleInputChange(this)"
                                        type="text"
                                        class="additional-details-input w-50"
                                    />
                                </li>
                            </ul>
                        </div>
    
                        <div class="discontinuation-signature-box signature-wrapper">
                            <div class="d-flex pl-0 pr-0 w-70 ml-auto mr-auto">
                                <div class="signature-div" style="flex: 2">
                                    <div
                                        id="signature-container-11"
                                        class="signature-container-modal-open"
                                        onclick="openModal(this)"
                                    ></div>
                                    <label>Signature</label>
                                </div>
                                <div class="signature-div">
                                    <input type="date" oninput="handleInputChange(this)" />
                                    <label>Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="health-history-rn">
                    <label>RN: </label>
                    <span> <input oninput="handleInputChange(this)" style="width: 280px; height: 18px" type="text" /></span>
                </div>
            </div>
        </body>
    </html>
    `
};
