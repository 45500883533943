import React, { memo } from "react";
import { Col, Row } from "antd";
import CustomHeading from "Components/CustomHeading/CustomHeading";
import "./breadcurmbs.less";

const Breadcrumbs = (props) => {
    return (
        <div className={`breadcurmbsSec cardWithStyle ${props.className}`}>
            <Row align="middle" justify="center">
                <Col md={12} sm={24} xs={24} className="title-icon-sec">
                    <div className="breadcurmbs-icon-sec inline-block">
                        <i className={props.Icon}></i>
                    </div>
                    {props?.Title ? (
                        <CustomHeading className="breadcurmbsHeading inline-block margin-0">
                            {props?.Title}
                        </CustomHeading>
                    ) : null}
                </Col>
                <Col md={12} sm={24} xs={24}>
                    {props?.AddBtn}
                </Col>
            </Row>
        </div>
    );
};
export default memo(Breadcrumbs);
