import { Button, Space } from "antd";
import { UtilService } from "../../util/util.service";

const deletdColumn = (
    editEnvelopeHandler,
    editEnvelopeFormViewHandler,
    downloadEnvelope,
    deleteEnvelope,
    retoreEnvelope
) => {
    return [
        {
            title: "Id",
            key: "Id",
            render: (text) => text?.id
        },
        {
            title: "Name",
            key: "name",
            render: (text) => text?.attributes?.name
        },
        {
            title: "Template Name",
            key: "templateName",
            render: (text) => text?.attributes?.template?.attributes?.name
        },
        {
            title: "Assign Document Name",
            key: "userId",
            render: (text) => text?.attributes?.assign_document_name || "-"
        },
        {
            title: "User Id",
            key: "userId",
            render: (text) => text?.attributes?.user_id || "-"
        },
        {
            title: "Relation Id",
            key: "relationId",
            render: (text) => text?.attributes?.relation_id || "-"
        },
        {
            title: "Identity Key",
            key: "identityKey",
            render: (text) => text?.attributes?.identity_key || "-"
        },

        {
            title: "Created At",
            key: "createdAt",
            render: (text) => UtilService.convertDateToOurFormat(text?.attributes?.createdAt)
        },
        {
            title: "Updated At",
            key: "updatedAt",
            render: (text) => UtilService.convertDateToOurFormat(text?.attributes?.updatedAt)
        },
        {
            title: "Actions",
            key: "action",
            render: (_, record) => (
                <Space>
                    {record?.attributes?.deleted_at == null && (
                        <>
                            <Button type="primary" onClick={(event) => editEnvelopeHandler(event, record)}>
                                Edit
                            </Button>

                            <Button type="primary" onClick={(event) => editEnvelopeFormViewHandler(event, record)}>
                                Form View
                            </Button>

                            <Button type="primary" onClick={(event) => downloadEnvelope(event, record)}>
                                Download
                            </Button>
                            <Button type="danger" onClick={(event) => deleteEnvelope(event, record)}>
                                Delete
                            </Button>
                        </>
                    )}
                    {record?.attributes?.deleted_at != null && (
                        <Button type="danger" onClick={(event) => retoreEnvelope(event, record)}>
                            Retore
                        </Button>
                    )}
                </Space>
            )
        }
    ];
};

export default deletdColumn;
