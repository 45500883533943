import { useMemo } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notification } from "antd";
import { EnvelopeService } from "services/envelopes.service";
import { UtilService } from "util/util.service";
import { authenticatedRoutesConstant } from "util/util.constant";
import {
    globalReactQueryGetOption,
    PAGE_SIZE,
    PAGINATION_STALE_TIME,
    QueryModuleNames,
    QueryNames
} from "./queries.constant";
import { usePrefetchPaginationWithReactQuery } from "./general.queries";

function useQueryEnvelopes(page = 1, take = 10, isPagination = true, deleted = false) {
    const queryName = [QueryNames.envelopes, page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!isPagination
                ? { take: take || 100 }
                : {
                      page: page,
                      take: take
                  }),
            ...(Boolean(deleted) && {
                deleted: true
            })
        };
    }, [page, isPagination]);
    const queryFn = async () => await EnvelopeService.getEnvelopes(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        staleTime: !isPagination ? 0 : PAGINATION_STALE_TIME,
        keepPreviousData: true
    });

    usePrefetchPaginationWithReactQuery(
        queryReturnedValues,
        page,
        QueryNames.envelopes,
        () =>
            EnvelopeService.getEnvelopes({
                ...queryFnParams,
                page: page + 1
            }),
        isPagination
    );

    return queryReturnedValues;
}

function useQueryDeleteEnvelope() {
    const queryClient = useQueryClient();
    const queryFn = async (envelopeId) => await EnvelopeService.deleteEnvelope(envelopeId);
    return useMutation(queryFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryNames.envelopes);
        }
        // onError: () => {
        //     notification.error({
        //         message: "An error occurred while deleting the envelope"
        //     });
        // }
    });
}
function useQueryRestoreEnvelope() {
    const queryClient = useQueryClient();
    const queryFn = async (envelopeId) => await EnvelopeService.restoreEnvelope(envelopeId);
    return useMutation(queryFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryNames.envelopes);
        }
        // onError: () => {
        //     notification.error({
        //         message: "An error occurred while deleting the envelope"
        //     });
        // }
    });
}

function useQueryDownloadEnvelope() {
    const queryFn = async (envelopeId) => await EnvelopeService.downloadEnvelopeById(envelopeId);
    return useMutation(queryFn, {
        onSuccess: (data) => {
            const downloadUrl = data;
            downloadUrl
                ? UtilService.downloadFromUrl(downloadUrl)
                : notification.error({ message: "cannot find the download url" });
        }
        // onError: () => {
        //     notification.error({ message: "An error occurred while deleting the envelope" });
        // }
    });
}

function useQueryGetEnvelopeById(envelopeId, afterFetchedCallback = () => {}, forDisabled = false) {
    const queryClient = useQueryClient();
    const queryName = [QueryModuleNames.envelopes, envelopeId];
    const queryFn = async () => await EnvelopeService.getEnvelopesById(envelopeId);
    return useQuery(queryName, queryFn, {
        onSuccess: (data) => {
            afterFetchedCallback(data);
            queryClient.setQueryData(queryName, data);
        },
        enabled: Boolean(envelopeId) || Boolean(!forDisabled),
        ...globalReactQueryGetOption
    });
}

function useQueryAddEnvelope() {
    const navigate = useNavigate();
    const queryFn = async (payload) => await EnvelopeService.createEnvelope(payload);
    return useMutation(queryFn, {
        onSuccess: () => {
            navigate(authenticatedRoutesConstant.Envelopes);
        }
        // onError: () => {
        //     notification.error({ message: "An error occurred while adding the envelope" });
        // }
    });
}

function useQueryUpdateEnvelope() {
    const queryFn = async ({ payload, envelopeIdParam }) =>
        await EnvelopeService.updateEnvelope(envelopeIdParam, payload);
    return useMutation(queryFn, {
        // onError: () => {
        //     notification.error({ message: "An error occurred while updating the envelope" });
        // }
    });
}

function useQueryEnvelopebyPassedRoute() {
    const queryFn = async ({ payload, headers }) => await EnvelopeService.createEnvelopebyPassedRoute(payload, headers);
    return useMutation(queryFn, {
        // onError: () => {
        //     notification.error({ message: "An error occurred while updating the envelope" });
        // }
    });
}

function useQueryGetEnvelopeByAuthorization(query, onSuccess, forDisabled = false) {
    const queryName = [QueryModuleNames.envelopeAuth];
    const queryFn = async () => await EnvelopeService.getEnvelopeAuthorization(query);
    return useQuery(queryName, queryFn, {
        onSuccess: (data) => {
            onSuccess(data);
        },
        enabled: Boolean(query?.authorization) && Boolean(!forDisabled),
        staleTime: 0,
        ...globalReactQueryGetOption
    });
}

export {
    useQueryEnvelopes,
    useQueryDeleteEnvelope,
    useQueryDownloadEnvelope,
    useQueryGetEnvelopeById,
    useQueryAddEnvelope,
    useQueryUpdateEnvelope,
    useQueryEnvelopebyPassedRoute,
    useQueryGetEnvelopeByAuthorization,
    useQueryRestoreEnvelope
};

