import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";
import { UtilService } from "util/util.service";

const EnvelopeUrl = {
    getEnvelope: "/envelope/get-all-envelopes",
    getEnvelopeById: "/envelope/get-envelope/{id}",
    createEnvelope: "/envelope/create-envelope",
    updateEnvelope: "/envelope/update-envelope/{id}",
    deleteEnvelope: "/envelope/delete-envelope/{id}",
    restoreEnvelope: "/envelope/restore-envelope/{id}",
    downloadEnvelopeById: "/envelope/download-envelope/{id}",
    createEnvelopebyPassedRoute: "/envelope/create-envelope/byPassedRoute",
    getEnvelopeAuthorization: "/envelope/authorization"
};

const getEnvelopes = async (params = {}) => {
    const response = await ApiService.get(EnvelopeUrl.getEnvelope, params);
    return ApiRequestUtil.handleApiRequestTwo(response);
};

const getEnvelopesById = async (envelopeId) => {
    const url = UtilService.createDynamicUrlForApiService(EnvelopeUrl.getEnvelopeById, {
        id: envelopeId
    });
    const response = await ApiService.get(url);
    return ApiRequestUtil.handleApiRequest(response);
};

const createEnvelope = async (payload) => {
    const response = await ApiService.post(EnvelopeUrl.createEnvelope, payload);
    return ApiRequestUtil.handleApiRequest(response);
};

const updateEnvelope = async (envelopeId, payload) => {
    const url = UtilService.createDynamicUrlForApiService(EnvelopeUrl.updateEnvelope, {
        id: envelopeId
    });

    const response = await ApiService.put(url, payload);

    return ApiRequestUtil.handleApiRequest(response);
};

const deleteEnvelope = async (envelopeId) => {
    const url = UtilService.createDynamicUrlForApiService(EnvelopeUrl.deleteEnvelope, {
        id: envelopeId
    });
    const response = await ApiService.deleteRequest(url);
    return ApiRequestUtil.handleApiRequest(response);
};
const restoreEnvelope = async (envelopeId) => {
    const url = UtilService.createDynamicUrlForApiService(EnvelopeUrl.restoreEnvelope, {
        id: envelopeId
    });
    const response = await ApiService.deleteRequest(url);
    return ApiRequestUtil.handleApiRequest(response);
};

const downloadEnvelopeById = async (envelopeId) => {
    const url = UtilService.createDynamicUrlForApiService(EnvelopeUrl.downloadEnvelopeById, {
        id: envelopeId
    });

    const response = await ApiService.get(url);
    return ApiRequestUtil.handleApiRequest(response);
};

const createEnvelopebyPassedRoute = async (payload, headers = []) => {
    ApiService.setHeaders(headers);
    const response = await ApiService.post(EnvelopeUrl.createEnvelopebyPassedRoute, payload);
    return ApiRequestUtil.handleApiRequest(response);
};

const getEnvelopeAuthorization = async (query = {}) => {
    const response = await ApiService.get(EnvelopeUrl.getEnvelopeAuthorization, query);
    return ApiRequestUtil.handleApiRequest(response);
};

export const EnvelopeService = {
    getEnvelopes,
    getEnvelopesById,
    createEnvelope,
    updateEnvelope,
    deleteEnvelope,
    downloadEnvelopeById,
    createEnvelopebyPassedRoute,
    getEnvelopeAuthorization,
    restoreEnvelope
};
