import { Radio } from "antd";
import React, { memo } from "react";

const CustomRadio = (props) => {
    return (
        <div className="custom-radio-container">
            <Radio {...props}>{props.children}</Radio>
        </div>
    );
};

export default memo(CustomRadio);
