/* eslint-disable react-hooks/exhaustive-deps */
import React, { Children, useEffect, useState } from "react";
import { Checkbox, Col, Row, Form, Spin } from "antd";
import CustomButton from "Components/CustomButton/CustomButton";
import CustomCheckbox from "Components/CustomCheckbox/CustomCheckbox";
import CustomForm from "Components/CustomForm/CustomForm";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import CustomSelect from "Components/CustomSelect/CustomSelect";
import { UtilService } from "../../util/util.service";
import { useQueryAddSetting, useQuerySetting, useQueryUpdateSetting } from "queries/setting.queries";
import { SETTING_STATUS, EVENT } from "./constant";
import "./Setting.less";

const Setting = () => {
    const [, setChecked] = useState("");
    const [form] = Form.useForm();
    const { data: setting, isLoading: settingLoading, refetch } = useQuerySetting();
    const getSetting = () => {
        refetch();
    };
    const { mutateAsync: addSettingQuery, isLoading: addSettingLoading } = useQueryAddSetting({
        onSuccess: getSetting
    });

    const { isLoading: updateSettingLoading } = useQueryUpdateSetting({
        onSuccess: getSetting,
        onError: getSetting
    });

    useEffect(
        () =>
            form.setFieldsValue({
                name: setting?.attributes?.name,
                status: setting?.attributes?.status,
                return_url: setting?.attributes?.returnUrl,
                url: setting?.attributes?.url,
                events: setting?.attributes?.triggerEvents?.map((item) => item?.event)
            }),
        [setting]
    );

    const onChange = (checkedValues) => {
        setChecked(checkedValues);
    };
    const onFinish = async (value) => {
        const events = UtilService.filterUniqueArray(value.events);
        const payload = { ...value, events, userId: setting?.id };
        addSettingQuery(payload);
    };
    return (
        <div className="setting">
            <h2>Setting</h2>
            <Spin spinning={settingLoading}>
                <CustomForm name="setting" onFinish={onFinish} form={form} layout="vertical">
                    <Row gutter={20}>
                        <Col sm={24} md={12}>
                            <CustomFormItem label="Name" name="name">
                                <CustomInput placeholder="Name" />
                            </CustomFormItem>
                        </Col>
                        <Col sm={24} md={12}>
                            <CustomFormItem label="Status" name="status">
                                <CustomSelect
                                    placeholder="Status"
                                    options={SETTING_STATUS?.map((singleStatus) => {
                                        return {
                                            label: singleStatus,
                                            value: singleStatus
                                        };
                                    })}
                                />
                            </CustomFormItem>
                        </Col>
                        <Col sm={24} md={24}>
                            <CustomFormItem label="Url to publish" name="url">
                                <CustomInput placeholder="Url" />
                            </CustomFormItem>
                        </Col>
                        <Col sm={24} md={24}>
                            <CustomFormItem label="Return Url's" name="return_url">
                                <CustomInput placeholder="Return Url" />
                            </CustomFormItem>
                        </Col>
                        <Col sm={24} md={24}>
                            <CustomFormItem label="Events" name="events">
                                <Checkbox.Group onChange={onChange}>
                                    {Children.map(EVENT, (item) => (
                                        <CustomCheckbox value={item}>{item}</CustomCheckbox>
                                    ))}
                                </Checkbox.Group>
                            </CustomFormItem>
                        </Col>
                        <Col sm={24} md={24}>
                            <CustomFormItem>
                                <CustomButton
                                    loading={addSettingLoading || updateSettingLoading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </CustomButton>
                            </CustomFormItem>
                        </Col>
                    </Row>
                </CustomForm>
            </Spin>
        </div>
    );
};

export default Setting;

