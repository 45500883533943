import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomSelect from "Components/CustomSelect/CustomSelect";
import RepeaterFields from "Components/RepeaterFields/RepeaterFields";
import { updateField } from "redux/fieldSlice";
import { useMemo } from "react";

function RightDropdownOption({ singleField }) {
    const fields = useSelector((state) => state.fields.fields);
    const dispatch = useDispatch();
    // const [defaultOptions, setDefaultOptions] = useState([]);
    // const getFieldsHandler = (fields) => {
    //     setDefaultOptions(fields);
    //     getRepeaterFields(fields);
    // };

    // const onDefaultDropdownValueChange = (value) => {
    //     onDefaultDropdownValueChangeProps(value);
    // };

    const dropdownChildrens = useMemo(
        () =>
            (fields.find((singleFieldRedux) => singleFieldRedux.id === singleField.id)?.childrens || []).filter(
                (singleChildren) => singleChildren.option !== ""
            ),
        [fields, singleField.id]
    );

    const defaultDropdownOptionChange = (value) => {
        dispatch(
            updateField({
                id: singleField.id,
                value
            })
        );
    };

    return (
        <div className="repeter-sec">
            <CustomFormItem
                name="defaultSelectOption"
                required={false}
                colon={false}
                rules={[
                    {
                        required: false,
                        message: "Please select default select option!"
                    }
                ]}
            >
                <CustomSelect
                    placeholder="Default Option"
                    onChange={defaultDropdownOptionChange}
                    options={dropdownChildrens?.map((items) => {
                        return {
                            label: items.option,
                            value: items.value || items.option
                        };
                    })}
                />
            </CustomFormItem>

            <Form.Item>
                <RepeaterFields />
            </Form.Item>
        </div>
    );
}

export default memo(RightDropdownOption);
