import React, { memo } from "react";
import { Card, Input } from "antd";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import { DEFAULT_FONT_SIZE, QUERY_KEYS_NAMES } from "util/util.constant";

const { TextArea } = Input;
function FormViewName(props) {
    const { singleField, trackPositions, onTextChangeHandler, reviewer, disabled = false } = props;
    const { width, height, fontSize = DEFAULT_FONT_SIZE } = singleField;
    const signerFields = useSelector((state) => state.signerFields.signerFields);
    const textStyle = { height, width, minHeight: "auto", fontSize };
    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            {/* {console.log("singleField", singleField)} */}
            <div className="box-draggable " style={{ width, height }}>
                <Card
                    style={{
                        width,
                        height,
                        ...(singleField?.fontSize && { fontSize })
                    }}
                >
                    {/* {reviewer ? (
                        <p className="m-0 line-height">
                            <b>{singleField.value}</b>
                        </p>
                    ) : ( */}
                    <TextArea
                        title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                        className="form-view-text-area"
                        style={reviewer ? { ...textStyle, pointerEvents: "none" } : textStyle}
                        onChange={(event) => onTextChangeHandler(event, singleField)}
                        status={singleField.validationStatus}
                        // value={singleField.value}
                        value={signerFields ? signerFields?.payload?.name : singleField.value}
                        disabled={disabled}
                    />
                    {/* )} */}
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewName);

