import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import CustomButton from "Components/CustomButton/CustomButton";
import { addDropdownOption, removeDropDownOption, updateDropDownOption } from "../../redux/fieldSlice";

function RepeaterFields() {
    const singleFieldId = useSelector((state) => state.fields.singleFieldId);
    const fields = useSelector((state) => state.fields.fields);
    const dispatch = useDispatch();

    const addOptionHandler = (event) => {
        event.preventDefault();

        dispatch(
            addDropdownOption({
                id: singleFieldId
            })
        );
    };

    const removeField = (event, singleRepeaterField) => {
        event.preventDefault();

        dispatch(
            removeDropDownOption({
                parentFieldId: singleFieldId,
                childrenFieldId: singleRepeaterField.id
            })
        );
    };

    const onChangeOptionHandler = (event, singleRepeaterField) => {
        // event.preventDefault();
        const {
            target: { value = "" }
        } = event;

        dispatch(
            updateDropDownOption({
                parentFieldId: singleFieldId,
                childrenFieldId: singleRepeaterField.id,
                updateValues: {
                    option: value
                }
            })
        );
    };

    const onChangeValueHandler = (event, singleRepeaterField) => {
        const {
            target: { value = "" }
        } = event;

        dispatch(
            updateDropDownOption({
                parentFieldId: singleFieldId,
                childrenFieldId: singleRepeaterField.id,
                updateValues: {
                    value
                }
            })
        );
    };

    const dropdownChildrens = fields.find((singleFieldRedux) => singleFieldRedux.id === singleFieldId)?.childrens || [];

    return (
        <>
            {dropdownChildrens.length > 0 &&
                dropdownChildrens.map((singleRepeaterField, index) => {
                    return (
                        <div className="repeter-row" align="baseline" key={singleRepeaterField?.id || index}>
                            <div className="field-sec">
                                <CustomFormItem>
                                    <CustomInput
                                        placeholder="Option"
                                        onChange={(event) => onChangeOptionHandler(event, singleRepeaterField)}
                                        value={singleRepeaterField?.option}
                                    />
                                </CustomFormItem>

                                <CustomFormItem>
                                    <CustomInput
                                        placeholder="Value"
                                        onChange={(event) => onChangeValueHandler(event, singleRepeaterField)}
                                        value={singleRepeaterField?.value}
                                    />
                                </CustomFormItem>
                            </div>
                            <MinusCircleOutlined
                                className="repeter-icon"
                                onClick={(e) => removeField(e, singleRepeaterField)}
                            />
                        </div>
                    );
                })}

            <CustomButton
                type="dashed"
                className="add-field-btn"
                onClick={addOptionHandler}
                block
                icon={<PlusOutlined />}
            >
                Add Option
            </CustomButton>
        </>
    );
}

export default memo(RepeaterFields);

