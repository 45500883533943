import React, { useState } from "react";
import { Button, Modal, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    useQueryDeleteEnvelope,
    useQueryDownloadEnvelope,
    useQueryEnvelopes,
    useQueryRestoreEnvelope
} from "queries/envelope.queries";
import { authenticatedRoutesConstant, normalRoutesConstant } from "util/util.constant";
import { UtilService } from "util/util.service";
import GridView from "Components/GridView/GridView";
import { PAGE_SIZE } from "queries/queries.constant";
import deletdColumn from "./deletdColumn";

const { confirm } = Modal;

function EnvelopeWithDeleted() {
    const [searchParams] = useSearchParams();
    const take = searchParams.get("take") || 10;
    const [page, setPage] = useState(1);
    const {
        data: envelopes,
        isLoading: envelopeLoading,
        refetch: refetchEnvelopes
    } = useQueryEnvelopes(page, take, true, true);
    const { mutateAsync: deleteEnvelopeByQuery, isLoading: deleteEnvelopeLoading } = useQueryDeleteEnvelope();
    const { mutateAsync: restoreEnvelopeByQuery, isLoading: restoreEnvelopeLoading } = useQueryRestoreEnvelope();
    const { mutateAsync: downloadEnvelopeByIdQuery, isLoading: downloadEnvelopeLoading } = useQueryDownloadEnvelope();

    const navigate = useNavigate();

    const deleteEnvelope = (event, record) => {
        confirm({
            title: "Do you want to delete this envelope?",
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                await deleteEnvelopeByQuery(record?.id);

                refetchEnvelopes();
            },
            onCancel() {}
        });
    };
    const retoreEnvelope = (event, record) => {
        confirm({
            title: "Do you want to restore this envelope?",
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                await restoreEnvelopeByQuery(record?.id);

                refetchEnvelopes();
            },
            onCancel() {}
        });
    };

    const createEnvelopeHandler = (event, record) => {
        navigate(authenticatedRoutesConstant.EnvelopeCreate);
    };

    const editEnvelopeFormViewHandler = (event, record) => {
        if (record?.id && record?.attributes?.template?.id) {
            const convertUrl = UtilService.createDynamicUrl(normalRoutesConstant.EnvelopeFormView, {
                envelope_id: record?.id,
                template_id: record?.attributes?.template?.id
            });
            navigate(convertUrl);
        }
    };

    const downloadEnvelope = async (event, record) => {
        downloadEnvelopeByIdQuery(record?.attributes?.identity_key);
    };

    const editEnvelopeHandler = (event, record) => {
        if (record?.id) {
            const url = UtilService.createDynamicUrl(authenticatedRoutesConstant.EnvelopeEdit, {
                envelope_id: record?.id
            });
            navigate(url);
        }
    };

    const columns = deletdColumn(
        editEnvelopeHandler,
        editEnvelopeFormViewHandler,
        downloadEnvelope,
        deleteEnvelope,
        retoreEnvelope
    );

    return (
        <div>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: "flex"
                }}
            >
                <Button type="primary" onClick={createEnvelopeHandler}>
                    Create Envelope
                </Button>

                <GridView
                    columns={columns}
                    data={envelopes?.data}
                    loading={
                        envelopeLoading || deleteEnvelopeLoading || downloadEnvelopeLoading || restoreEnvelopeLoading
                    }
                    pagination={{
                        currentPage: envelopes?.currentPage,
                        pageSize: take,
                        total: envelopes?.totalCount,
                        onChange: setPage
                    }}
                />
            </Space>
        </div>
    );
}

export default EnvelopeWithDeleted;
