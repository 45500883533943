import React from "react";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    authenticatedRoutesConstant,
    normalRoutesConstant,
    unAuthenticatedRoutesConstant,
    userInfo
} from "util/util.constant";
import EnvelopeFormView from "Container/EnvelopeFormView/EnvelopeFormView";
import TemplateSigning from "Container/TemplateSigning/TemplateSigning";
import ThankYou from "Container/ThankYou/ThankYou";
import Template from "Container/Template/Template";
import TemplateCreateEdit from "Container/TemplateCreateEdit/TemplateCreateEdit";
import TemplateBuilderView from "Container/TemplateBuilderView/TemplateBuilderView";
import Envelope from "Container/Envelope/Envelope";
import EnvelopeCreateEdit from "Container/EnvelopeCreateEdit/EnvelopeCreateEdit";
import Setting from "Container/Setting";
import Login from "Container/Login/Login";
import Register from "Container/Register/Register";
import CustomLayout from "Components/CustomLayout/CustomLayout";
import HTMLEnvelopeFormView from "Container/HTMLEnvelopeFormView/HTMLEnvelopeFormView";
import EnvelopeWithDeleted from "Container/Envelope/EnvelopeWithDeleted";

const logoutClickHandler = (event) => {
    event.preventDefault();
    localStorage.removeItem(userInfo.TOKEN);
    localStorage.removeItem(userInfo.USERNAME);
    window.location.href = unAuthenticatedRoutesConstant.Login;
};

export const sidebarItems = [
    {
        icon: <UserOutlined />,
        label: <Link to={authenticatedRoutesConstant.Templates}>Templates</Link>,
        key: "Templates"
    },
    {
        icon: <UserOutlined />,
        label: <Link to={authenticatedRoutesConstant.Envelopes}>Envelopes</Link>,
        key: "Envelopes"
    },
    {
        icon: <UserOutlined />,
        label: <Link to={authenticatedRoutesConstant.EnvelopeUserSetting}>Setting</Link>,
        key: "Setting"
    },
    {
        icon: <LogoutOutlined />,
        label: <div onClick={logoutClickHandler}>Logout</div>,
        key: "Logout"
    }
];

export const NO_CONDITION_ROUTES = [
    {
        path: normalRoutesConstant.EnvelopeUserFormView,
        element: <EnvelopeFormView />
    },
    {
        path: normalRoutesConstant.EnvelopeFormView,
        element: <EnvelopeFormView />
    },
    {
        path: normalRoutesConstant.TemplateSigning,
        element: <TemplateSigning />
    },
    {
        path: normalRoutesConstant.ThankYou,
        element: <ThankYou />
    },
    {
        path: normalRoutesConstant.HTMLEnvelopeFormView,
        element: <HTMLEnvelopeFormView />
    }
];

export const AUTHENTICATED_ROUTES = [
    {
        path: authenticatedRoutesConstant.Home,
        element: <CustomLayout />,
        children: [
            // Template Module
            {
                path: authenticatedRoutesConstant.Templates,
                element: <Template />,
                index: true
            },
            {
                path: authenticatedRoutesConstant.TemplateCreate,
                element: <TemplateCreateEdit />
            },
            {
                path: authenticatedRoutesConstant.TemplateEdit,
                element: <TemplateCreateEdit />
            },
            {
                path: authenticatedRoutesConstant.TemplateBuilderView,
                element: <TemplateBuilderView />
            },

            // Envelope Module
            {
                path: authenticatedRoutesConstant.Envelopes,
                element: <Envelope />
            },
            {
                path: authenticatedRoutesConstant.EnvelopesDeleted,
                element: <EnvelopeWithDeleted />
            },
            {
                path: authenticatedRoutesConstant.EnvelopeCreate,
                element: <EnvelopeCreateEdit />
            },
            {
                path: authenticatedRoutesConstant.EnvelopeEdit,
                element: <EnvelopeCreateEdit />
            },
            {
                path: authenticatedRoutesConstant.EnvelopeUserSetting,
                element: <Setting />
            }
        ]
    },
    ...NO_CONDITION_ROUTES
];

export const UNAUTHENTICATED_ROUTES = [
    {
        path: unAuthenticatedRoutesConstant.Login,
        element: <Login />
    },
    {
        path: unAuthenticatedRoutesConstant.Register,
        element: <Register />
    },
    ...NO_CONDITION_ROUTES
];
