import { Upload } from "antd";
import React, { memo } from "react";
import "./CustomUpload.less";

const { Dragger } = Upload;
const CustomUpload = (props) => {
    return (
        <span className="kl-custom-upload-container">
            <Dragger {...props}>{props.children}</Dragger>
        </span>
    );
};

export default memo(CustomUpload);
