/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo, useState } from "react";
import { Collapse, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteField, saveSingleFieldId, updateAllFields, updateField } from "redux/fieldSlice";
import CustomButton from "Components/CustomButton/CustomButton";
import CustomCheckbox from "Components/CustomCheckbox/CustomCheckbox";
import CustomForm from "Components/CustomForm/CustomForm";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import CustomSelect from "Components/CustomSelect/CustomSelect";
import RightCheckBoxOption from "./RightCheckBoxOption/RightCheckBoxOption";
import RightRadioButtonOption from "./RightRadioButtonOption/RightRadioButtonOption";
import RightDropdownOption from "./RightDropdownOption/RightDropdownOption";
import RightSignatureOption from "./RightSignatureOption/RightSignatureOption";
import CustomInputNumber from "Components/CustomInputNumber/CustomInputNumber";
import { FONT_SIZE_VALUE, FIELD_TYPES, DEFAULT_QUERY_NAMES, QUERY_KEYS_NAMES } from "util/util.constant";
import { TemplateService } from "services/templates.service";
import { UtilService } from "util/util.service";
import "./TemplateBuilderRightSide.less";
// import usePageVisibility from "hooks/usePageVisibility ";

const { Panel } = Collapse;

function TemplateBuilderRightSide({ loading, setLoading, template_id, currentPdfObject }) {
    const singleFieldId = useSelector((state) => state.fields.singleFieldId);
    const [fieldType, SetFieldType] = useState(null);
    const [queryNotExistCheckbox, setQueryNotExistCheckbox] = useState(false);
    const [singleField, setSingleField] = useState(null);
    const [form] = Form.useForm();
    const fields = useSelector((state) => state.fields.fields);
    const dispatch = useDispatch();
    // const isVisible = usePageVisibility();

    // useEffect(() => {
    //     if (isVisible) {
    //         const oneSecond = 1000;

    //         const apiInterval = setInterval(() => {
    //             if (fields?.length > 0) {
    //                 sendApiRequestEveryInterval();
    //             }
    //         }, oneSecond * 60);

    //         return () => {
    //             clearInterval(apiInterval);
    //         };
    //     }
    // }, [fields, isVisible]);

    useEffect(() => {
        if (singleFieldId) {
            const findObject = fields.find((singleField) => singleField.id === singleFieldId);

            setSingleField(findObject);
            SetFieldType(findObject?.type);
            form.setFieldsValue({
                placeholder: findObject?.placeholder || "",
                isRequired: Boolean(findObject?.required),
                fontSize: findObject?.fontSize,
                textColor: findObject?.textColor,
                xAxis: findObject?.x,
                yAxis: findObject?.y,
                defaultSelectOption: findObject?.value || "Default option",
                groupName: String(findObject?.groupId),
                queryName: findObject?.queryName || "",
                width: findObject?.width,
                height: findObject?.height
            });
        }
    }, [singleFieldId]);

    useEffect(() => {
        if (singleFieldId) {
            const findObject = fields.find((singleField) => singleField.id === singleFieldId);
            console.log("findObject", findObject);
            if (findObject) {
                form.setFieldsValue({
                    // placeholder: findObject?.placeholder || "",
                    xAxis: findObject.x,
                    yAxis: findObject.y,
                    width: findObject?.width,
                    height: findObject?.height
                });
            }
        }
    }, [fields]);

    const onPlaceHolderChange = (event) => {
        event.preventDefault();

        const {
            target: { value = " " }
        } = event;

        dispatch(
            updateField({
                id: singleFieldId,
                placeholder: value
            })
        );
    };

    const onQueryNameChangeInput = (event) => {
        event.preventDefault();

        const {
            target: { value = " " }
        } = event;

        dispatch(
            updateField({
                id: singleFieldId,
                queryName: value
            })
        );
    };

    const onQueryNameChangeDropdown = (value) => {
        dispatch(
            updateField({
                id: singleFieldId,
                queryName: value
            })
        );
    };

    const onWidthChange = (value) => {
        dispatch(
            updateField({
                id: singleFieldId,
                width: value
            })
        );
    };

    const onHeightChange = (value) => {
        dispatch(
            updateField({
                id: singleFieldId,
                height: value
            })
        );
    };

    const handleDeleteField = () => {
        const fieldClone = [...fields];
        const singleFieldFind = fieldClone.find((singleFieldRedux) => singleFieldRedux.id === singleFieldId);

        if (singleFieldFind.type === FIELD_TYPES.checkbox) {
            const removeSameGroupFields = fieldClone.filter(
                (singleFilterField) => singleFilterField.groupId !== singleFieldFind.groupId
            );

            dispatch(updateAllFields(removeSameGroupFields));
        }

        if (singleFieldFind.type === FIELD_TYPES.radiobtn) {
            const removeSameGroupFields = fieldClone.filter(
                (singleFilterField) => singleFilterField.groupId !== singleFieldFind.groupId
            );

            dispatch(updateAllFields(removeSameGroupFields));
        }

        if (singleFieldFind.type === FIELD_TYPES.signature) {
            const removeAllSignedField = fieldClone.filter(
                (singleFilterField) => singleFilterField.parentId !== singleFieldId
            );

            dispatch(updateAllFields(removeAllSignedField));
        }

        dispatch(
            deleteField({
                id: singleFieldId
            })
        );

        dispatch(saveSingleFieldId(null));
    };

    const fontSizeChangeHandler = (value) => {
        dispatch(
            updateField({
                id: singleFieldId,
                fontSize: parseInt(value)
            })
        );
    };

    const colorChangeHandler = (event) => {
        event.preventDefault();
        const {
            target: { value = "" }
        } = event;

        dispatch(
            updateField({
                id: singleFieldId,
                textColor: value
            })
        );
    };

    // const sendApiRequestEveryInterval = () => {
    //     const fieldValues = form.getFieldsValue();
    //     const fieldNames = Object.keys(fieldValues);

    //     const createValueObj = {};
    //     fieldNames.forEach((singleFieldName) => {
    //         const fieldValue = form.getFieldValue(singleFieldName);
    //         if (fieldValue) {
    //             createValueObj[singleFieldName] = fieldValue;
    //         }
    //     });

    //     if (Object.keys(createValueObj).length !== 0) {
    //         onRightSideSave(createValueObj, true);
    //     }
    // };

    const onRightSideSave = async (values, autoSave = false) => {
        const fieldClone = UtilService.deepClone(fields);

        const modifyObject = {
            placeholder: values?.placeholder || "text",
            textColor: values?.textColor,
            boldText: values?.boldText
        };

        if (values?.textSize) {
            modifyObject.fontSize = `${values?.textSize}px`;
        }
        if (fieldType === FIELD_TYPES.checkbox || fieldType === FIELD_TYPES.radiobtn) {
            delete modifyObject.placeholder;
        }

        const tabData = fieldClone.map((singleField) => {
            const tempSingleFieldObject = { ...singleField };

            if (tempSingleFieldObject.fontSize === "undefinedpx") {
                tempSingleFieldObject.fontSize = 8;
            }

            return {
                ...tempSingleFieldObject
            };
        });

        dispatch(updateAllFields(tabData));

        dispatch(
            updateField({
                id: singleFieldId,
                ...modifyObject
            })
        );

        const payload = {
            template_id: template_id,
            tabs: fieldClone
        };

        setLoading(true);
        const data = await TemplateService.createBuilderData(payload);
        if (data) {
            message.success(`template is ${Boolean(autoSave) ? "auto" : ""} saved successfully!`);
        }
        setLoading(false);
    };

    // const getRepeaterFields = (fields) => {
    //     setDropdownRepeaterFields({ ...dropdownRepeaterFields, fields: fields });
    // };

    // const onDefaultDropdownValueChangeProps = (value) => {
    //     setDropdownRepeaterFields({
    //         ...dropdownRepeaterFields,
    //         defaultSelectValue: value
    //     });
    // };

    const onRequiredChanged = (event) => {
        const {
            target: { checked }
        } = event;
        dispatch(
            updateField({
                id: singleFieldId,
                required: checked
            })
        );
    };

    const onQueryExistChanged = (event) => {
        const {
            target: { checked }
        } = event;

        setQueryNotExistCheckbox(checked);
    };

    return (
        <div id="template-builder-right-side" className="template-builder-card-side field-option-side">
            {singleFieldId ? (
                <CustomForm name="basic" autoComplete="off" form={form} onFinish={onRightSideSave}>
                    <Collapse defaultActiveKey={["1"]} expandIconPosition="end">
                        <Panel header="Field Option" key="1">
                            <CustomFormItem
                                name="isRequired"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please check the required field!"
                                    }
                                ]}
                                valuePropName="checked"
                                initialValue={Boolean(singleField?.required)}
                            >
                                <CustomCheckbox onChange={(event) => onRequiredChanged(event)}>Required</CustomCheckbox>
                            </CustomFormItem>

                            <CustomFormItem
                                name="queryExist"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please check the query exist!"
                                    }
                                ]}
                                valuePropName="checked"
                                initialValue={Boolean(queryNotExistCheckbox)}
                            >
                                <CustomCheckbox onChange={(event) => onQueryExistChanged(event)}>
                                    Add New Query
                                </CustomCheckbox>
                            </CustomFormItem>

                            {queryNotExistCheckbox ? (
                                <CustomFormItem
                                    name="queryName"
                                    required={false}
                                    label="Query Name"
                                    colon={false}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please Add Query Name!"
                                        }
                                    ]}
                                >
                                    <CustomInput placeholder="Query Name" onChange={(e) => onQueryNameChangeInput(e)} />
                                </CustomFormItem>
                            ) : (
                                <CustomFormItem
                                    name="queryName"
                                    className="queryNamesDropdown"
                                    required={false}
                                    label="Query Name"
                                    colon={false}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please Add Query Name!"
                                        }
                                    ]}
                                >
                                    <CustomSelect
                                        placeholder="Select Query Name"
                                        allowClear
                                        autoClearSearchValue
                                        showSearch
                                        onChange={onQueryNameChangeDropdown}
                                        options={DEFAULT_QUERY_NAMES?.map((singleDefaultQueryName) => {
                                            return {
                                                label:
                                                    QUERY_KEYS_NAMES[singleDefaultQueryName?.value] ??
                                                    singleDefaultQueryName?.value,
                                                value: singleDefaultQueryName?.value
                                            };
                                        })}
                                    />
                                </CustomFormItem>
                            )}

                            {fieldType !== FIELD_TYPES.checkbox && fieldType !== FIELD_TYPES.radiobtn && (
                                <>
                                    <CustomFormItem
                                        name="placeholder"
                                        label="Place Holder"
                                        required={false}
                                        colon={false}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Please add placeholder!"
                                            }
                                        ]}
                                    >
                                        <CustomInput
                                            placeholder="Placeholder Name"
                                            onChange={(e) => onPlaceHolderChange(e)}
                                        />
                                    </CustomFormItem>

                                    <CustomFormItem
                                        name="width"
                                        label="Width"
                                        required={false}
                                        colon={false}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Please add width!"
                                            }
                                        ]}
                                    >
                                        <CustomInputNumber placeholder="Width" onChange={(e) => onWidthChange(e)} />
                                    </CustomFormItem>

                                    <CustomFormItem
                                        name="height"
                                        label="Height"
                                        required={false}
                                        colon={false}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Please add height!"
                                            }
                                        ]}
                                    >
                                        <CustomInputNumber placeholder="Height" onChange={(e) => onHeightChange(e)} />
                                    </CustomFormItem>
                                </>
                            )}

                            {/* <CustomFormItem
                                name="dataLabel"
                                required={false}
                                colon={false}
                                rules={[
                                {
                                    required: false,
                                    message: "Please add data label!",
                                },
                                ]}
                            >
                                <CustomInput placeholder="Data Label" />
                            </CustomFormItem> */}

                            {fieldType === FIELD_TYPES.checkbox ? (
                                <RightCheckBoxOption singleField={singleField} />
                            ) : fieldType === FIELD_TYPES.radiobtn ? (
                                <RightRadioButtonOption singleField={singleField} />
                            ) : fieldType === FIELD_TYPES.dropdown ? (
                                <RightDropdownOption singleField={singleField} />
                            ) : fieldType === FIELD_TYPES.signature ? (
                                <RightSignatureOption singleField={singleField} />
                            ) : null}
                        </Panel>
                        <Panel header="Formating" key="2">
                            <CustomFormItem
                                name="fontSize"
                                required={false}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select font size!"
                                    }
                                ]}
                                className="font-size-formatting"
                            >
                                <CustomSelect
                                    placeholder="Select Font Size"
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={fontSizeChangeHandler}
                                    options={FONT_SIZE_VALUE?.map((items) => {
                                        return {
                                            label: items?.font_size,
                                            value: items?.font_size
                                        };
                                    })}
                                />
                            </CustomFormItem>
                            {/* <div className="font-btn-sec">
                                <CustomFormItem>
                                <CustomButton className="font-style-btn textWhite">
                                    {<FontAwesomeIcon icon={faBold} />}
                                </CustomButton>
                                </CustomFormItem>

                                <CustomFormItem>
                                <CustomButton className="font-style-btn textWhite">
                                    {<FontAwesomeIcon icon={faItalic} />}
                                </CustomButton>
                                </CustomFormItem>

                                <CustomFormItem name="boldtext">
                                <CustomCheckbox
                                    className="font-style-btn textWhite"
                                    value="bold"
                                >
                                    {<FontAwesomeIcon icon={faUnderline} />}
                                </CustomCheckbox>
                                </CustomFormItem>
                            </div> */}
                            <CustomFormItem
                                name="textColor"
                                className="color-select"
                                required={false}
                                colon={false}
                                label="Select Color"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select color!"
                                    }
                                ]}
                            >
                                <Input type="color" onChange={colorChangeHandler} />
                            </CustomFormItem>
                            {/* <CustomFormItem required={false} name="hideText">
                                    <CustomCheckbox>Hide text with asterisks</CustomCheckbox>
                                </CustomFormItem>
                                <CustomFormItem
                                    required={false}
                                    name="fixedWidth"
                                    className="mb-0"
                                >
                                    <CustomCheckbox>Fixed Width</CustomCheckbox>
                                </CustomFormItem> */}
                        </Panel>

                        <Panel header="Locations" key="3">
                            <CustomFormItem
                                name="xAxis"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                                label="X Axis"
                            >
                                <CustomInput placeholder="X Axis" disabled />
                            </CustomFormItem>

                            <CustomFormItem
                                name="yAxis"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                                label="Y Axis"
                            >
                                <CustomInput placeholder="Y Axis" disabled />
                            </CustomFormItem>
                        </Panel>
                    </Collapse>
                    <div className="save-back-btn-sec">
                        <CustomFormItem>
                            <CustomButton type="danger" className="secondary-btn" onClick={handleDeleteField}>
                                Delete
                            </CustomButton>
                        </CustomFormItem>

                        <CustomFormItem>
                            <CustomButton type="primary" htmlType="submit" loading={loading}>
                                Save
                            </CustomButton>
                        </CustomFormItem>
                    </div>
                </CustomForm>
            ) : (
                <div className="field-is-not-selected-template-right-side">
                    <h2>Field is not selected</h2>
                </div>
            )}
        </div>
    );
}

export default memo(TemplateBuilderRightSide);
