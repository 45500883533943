import React, { memo } from "react";
import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import { FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { saveField } from "redux/fieldSlice";
import { UtilService } from "util/util.service";

function AddEmail({ currentPdfObject }) {
    const dispatch = useDispatch();

    const generateTextField = () => {
        const unique_id = UtilService.generateUniqueId();
        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.email,
            placeholder: FIELD_TYPES.email,
            pageNumber: currentPdfObject.pageNumber,
            queryName: `${FIELD_TYPES.email}-${unique_id}`,
            x: 0,
            y: 0
        };
    };

    const [, dragRef] = useDrag({
        type: fieldDraggleType,
        item: () => {
            const textField = generateTextField();
            return textField;
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const onFieldFinish = () => {
        const textField = generateTextField();
        dispatch(saveField(textField));
    };
    return (
        <Button
            ref={dragRef}
            draggable
            onClick={onFieldFinish}
            className="common-button"
            icon={<i className="paso-textfield"></i>}
        >
            Email
        </Button>
    );
}

export default memo(AddEmail);
