import React, { memo } from "react";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import RepeaterFieldForRadioButton from "Components/RepeaterFields/RepeaterFieldForRadioButton";

function RightRadioButtonOption({ singleField }) {
    return (
        <div className="repeter-sec">
            <CustomFormItem
                name="groupName"
                required={false}
                colon={false}
                rules={[
                    {
                        required: true,
                        message: "Please add radio button group Name!"
                    }
                ]}
            >
                <CustomInput disabled placeholder="Radio Btn Group Name" />
            </CustomFormItem>
            <CustomFormItem name="radiobutton-option">
                <RepeaterFieldForRadioButton singleField={singleField} />
            </CustomFormItem>
        </div>
    );
}

export default memo(RightRadioButtonOption);
