import { Checkbox } from "antd";
import React, { memo } from "react";

const CustomCheckbox = (props) => {
    return (
        <div>
            <Checkbox {...props}>{props.children}</Checkbox>
        </div>
    );
};

export default memo(CustomCheckbox);
