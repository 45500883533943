import React, { memo } from "react";
import Draggable from "react-draggable";
import useWindowSize from "hooks/useWindowSize";
import CustomResizeable from "./CustomResizeable";

function DraggleWithResize({
    singleField,
    onResizeStopHandle,
    onResizeStart,
    // dragStartItem,
    trackPositions,
    selectFieldHandler,
    children,
    className = ""
}) {
    const [screenWidth] = useWindowSize();

    const adjustScaleForDraggableAndResize = screenWidth <= 1440 ? 1 : screenWidth <= 1880 ? 1.8 : 1.8;

    const isItemDraggable = singleField.disableDraggable || false;

    return (
        <Draggable
            onDrag={(e, data) => {
                e.preventDefault();
                if (isItemDraggable) {
                    return false;
                }
            }}
            onStart={(e, data) => {
                e.preventDefault();
                // dragStartItem(data, singleField);
            }}
            onStop={(e, data) => {
                e.preventDefault();

                if (isItemDraggable) {
                    return true;
                }
                trackPositions(data, singleField);
            }}
            handle=".resize-able-item-custom"
            defaultPosition={{ x: singleField.x, y: singleField.y }}
            scale={adjustScaleForDraggableAndResize}
            bounds="parent"
            positionOffset={{ x: 0, y: 0 }}
        >
            <div className="box-draggable" onClick={(event) => selectFieldHandler(event, singleField)}>
                <CustomResizeable
                    singleField={singleField}
                    onResizeStopHandle={onResizeStopHandle}
                    onResizeStart={onResizeStart}
                    className={className}
                >
                    {children}
                </CustomResizeable>
            </div>
        </Draggable>
    );
}

export default memo(DraggleWithResize);

