const handleApiRequest = (response) => {
    if (response.ok) {
        return response?.data?.data;
    }
    throw response?.response;
};

const handleApiRequestTwo = (response) => {
    if (response.ok) {
        return response?.data;
    }
    throw response?.response;
};

export const ApiRequestUtil = {
    handleApiRequest,
    handleApiRequestTwo
};

