import { Card, Input } from "antd";
import React, { memo, useState } from "react";
import Draggable from "react-draggable";
import { QUERY_KEYS_NAMES, DEFAULT_FONT_SIZE } from "util/util.constant";
import "./FormViewInputTextarea.less";

const { TextArea } = Input;
function FormViewInputTextarea(props) {
    const { singleField, trackPositions, onTextChangeHandler, reviewer, disabled = false } = props;
    const { width, height, fontSize = DEFAULT_FONT_SIZE } = singleField;

    const [textValue, setTextValue] = useState(null);

    const textChangeHandler = (event) => {
        if (event?.target?.scrollHeight - 5 <= event?.target?.offsetHeight) {
            setTextValue(event?.target?.value);
        }
    };
    const textStyle = { height, width, minHeight: "auto", fontSize, lineHeight: 1 };

    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div className="box-draggable " style={{ width, height }}>
                <Card style={{ width, height, ...(singleField?.fontSize && { fontSize }) }}>
                    {/* {reviewer ? (
                        <p className="m-0 line-height">
                            <b>{singleField.value}</b>
                        </p>
                    ) : ( */}
                    <TextArea
                        // rows={Math.floor(fontSize / height)}
                        // maxLength={calculateTextLimit(width, height, fontSize) - 2}
                        title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                        className="form-view-text-area"
                        style={reviewer ? { ...textStyle, pointerEvents: "none" } : textStyle}
                        // placeholder="type your text"
                        onChange={(event) => {
                            textChangeHandler(event);
                        }}
                        onBlur={(event) => {
                            onTextChangeHandler(event, singleField);
                        }}
                        status={singleField.validationStatus}
                        value={textValue ?? singleField.value}
                        disabled={disabled}
                    />
                    {/* )} */}
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewInputTextarea);

