export const authenticatedRoutesConstant = {
    Home: "/",
    Templates: "/templates",
    TemplateCreate: "/templates/create",
    TemplateEdit: "/templates/edit/:template_id",
    TemplateBuilderView: "/templates/view/builder/:template_id",
    Envelopes: "/envelopes",
    EnvelopesDeleted: "/envelopes/with-deleted",
    EnvelopeCreate: "/envelopes/create",
    EnvelopeEdit: "/envelopes/edit/:envelope_id",

    EnvelopeUserSetting: "/user/settings",

    EnvelopesNew: "/envelopesnew",
    // EnvelopeFormViewNew: "/envelopenew/view/form/:template_id/:envelope_id",
    TemplatesNew: "/templatesnew"
};

//login,register
export const unAuthenticatedRoutesConstant = {
    Home: "/",
    Login: "/",
    Register: "/register"
};

export const normalRoutesConstant = {
    EnvelopeUserFormView: "/user/envelope-view",
    TemplateSigning: "/template/signing",
    ThankYou: "/thankYou",
    EnvelopeFormView: "/envelope/view/form/:template_id/:envelope_id",
    HTMLEnvelopeFormView: "/user/envelope-html-view"
};

export const userInfo = {
    TOKEN: "TOKEN",
    USERNAME: "username",
    SIGNING_DATA: "signing_data"
};

export const FooterText = "Koderlabs 2022";

export const FIELD_TYPES = {
    text: "text",
    signature: "signature",
    signature_signed_date: "signed-date",
    checkbox: "checkbox",
    radiobtn: "radiobtn",
    datepicker: "datepicker",
    dropdown: "dropdown",
    number: "number",
    time: "time",
    paragraph: "paragraph",
    name: "name",
    company: "company",
    email: "email",
    signerName: "signerName",
    signerEmail: "signerEmail"
};
export const SIGN_TABS_KEY = {
    CreateSign: "CreateSign",
    UploadSign: "UploadSign",
    SignFromName: "SignFromName",
    RecentSign: "RecentSign",
    InitialSign: "InitialSign"
};
export const FONT_SIZE_VALUE = [
    {
        font_size: "8"
    },
    {
        font_size: "9"
    },

    {
        font_size: "10"
    },

    {
        font_size: "11"
    },

    {
        font_size: "12"
    },

    {
        font_size: "14"
    },

    {
        font_size: "16"
    },

    {
        font_size: "18"
    },

    {
        font_size: "22"
    },
    {
        font_size: "24"
    },
    {
        font_size: "26"
    },
    {
        font_size: "28"
    },
    {
        font_size: "36"
    },
    {
        font_size: "48"
    },
    {
        font_size: "72"
    }
];

export const QUERY_KEYS_NAMES = {
    age: "Age",
    diagnosisCodes: "Diagnosis Codes",
    diagnosisNames: "Diagnosis Names",
    lastNursingAssessment: "Last Nursing Assessment",
    waiver: "Waiver",
    fullAddress: "Full Address",
    streetAddress: "Street Address",
    companyAddress: "Company Address",
    county: "County",
    careId: "Care Id",
    medicaidId: "Medicaid Id",
    ipcStarts: "Ipc Starts",
    idrcStart: "Idrc Starts",
    ipcEnds: "Ipc Ends",
    icapEnds: "Icap Ends",
    idrcEnds: "Idrc Ends",
    lon: "Lon",
    lcn: "Lcn",
    startDate: "Start Date",
    rate: "Rate",
    programType: "Program Type",
    annualTraining: "Annual Training",
    contactName: "Contact Name",
    contract: "Contract",
    dateOfBirth: "Date of Birth",
    directDeposit: "Direct Deposit",
    driversLicence: "Drivers License",
    ein: "Ein",
    // includeReference: "Include Reference",
    insurance: "Insurance",
    insuranceExpiry: "Insurance Expiry",
    locationCode: "Location Code",
    medicareId: "Medicare Id",
    nurseLicense: "Nurse License",
    organizationName: "Organization Name",
    payRate: "Pay Rate",
    routingNo: "Routing Number",
    // serviceCord: "Service Cord",
    ssn: "SSN",
    worlEmail: "Work Email",
    service_name: "Service Name",
    company: "Company Name",
    driversLicenceExpiry: "Driver License Expiry",
    annualTrainingExpiry: "Annual Training Expiry",
    guardianExpiry: "Guardian Expiry",
    guardianType: "Guardian Type",
    guardianName: "Guardian Name",
    guardianAddress: "Guardian Address",
    guardianFax: "Guardian Fax",
    guardianEmail: "Guardian Email",
    guardianContact: "Guardian Contact",
    repPayee: "Rep Payee",
    lidda: "Lidda",
    liddaFirstName: "Lidda First Name",
    liddaEmail: "Lidda Email",
    liddaLastName: "Lidda Last Name",
    liddaPhoneNo: "Lidda Phone No",
    liddaFaxNo: "Lidda Fax No",
    accountNo: "Account No",
    bankInstitutionName: "Bank Institution Name",
    componentNode: "componentNode",
    componentCode: "Component Code",
    guardianDocument: "Guardian Document",

    updatedAt: "Updated At",
    workEmail: "Work Email",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    createdAt: "Created At",
    // payRate: "Pay Rate",
    cfcRate: "Cfc Rate",
    issRate: "Iss Rate",
    hhRate: "Hh Rate",
    rehRate: "Reh Rate",
    accountHolderName: "Account Holder Name",
    firstNameLastName: "First Name & Last Name",
    lastNameFirstName: "Last Name & First Name",
    ambulation: "Ambulation",
    communication: "Communication",
    gtube: "G-Tube",
    effectiveDate: "Effective Date",
    coordinatorName: "Coordinator Name"
};

export const DEFAULT_QUERY_NAMES = [
    {
        value: "age"
    },
    {
        value: "coordinatorName"
    },
    {
        value: "effectiveDate"
    },
    {
        value: "ambulation"
    },
    {
        value: "communication"
    },
    {
        value: "gtube"
    },
    {
        value: "diagnosisCodes"
    },
    {
        value: "diagnosisNames"
    },
    {
        value: "liddaFullName"
    },
    {
        value: "firstNameLastName"
    },
    {
        value: "lastNameFirstName"
    },
    {
        value: "accountHolderName"
    },
    {
        value: "accountNo"
    },
    {
        value: "fullAddress"
    },
    {
        value: "streetAddress"
    },
    {
        value: "annualTraining"
    },
    {
        value: "annualTrainingExpiry"
    },
    {
        value: "bankInstitutionName"
    },
    {
        value: "careId"
    },
    {
        value: "company"
    },
    {
        value: "componentCode"
    },
    {
        value: "contactName"
    },
    {
        value: "contract"
    },
    {
        value: "county"
    },
    {
        value: "createdAt"
    },
    {
        value: "dateOfBirth"
    },
    {
        value: "directDeposit"
    },
    {
        value: "driversLicence"
    },
    {
        value: "driversLicenceExpiry"
    },
    {
        value: "ein"
    },
    {
        value: "firstName"
    },
    {
        value: "guardianAddress"
    },
    {
        value: "guardianContact"
    },
    {
        value: "guardianDocument"
    },
    {
        value: "guardianEmail"
    },
    {
        value: "guardianExpiry"
    },
    {
        value: "guardianFax"
    },
    {
        value: "guardianName"
    },
    {
        value: "guardianType"
    },
    {
        value: "icapEnds"
    },
    {
        value: "idrcEnds"
    },
    {
        value: "idrcStart"
    },
    {
        value: "insurance"
    },
    {
        value: "insuranceExpiry"
    },
    {
        value: "ipcEnds"
    },
    {
        value: "ipcStarts"
    },
    {
        value: "lastName"
    },
    {
        value: "lastNursingAssessment"
    },
    {
        value: "lcn"
    },
    {
        value: "lidda"
    },
    {
        value: "liddaEmail"
    },
    {
        value: "liddaFaxNo"
    },
    {
        value: "liddaFirstName"
    },
    {
        value: "liddaLastName"
    },
    {
        value: "liddaPhoneNo"
    },
    {
        value: "locationCode"
    },
    {
        value: "lon"
    },
    {
        value: "medicaidId"
    },
    {
        value: "medicareId"
    },
    {
        value: "name"
    },
    {
        value: "nurseLicense"
    },
    {
        value: "organizationName"
    },
    // {
    //     value: "payRate"
    // },
    {
        value: "cfcRate" //additional field
    },
    {
        value: "issRate" //additional field
    },
    {
        value: "hhRate" //additional field
    },
    {
        value: "rehRate" //additional field
    },
    {
        value: "programType"
    },
    {
        value: "repPayee"
    },
    {
        value: "routingNo"
    },
    {
        value: "services"
    },
    {
        value: "sourceOfIncome"
    },
    {
        value: "ssn"
    },
    {
        value: "startDate"
    },
    {
        value: "updatedAt"
    },
    {
        value: "waiver"
    },
    {
        value: "workEmail"
    },
    {
        value: "companyAddress"
    },
    {
        value: "medicareId"
    }
];
export const envelopeHeaderKey = {
    refreshToken: "refresh_token",
    xEnvelope: "x_envelope",
    clientApiKey: "client_api_key"
};

export const envelopeActionEnum = {
    envelopeFinished: "EnvelopeFinished",
    EnvelopeSent: "EnvelopeSent",
    EnvelopeCreated: "EnvelopeCreated",
    EnvelopeFinished: "EnvelopeFinished",
    EnvelopeFinishLater: "EnvelopeFinishLater",
    EnvelopeDiscard: "EnvelopeDiscard"
};

export const validationErrorStatus = "error";
export const checkBoxAndRadioBtnGroupByFieldName = "groupId";

export const FontFamilyTheme = "autographyregular";

export const FIELD_STATIC_WIDTH = {
    RADIO_BUTTON: {
        width: 9,
        height: 9
    },
    CHECKBOX_BUTTON: {
        width: 9,
        height: 9
    },
    DROPDOWN: {
        width: 80,
        height: 25
    },
    SIGNATURE: {
        width: 60,
        height: 50
    }
};

export const FormViewSec = "form-view-sec";

export const dummyQueryParamBase64Data =
    "Wwp7CnF1ZXJ5TmFtZToidGV4dC1jN2YyZWVkYS1jZGU3LTQ3NDQtYTQ3NS1mYjBlNjJkYzFjODgiLAp2YWx1ZToiVGVzdGluZyBGaXJzdCBuYW1lIgp9LAp7CnF1ZXJ5TmFtZToidGV4dC03MjczMGRiNS03NjM5LTQzZTUtOTcwMi1jNjQ2NTcyZWUyNDkiLAp2YWx1ZToiVGVzdGluZyBMYXN0IG5hbWUiCn0sCnsKcXVlcnlOYW1lOiJudW1iZXItMWI0NDlkNGQtMWY0MC00NDhkLTliN2QtNjAzMzZmM2YxNzI3IiwKdmFsdWU6IjEyMzQ1Njc4OTAiCn0sCnsKcXVlcnlOYW1lOiJkcm9wZG93bi1hNTRhNmM5Yi1hOTI4LTQ0NTQtODIyMS1lYzYyZDRiMzRmOTIiLAp2YWx1ZToiMTIzIgp9LAp7CnF1ZXJ5TmFtZToicXVlcnktc2lnbmF0dXJlIiwKdmFsdWU6InNpZ24xMjMiCn0sCnsKcXVlcnlOYW1lOiJjaGVja2JveC01MjYyZDg3Zi1kOTM3LTRkOTAtODYwZi01YjM2YzZiZGYzMTIiLAp2YWx1ZToidHJ1ZSIKfQpd";

export const ActionsTypesForGuestRoutes = {
    CREATE_ENVELOPE: "CREATE_ENVELOPE",
    UPDATE_ENVELOPE: "UPDATE_ENVELOPE"
};

export const ACTIONS_PARAM_NAMES_ENVELOPES = {
    UPDATE_ENVELOPE: "UPDATE_ENVELOPE",
    CLONE_ENVELOPE: "CLONE_ENVELOPE"
};

export const EnvelopeActionStatus = {
    DRAFT: "DRAFT",
    COMPLETED: "COMPLETED"
};

export const FINISH_LATER_BTN_CHECK = "finishLater";

export const POST_ACTION = {
    removeDuplicateTemplateImages: "removeDuplicateTemplateImages"
};

export const DEFAULT_FONT_SIZE = 8;

export const TEMPLATES_TYPES = {
    html: "HTML",
    pdf: "PDF"
};
