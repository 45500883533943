/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import "./SignerForm.less";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import CustomForm from "Components/CustomForm/CustomForm";
import CustomButton from "Components/CustomButton/CustomButton";
import { saveSignerField } from "redux/signerFieldSlice";

function SignerForm() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinishSigner = (values) => {
        dispatch(saveSignerField(values));
    };

    return (
        <div className="signer-form-sec">
            <CustomForm layout="vertical" name="signer" form={form} onFinish={onFinishSigner} className="signer-form">
                <div className="form-field-sec">
                    <CustomFormItem
                        required={false}
                        label="Your Name"
                        name="name"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: "please add your name"
                            }
                        ]}
                    >
                        <CustomInput placeholder="Full Name" />
                    </CustomFormItem>
                    <CustomFormItem
                        required={false}
                        label="Your Email:"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: "please add your email"
                            },
                            { type: "email" },
                            {
                                max: 320
                            },
                            {
                                min: 3
                            }
                        ]}
                    >
                        <CustomInput placeholder="Email Address" />
                    </CustomFormItem>
                </div>
                <div className="signer-btn">
                    <CustomFormItem>
                        <CustomButton className="ant-btn-primary" htmlType="submit">
                            Begin Signing
                        </CustomButton>
                    </CustomFormItem>
                </div>
            </CustomForm>
        </div>
    );
}

export default memo(SignerForm);

