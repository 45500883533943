import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, notification, Typography } from "antd";
import { UserService } from "services/users.service";
import { authenticatedRoutesConstant, unAuthenticatedRoutesConstant, userInfo } from "util/util.constant";
import "./Login.less";

const { Title, Text } = Typography;

function Login() {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();
    const onFinish = async (values) => {
        setloading(true);
        try {
            const response = await UserService.login(values);
            if (response) {
                const apiKey = response?.user?.apiKey;
                const fullUserName = `${response?.user?.firstName} ${response?.user?.lastName}`;
                localStorage.setItem(userInfo.TOKEN, apiKey);
                localStorage.setItem(userInfo.USERNAME, fullUserName);
                window.location.href = authenticatedRoutesConstant.Templates;
            }
            setloading(false);
        } catch (error) {
            notification.warn({
                message: "Email or password is incorrect!"
            });
            setloading(false);
        }
    };

    return (
        <div className="custom-login-container">
            <Title level={2} className="custom-heading-login">
                Login
            </Title>
            <Form name="basic" onFinish={onFinish} autoComplete="off" className="custom-form-container" form={form}>
                <Text>Email</Text>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please input your email!"
                        }
                    ]}
                >
                    <Input placeholder="Email" autoComplete="off" />
                </Form.Item>

                <Text>Password</Text>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your password!"
                        }
                    ]}
                >
                    <Input.Password placeholder="Password" autoComplete="off" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>

                {/* <Text>
                    or <Link to={unAuthenticatedRoutesConstant.Register}>Signup</Link>
                </Text> */}
            </Form>
        </div>
    );
}

export default Login;
