import { Card, Checkbox } from "antd";
import React, { memo } from "react";
import Draggable from "react-draggable";
import { QUERY_KEYS_NAMES } from "util/util.constant";

function FormViewCheckbox(props) {
    const { singleField, trackPositions, onCheckboxChanged, reviewer, checkboxRef } = props;

    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div className="box-draggable " key={singleField?.id}>
                <Card
                    style={{
                        ...(singleField?.fontSize && { fontSize: singleField?.fontSize })
                    }}
                >
                    <Checkbox
                        ref={checkboxRef}
                        title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                        onChange={(event) => onCheckboxChanged(event, singleField)}
                        value={singleField.placeholder || singleField.id}
                        disabled={reviewer}
                        style={{ width: singleField.width, height: singleField.height }}
                        className={`ant-select-status-${singleField.validationStatus}`}
                    >
                        {/* {singleField.placeholder} */}
                    </Checkbox>
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewCheckbox);
