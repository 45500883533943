import { Navigate, useRoutes } from "react-router-dom";
import AuthService from "./util/auth.service";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "routes/RouteConstants";
import "./assets/vendors/style";
import "./App.less";

function App() {
    let element = useRoutes([
        ...(AuthService.isTokenExist() ? [...AUTHENTICATED_ROUTES] : [...UNAUTHENTICATED_ROUTES]),
        { path: "*", element: <Navigate to="/" replace /> }
    ]);

    return <div className="App">{element}</div>;
}

export default App;

