import { Button, Space } from "antd";
import { UtilService } from "../../util/util.service";

const envelopeColumns = (editEnvelopeHandler, editEnvelopeFormViewHandler, downloadEnvelope, deleteEnvelope) => {
    return [
        {
            title: "Id",
            key: "Id",
            render: (text) => text?.id
        },
        {
            title: "Name",
            key: "name",
            render: (text) => text?.attributes?.name
        },
        {
            title: "Template Name",
            key: "templateName",
            render: (text) => text?.attributes?.template?.attributes?.name
        },

        {
            title: "Created At",
            key: "createdAt",
            render: (text) => UtilService.convertDateToOurFormat(text?.attributes?.createdAt)
        },
        {
            title: "Updated At",
            key: "updatedAt",
            render: (text) => UtilService.convertDateToOurFormat(text?.attributes?.updatedAt)
        },
        {
            title: "Actions",
            key: "action",
            render: (_, record) => (
                <Space>
                    <Button type="primary" onClick={(event) => editEnvelopeHandler(event, record)}>
                        Edit
                    </Button>

                    <Button type="primary" onClick={(event) => editEnvelopeFormViewHandler(event, record)}>
                        Form View
                    </Button>

                    <Button type="primary" onClick={(event) => downloadEnvelope(event, record)}>
                        Download
                    </Button>

                    <Button type="danger" onClick={(event) => deleteEnvelope(event, record)}>
                        Delete
                    </Button>
                </Space>
            )
        }
    ];
};

export default envelopeColumns;

