import { Spin } from "antd";
import React, { memo } from "react";
import logo from "../../assets/images/logo.png";
import "./FullPageLoader.less";

function FullPageLoader() {
    return (
        <div className="custom-full-page-loader">
            <img src={logo} alt="logo" />
            <Spin className="mt-4" size="large" />
        </div>
    );
}

export default memo(FullPageLoader);
