import { message } from "antd";
import { create } from "apisauce";
import AuthService from "./auth.service";
import { unAuthenticatedRoutesConstant, userInfo } from "./util.constant";

const apiInstance = create({
    baseURL: process.env.REACT_APP_API_URL
});

const get = async (url, queryParams, config) => {
    const response = await apiInstance.get(url, queryParams, config);

    return handleReponse(response);
};

const post = async (url, data, config) => {
    const response = await apiInstance.post(url, data, config);
    return handleReponse(response);
};

const put = async (url, data, config) => {
    const response = await apiInstance.put(url, data, config);
    return handleReponse(response);
};

const patch = async (url, data, config) => {
    const response = await apiInstance.patch(url, data, config);
    return handleReponse(response);
};

const deleteRequest = async (url, queryParams, config) => {
    const response = await apiInstance.delete(url, queryParams, config);
    return handleReponse(response);
};

const handleReponse = (response) => {
    if (response?.data?.status === 401) {
        localStorage.removeItem(userInfo.TOKEN);
        localStorage.removeItem(userInfo.USERNAME);
        window.location.href = unAuthenticatedRoutesConstant.Login;
    }

    // if (response?.data?.status === 500) {
    //     message.warn("something went wrong!");
    //     return response;
    // }

    if (!Boolean(response?.ok)) {
        const errorMessages = response?.data?.message || [];

        // console.log(response, "response?.data error");
        if (errorMessages?.length > 0) {
            errorMessages?.forEach((singleErrorMessage) => {
                message.warn(singleErrorMessage);
            });
        } else {
            message.warn("something went wrong!");
        }
    }

    return response;
};

apiInstance.addRequestTransform((request) => {
    if (AuthService.isTokenExist()) {
        request.headers["X-API-KEY"] = AuthService.getTokenExist();
    }
});

const setHeaders = (headers = []) => {
    if (headers.length > 0) {
        headers.forEach((singleHeader) => {
            const { headerKey, headerValue } = singleHeader;
            apiInstance.setHeader(headerKey, headerValue);
        });
    }
};

export const ApiService = {
    get,
    post,
    put,
    patch,
    deleteRequest,
    setHeaders
};

