/* eslint-disable no-eval */
import _ from "lodash";
import { v4 as uuid } from "uuid";

import moment from "moment";
import { FontFamilyTheme, POST_ACTION } from "./util.constant";

const convertDateToOurFormat = (data) => {
    if (!data) {
        return null;
    }
    return moment(data).format("MMMM Do YYYY,  h:mm a");
};

function createDynamicUrl(dynamicUrl, object) {
    for (const key in object) {
        dynamicUrl = dynamicUrl.replace(`:${key}`, object[key]);
    }
    return dynamicUrl;
}

function isAfter(currentDate, latestData) {
    return moment(currentDate).isBefore(latestData);
}

function createDynamicUrlForApiService(dynamicUrl, object) {
    for (const key in object) {
        const objectKey = object[key] ?? "";
        dynamicUrl = dynamicUrl.replace(`{${key}}`, objectKey);
    }
    return dynamicUrl;
}

function downloadFromUrl(url) {
    const aHref = document.createElement("a");
    aHref.href = url;
    aHref.target = "_blank";
    aHref.download = "download";
    aHref.click();
}

function findMainCheckboxElement(groupId, fields) {
    const sameGroupIdsFields = fields.filter((singleFieldFilter) => singleFieldFilter.groupId === groupId);
    const findMainCheckbox = sameGroupIdsFields.find((singleSameGroupIdsField) => singleSameGroupIdsField.mainCheckbox);

    return findMainCheckbox;
}

function findMainCheckboxIndex(groupId, fields) {
    const sameGroupIdsFields = fields.filter((singleFieldFilter) => singleFieldFilter.groupId === groupId);
    const findMainCheckbox = sameGroupIdsFields.findIndex(
        (singleSameGroupIdsField) => singleSameGroupIdsField.mainCheckbox
    );

    return findMainCheckbox;
}

function findMainRadioElement(groupId, fields) {
    const sameGroupIdsFields = fields.filter((singleFieldFilter) => singleFieldFilter.groupId === groupId);
    const findMainRadio = sameGroupIdsFields.find((singleSameGroupIdsField) => singleSameGroupIdsField.mainRadio);

    return findMainRadio;
}

function findMainRadioIndex(groupId, fields) {
    const sameGroupIdsFields = fields.filter((singleFieldFilter) => singleFieldFilter.groupId === groupId);
    const findMainRadio = sameGroupIdsFields.findIndex((singleSameGroupIdsField) => singleSameGroupIdsField.mainRadio);

    return findMainRadio;
}

function deepClone(arrayParam) {
    const cloneArray = _.cloneDeep(arrayParam);
    return cloneArray;
    // return JSON.parse(JSON.stringify(arrayParam));
    // return Object.assign({}, arrayParam);
}

function orderByDesc(collection, iteratees, orders) {
    const orderedValue = _.orderBy(collection, iteratees, orders);
    return orderedValue;
}
const between = (x, min, max) => {
    return x >= min && x <= max;
};
function orderByDescFields(fields = []) {
    // let newOrderFields = orderByDesc([...fields],['x','desc']);
    // let orderedFields = orderByDesc([...fields], ['y' ],['asc']);

    let orderedFields = [...fields].sort((a, b) => {
        const ayCal = a.y + a.height / 2;
        const byCal = b.y + b.height;

        if (between(ayCal, b.y, byCal)) {
            return a.x - b.x;
        }

        return a.y - b.y;
    });
    return orderedFields;
}

function urlParameters() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
}

function generateUniqueId() {
    const uniqueId = uuid();
    return uniqueId;
}

function groupBy(value = [], fieldName = "groupId") {
    const groupValues = _.groupBy(value, fieldName);
    return groupValues;
}

function base64toArray(data) {
    if (data) {
        const decodedStringAtoB = atob(data);
        const convertedArray = eval(decodedStringAtoB);
        return convertedArray;
    } else {
        return data;
    }
}

function filterUniqueArray(duplicateArray = []) {
    const arrStr = duplicateArray.map((item) => JSON.stringify(item));
    return [...new Set(arrStr)].map((item) => JSON.parse(item));
}

function deepfilterUniqueArray(duplicateArray = [], key = "") {
    const arrayUniqueByKey = [...new Map(duplicateArray.map((item) => [item[key], item])).values()];

    return arrayUniqueByKey;
}

function timeFormatFormView(value = "") {
    return moment(value).format("hh-mm aa");
}

function dateFormatFormView(value = "") {
    return moment(value).format("MM/DD/YYYY");
}

function updateTemplateImagesUrlsOnServiceWorker(templates) {
    const imagesUrls = [];
    templates?.data?.forEach((singleTemplate) => {
        const templateImages = singleTemplate?.attributes?.templateImages || [];

        templateImages?.forEach((singleTemplateImage) => {
            const imageUrl = singleTemplateImage.images.path;
            if (imageUrl) {
                imagesUrls.push(imageUrl);
            }
        });
    });

    if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistration().then((registeredServiceWorker) => {
            //post message to only active service workers
            if (
                registeredServiceWorker?.active &&
                registeredServiceWorker?.active?.scriptURL.indexOf("/service-worker.js") !== -1
            ) {
                registeredServiceWorker?.active.postMessage({
                    action: POST_ACTION.removeDuplicateTemplateImages,
                    imagesUrls
                });
            }
        });
    }
}

const generateTextWithCanvas = async (canvas, signName) => {
    const value = signName;
    const convasDomElement = canvas.current;

    const autoGraphyFont = new FontFace(FontFamilyTheme, `url('/vendor/fonts/autography-dolnw-webfont.woff')`);

    const response = await autoGraphyFont.load().then(function (font) {
        // with canvas, if this is ommited won't work
        document.fonts.add(font);
        const context = convasDomElement.getContext("2d");
        context.font = `bolder 35px ${FontFamilyTheme}`;
        const textArray = `${value}`.split("");
        let twidth = 0;
        for (let i = 0; i < textArray.length; i++) {
            twidth += context.measureText(textArray[i]).width;
        }
        convasDomElement.width = twidth + 6;
        context.globalCompositeOperation = "xor";
        context.clearRect(0, 0, convasDomElement.width, convasDomElement.height);
        context.font = `bolder 35px ${FontFamilyTheme}`;
        context.fillText(
            value,
            convasDomElement.width / 2 - twidth / 2,
            convasDomElement.height / 2,
            convasDomElement.width
        );
        context.save();
        const pngUrl = convasDomElement.toDataURL();
        return pngUrl;
    });

    return response;
};

function timePickerFormatEnvelope(value = "00:00:00") {
    return moment("00:00:00", "HH:mm:ss");
}

const debounce = (func, delay) => {
    let debounceHandler;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceHandler);
        debounceHandler = setTimeout(() => func.apply(context, args), delay);
    };
};

const throttle = (func, limit) => {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
};

export const UtilService = {
    generateTextWithCanvas,
    updateTemplateImagesUrlsOnServiceWorker,
    timeFormatFormView,
    dateFormatFormView,
    filterUniqueArray,
    deepfilterUniqueArray,
    base64toArray,
    deepClone,
    findMainRadioElement,
    findMainRadioIndex,
    findMainCheckboxElement,
    findMainCheckboxIndex,
    convertDateToOurFormat,
    createDynamicUrl,
    downloadFromUrl,
    createDynamicUrlForApiService,
    isAfter,
    urlParameters,
    generateUniqueId,
    groupBy,
    timePickerFormatEnvelope,
    orderByDesc,
    orderByDescFields,
    debounce,
    throttle
};

