import { InputNumber } from "antd";
import React, { memo } from "react";
import "./CustomInputNumber.less";

const CustomInputNumber = (props) => {
    return (
        <span className="kl-custom-input-number-container">
            <InputNumber {...props} />
        </span>
    );
};

export default memo(CustomInputNumber);
