import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { useDrag } from "react-dnd";
import { saveField } from "redux/fieldSlice";
// import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
// import { defaultFieldObject, fieldDraggleType } from "./constant";
import { UtilService } from "util/util.service";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject } from "Components/TemplateBuilderWrapper/FormFields/constant";

function DraggableSignatureBox({ currentPdfObject, isSignatureDeleteEnable = false, setFields }) {
    const dispatch = useDispatch();

    const generateCheckboxFields = () => {
        const unique_id = UtilService.generateUniqueId();
        const groupId = UtilService.generateUniqueId();

        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.signature,
            placeholder: FIELD_TYPES.signature,
            pageNumber: 0,
            width: 100,
            height: 60,
            groupId: `${FIELD_TYPES.signature} ${groupId}`,
            isFormViewSignature: isSignatureDeleteEnable,
            queryName: `${FIELD_TYPES.signature}-${unique_id}`
        };
    };

    const [, dragRef] = useDrag({
        type: "field",
        item: () => {
            return generateCheckboxFields();
        },
        // item: () => generateCheckboxFields,
        collect: (monitor) => {
            console.log("monitor: ", monitor);
            return {
                isDragging: monitor.isDragging()
            };
        }
    });
    console.log("dragRef: ", dragRef);

    const onFieldFinish = () => {
        console.log("HELLO FINISH");
        const clonedFieldData = generateCheckboxFields();
        setFields((prev) => [...prev, clonedFieldData]);
        // dispatch(saveField(clonedFieldData));
    };

    return (
        <Button
            ref={dragRef}
            className="common-button"
            onClick={onFieldFinish}
            icon={<i className="paso-signature"></i>}
        >
            Signature
        </Button>
    );
}

export default memo(DraggableSignatureBox);
