import React, { memo } from "react";
import { Col, Row, Table } from "antd";
import "./Gridview.less";
// import { pageSize } from "util/constants/constant";

const GridView = (props) => {
    const {
        data = null,
        className = "",
        // pagination = {
        //   currentPage: 1,
        //   pageSize: pageSize,
        //   total: 10,
        // },
        // listingCallback = () => {},
        filtersRender = false
        // isSearchAvailable = false,
        // mainHeading = false,
        // mainHeadingButton = "",
    } = props;
    let uniqueId = 0;

    const handleOnChange = async (pagination, filters, sorter = {}) => {
        // if (Object.keys(sorter).length !== 0) {
        // let payload = {};
        // if (Array.isArray(sorter)) {
        //     payload = sorter.map((singleSorter) => {
        //         return {
        //             column: singleSorter.columnKey,
        //             order: singleSorter.order
        //         };
        //     });
        // } else {
        //     payload = {
        //         column: sorter?.columnKey,
        //         order: sorter?.order
        //     };
        // }
        // await listingCallback(payload);
        // }
    };

    return (
        <>
            {/* FILTERS & SEARCH FUNCTIONALITIES */}
            {filtersRender && (
                <div className="topHeader">
                    <Row>
                        <Col span={16}>{filtersRender && filtersRender()}</Col>
                    </Row>
                </div>
            )}

            {/* ONLY TABLE COMPONENT */}
            <div className="custom-table-grid-view-container">
                <Table
                    {...props}
                    className={`${className} custom-table-container cardWithStyle`}
                    dataSource={data}
                    onChange={handleOnChange}
                    rowKey={(record) => {
                        if (!record?.id) {
                            return ++uniqueId;
                        }
                        return record?.id;
                    }}
                    // pagination={{
                    //   currentPage: pagination.currentPage,
                    //   pageSize: pagination.pageSize,
                    //   total: pagination.total,
                    //   onChange: (page, pageSize) => {
                    //     listingCallback({
                    //       page: page,
                    //       take: pageSize,
                    //     });
                    //   },
                    // }}
                />
            </div>
        </>
    );
};

export default memo(GridView);
