import CustomOptimizeLazyImageLoad from "Components/CustomOptimizeLazyImageLoad/CustomOptimizeLazyImageLoad";
import React, { memo, useState, useLayoutEffect } from "react";

function useWindowSize() {
    const [size, setSize] = useState([0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.screen.width]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

function BuilderPageContainer({
    currentPageNo,
    handleMouseEvents = () => {},
    imageUrl,
    children,
    isSignatureDeleteEnable = false
}) {
    return (
        <div style={{ textAlign: "center" }}>
            <h4>{currentPageNo}</h4>

            <div
                onMouseMove={(event) => handleMouseEvents(event, currentPageNo)}
                onMouseOver={(event) => handleMouseEvents(event, currentPageNo)}
                onMouseEnter={(event) => handleMouseEvents(event, currentPageNo)}
                onMouseUp={(event) => handleMouseEvents(event, currentPageNo)}
                className="main-template-middle-container"
            >
                <CustomOptimizeLazyImageLoad src={imageUrl} className="lazy-load-image" style={{ width: "100%" }} />
                {isSignatureDeleteEnable ? children : <div id="template-builder-middle-side">{children}</div>}
            </div>
        </div>
    );
}

export default memo(BuilderPageContainer);

