import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import CustomButton from "Components/CustomButton/CustomButton";
import CustomCheckbox from "Components/CustomCheckbox/CustomCheckbox";
import {
    addRadioButtonOption,
    deleteRadioButtonOption,
    radioOptionOnChangeChecked,
    updateField
} from "../../redux/fieldSlice";

function RepeaterFieldForRadioButton({ singleField }) {
    const fields = useSelector((state) => state.fields.fields);
    const dispatch = useDispatch();

    const addOptionHandler = (event) => {
        event.preventDefault();
        dispatch(addRadioButtonOption(singleField));
    };

    const removeField = (event, singleRepeaterField) => {
        event.preventDefault();
        dispatch(deleteRadioButtonOption(singleRepeaterField));
    };

    const onChangeRadioButtonHandler = (event, singleRepeaterField) => {
        const {
            target: { checked }
        } = event;

        dispatch(radioOptionOnChangeChecked({ checked, singleRepeaterField }));
        // dispatch(
        //     updateField({
        //         id: singleRepeaterField.id,
        //         value: checked
        //     })
        // );
    };

    const onChangeValueHandler = (event, singleRepeaterField) => {
        event.preventDefault();
        const {
            target: { value }
        } = event;

        dispatch(
            updateField({
                id: singleRepeaterField.id,
                placeholder: value
            })
        );
    };

    const getMainRadioButtonElement = () => {
        const sameGroupIdsFields = fields.filter(
            (singleFieldFilter) => singleFieldFilter.groupId === singleField.groupId
        );
        const findMainRadioButton = sameGroupIdsFields.find(
            (singleSameGroupIdsField) => singleSameGroupIdsField.mainRadio
        );

        const mainRadioButtonchildrens = findMainRadioButton?.childrens || [];

        if (mainRadioButtonchildrens.length > 0) {
            const fullObjectsOfChildrens = [];
            mainRadioButtonchildrens.forEach((singleRadioButtonChildren) => {
                const findFullChildrenObject = fields.find(
                    (singleFieldRedux) => singleFieldRedux.id === singleRadioButtonChildren
                );

                if (findFullChildrenObject) {
                    fullObjectsOfChildrens.push(findFullChildrenObject);
                }
            });

            return fullObjectsOfChildrens;
        }

        return [];
    };

    const mainRadioButtonChildrens = getMainRadioButtonElement();
    return (
        <>
            {mainRadioButtonChildrens.length > 0 &&
                mainRadioButtonChildrens.map((singleRepeaterField, index) => {
                    return (
                        <div className="repeter-row" align="baseline" key={index}>
                            <div className="field-sec">
                                <Row type="flex" justify="space-between">
                                    <Col style={{ marginRight: "20px" }}>
                                        <CustomFormItem>
                                            <CustomCheckbox
                                                onChange={(event) =>
                                                    onChangeRadioButtonHandler(event, singleRepeaterField)
                                                }
                                                checked={singleRepeaterField.value || false}
                                            />
                                        </CustomFormItem>
                                    </Col>

                                    <Col>
                                        <CustomFormItem>
                                            <CustomInput
                                                placeholder="Value"
                                                onChange={(event) => onChangeValueHandler(event, singleRepeaterField)}
                                                value={singleRepeaterField.placeholder || ""}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </div>
                            <MinusCircleOutlined
                                className="repeter-icon"
                                onClick={(e) => removeField(e, singleRepeaterField)}
                            />
                        </div>
                    );
                })}

            <CustomButton
                type="dashed"
                className="add-field-btn"
                onClick={addOptionHandler}
                block
                icon={<PlusOutlined />}
            >
                Add Radio Btn
            </CustomButton>
        </>
    );
}

export default memo(RepeaterFieldForRadioButton);
