import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";
import { UtilService } from "util/util.service";

const SettingUrl = {
    getSetting: "/connects/user/single",
    updateSetting: "/connects/{id}",
    addSetting: "/connects"
};

const getSetting = async () => {
    const response = await ApiService.get(SettingUrl.getSetting);
    return ApiRequestUtil.handleApiRequest(response);
};
const addSetting = async (payload = {}) => {
    const response = await ApiService.post(SettingUrl.addSetting, payload);
    return ApiRequestUtil.handleApiRequest(response);
};
const updateSetting = async (id, payload) => {
    const url = UtilService.createDynamicUrlForApiService(SettingUrl.updateSetting, {
        id
    });
    const response = await ApiService.patch(url, payload);
    return ApiRequestUtil.handleApiRequest(response);
};

export const SettingService = {
    getSetting,
    addSetting,
    updateSetting
};
