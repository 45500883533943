import React, { memo } from "react";

function FormViewSignatureWithoutImage({ width, height, singleField = {}, onClick = () => {} }) {
    return (
        <div
            className={`create-signature-container sign-${singleField?.validationStatus}`}
            style={{ width, height }}
            onClick={onClick}
        >
            Signature
        </div>
    );
}

export default memo(FormViewSignatureWithoutImage);
