import React, { memo } from "react";
import { Card, DatePicker } from "antd";
import moment from "moment";
import Draggable from "react-draggable";
import { FIELD_TYPES, DEFAULT_FONT_SIZE } from "util/util.constant";
import { UtilService } from "util/util.service";

function FormViewDatePicker(props) {
    const { singleField, trackPositions, onDatePickerChangeHandler, type, reviewer, disabled = false } = props;
    const { width, height, fontSize = DEFAULT_FONT_SIZE } = singleField;
    const textStyle = { width, height, fontSize };
    const zoomreset = () => {
        var viewport = document.querySelector("meta[name='viewport']");
        viewport.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
    };
    const setViewport = () => {
        var viewport = document.querySelector("meta[name='viewport']");
        viewport.content = "width=device-width, initial-scale=1,";
    };

    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div className="box-draggable " style={{ width, height }}>
                <Card style={{ width, height, ...(singleField?.fontSize && { fontSize }) }}>
                    {/* {reviewer ? (
                        <p className="m-0 line-height">
                            <b>
                                {type === FIELD_TYPES.time
                                    ? singleField.value && UtilService.timeFormatFormView(singleField.value)
                                    : singleField.value && UtilService.dateFormatFormView(singleField.value)}
                            </b>
                        </p>
                    ) : ( */}
                    <DatePicker
                        // picker={type}
                        // showTime={false}
                        inputReadOnly
                        // onFocus={zoomreset}
                        // onBlur={setViewport}
                        format="MM/DD/YYYY"
                        onChange={(date, dateString) => onDatePickerChangeHandler(date, dateString, singleField)}
                        status={singleField.validationStatus}
                        value={
                            singleField?.value
                                ? type === FIELD_TYPES.time
                                    ? moment(singleField.value, "LT") || ""
                                    : moment(singleField.value, "MM/DD/YYYY") || ""
                                : ""
                        }
                        style={reviewer ? { ...textStyle, pointerEvents: "none" } : textStyle}
                        placeholder=""
                        disabled={disabled}
                    />
                    {/* )} */}
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewDatePicker);

