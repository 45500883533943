/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Row, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "Components/Breadcrumbs/Breadcrumbs";
import GridView from "Components/GridView/GridView";
import CustomSearch from "Components/CustomSearch/CustomSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "Components/CustomButton/CustomButton";
import { authenticatedRoutesConstant } from "util/util.constant";
import AuthService from "../../util/auth.service";
import { UtilService } from "util/util.service";
import { useQueryDeleteTemplate, useQueryTemplates } from "queries/template.queries";
import templateColumns from "./columns";
import "./Template.less";
import { PAGE_SIZE } from "queries/queries.constant";

const { confirm } = Modal;

function Template() {
    const [searchValue, setSearchValue] = useState({});
    const [page, setPage] = useState(1);

    const { data: templates, isLoading: templateLoading, refetch } = useQueryTemplates(searchValue, page);
    const { mutateAsync: deleteTemplateByQuery, isLoading: deleteTemplateLoading } = useQueryDeleteTemplate();

    const onFinishSearch = (value) => {
        const queryParams = { search: value };
        setSearchValue(queryParams);
    };

    useEffect(() => {
        UtilService.updateTemplateImagesUrlsOnServiceWorker(templates);
    }, [templates]);

    useEffect(() => {
        refetch();
    }, [searchValue]);

    const navigate = useNavigate();

    const deleteTemplate = async (event, record) => {
        event.preventDefault();
        confirm({
            title: "Do you want to delete this template?",
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                await deleteTemplateByQuery(record?.id);
                refetch();
            }
        });
    };

    const createTemplateHandler = (event) => {
        event.preventDefault();
        navigate(authenticatedRoutesConstant.TemplateCreate);
    };

    const editTemplateHandler = (event, record) => {
        event.preventDefault();
        if (record?.id) {
            const url = UtilService.createDynamicUrl(authenticatedRoutesConstant.TemplateEdit, {
                template_id: record?.id
            });
            navigate(url);
        }
    };

    const builderViewHandler = (event, record) => {
        event.preventDefault();
        if (record?.id) {
            const url = UtilService.createDynamicUrl(authenticatedRoutesConstant.TemplateBuilderView, {
                template_id: record?.id
            });
            window.open(url, "_blank");
        }
    };

    const generateViewHandler = (event, record) => {
        event.preventDefault();
        const {
            attributes: { url }
        } = record;

        let cloneUrl = `/template/${url.split("/template/")[1]}`;
        console.log("cloneUrl: ", cloneUrl);

        const apiKey = AuthService.getTokenExist();

        if (apiKey) {
            cloneUrl += `&client_api_key=${apiKey}`;
        }

        window.open(cloneUrl, "_blank");
    };

    const generateHTMLFormView = (event, record) => {
        event.preventDefault();
        const {
            attributes: { url }
        } = record;

        let cloneUrl = `/template/${url.split("/template/")[1]}`;
        console.log("cloneUrl: ", cloneUrl);

        const apiKey = AuthService.getTokenExist();

        if (apiKey) {
            cloneUrl += `&client_api_key=${apiKey}`;
        }

        window.open(cloneUrl, "_blank");
    };

    const tempColumns = templateColumns(
        editTemplateHandler,
        builderViewHandler,
        deleteTemplate,
        generateViewHandler,
        generateHTMLFormView
    );

    const gridTitle = () => {
        return (
            <>
                <Row>
                    <Col sm={24} md={12} lg={12} className="title-sec">
                        <h5>Templates</h5>
                    </Col>
                    <Col sm={24} md={12} lg={12}>
                        <div className="table-top-btn-sec">
                            <CustomSearch
                                placeholder="Search"
                                allowClear
                                onSearch={onFinishSearch}
                                prefix={<FontAwesomeIcon icon={faSearch} />}
                            />
                        </div>
                    </Col>
                </Row>
            </>
        );
    };

    const breadCrumbAddBtn = (
        <div className="breadcrumb-btn-sec">
            <CustomButton
                htmlType="primary"
                onClick={createTemplateHandler}
                className="ant-btn-primary"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
                Create Template
            </CustomButton>
        </div>
    );

    return (
        <div>
            <Space direction="vertical" size="middle" className="custom-flex">
                <Breadcrumbs Title="Templates" Icon="paso-Home" AddBtn={breadCrumbAddBtn} />
                <GridView
                    bordered
                    rowSelection
                    title={gridTitle}
                    data={templates?.data}
                    columns={tempColumns}
                    loading={templateLoading || deleteTemplateLoading}
                    pagination={{
                        currentPage: templates?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: templates?.totalCount,
                        onChange: setPage
                    }}
                />
            </Space>
        </div>
    );
}

export default Template;
