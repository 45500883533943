import React, { useState } from "react";
import { Button, Form, Input, Radio, Select, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CustomUpload from "Components/CustomUpload/CustomUpload";
import { useQueryAddTemplate, useQueryGetTemplateById, useQueryUpdateTemplate } from "queries/template.queries";
import { useQueryAddMedia } from "queries/medias.queries";
import { TEMPLATES_TYPES, authenticatedRoutesConstant } from "util/util.constant";
import "./TemplateCreateEdit.less";
import { HTML_FORMS } from "./HTMlForm";

const createTemplateOptions = {
    FILE_UPLOAD: {
        label: "File Upload",
        value: " FILE_UPLOAD"
    },
    HTML_FORMS: {
        label: "HTML Forms",
        value: "HTML_FORMS"
    }
};

const staticFormTypes = {
    CNA_FORM: {
        label: "CNA Form",
        value: "CNA"
    },
    IP_FORM: {
        label: "IP Form",
        value: "IP"
    }
};

function TemplateCreateEdit() {
    const params = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [preLoading, setPreLoader] = useState(false);
    const [fileName, setFilename] = useState("Drag & Drop to Upload File");
    const [buttonName, setButtonName] = useState("Choose File");
    const [templateTypes, setTemplateTypes] = useState(createTemplateOptions.FILE_UPLOAD.value);
    const { template_id } = params;

    const { mutateAsync: mutateAddTemplate, isLoading: addTemplateLoading } = useQueryAddTemplate();
    const { mutateAsync: mutateAddMedia, isLoading: addMediaLoading } = useQueryAddMedia();
    const { mutateAsync: mutateUpdateTemplate, isLoading: updateTemplateLoading } = useQueryUpdateTemplate();
    const { isFetching: getTemplateByIdLoading } = useQueryGetTemplateById(template_id, (data) => {
        if (getTemplateByIdLoading) {
            form.setFieldsValue({
                name: data?.attributes?.name
            });
        }
    });

    const addTemplateCall = (values) => {
        const formData = new FormData();
        formData.append("file", file);

        mutateAddMedia(formData, {
            onSuccess: async (data) => {
                const { id: mediaId } = data;
                const payload = { ...values, media: mediaId, type: TEMPLATES_TYPES.pdf };
                delete payload.file;
                await mutateAddTemplate(payload);
                navigate(authenticatedRoutesConstant.Templates);
            }
        });
    };

    const updateTemplateCall = async (values) => {
        const formData = new FormData();
        formData.append("file", file);

        await mutateAddMedia(formData, {
            onSuccess: async (data) => {
                const { id: mediaId } = data;
                const payload = { ...values, media: mediaId };
                delete payload.file;
                await mutateUpdateTemplate({ templateIdParam: template_id, payload: values });
                form.resetFields();
                navigate(authenticatedRoutesConstant.Templates);
            }
        });
    };

    const onFinish = async (values) => {
        if (template_id) {
            updateTemplateCall(values);
        } else {
            addTemplateCall(values);
        }
    };

    const customRequest = (info) => {
        setPreLoader(true);

        if (info?.file?.type === "application/pdf") {
            setFile(info.file);
            setFilename(info?.file?.name);
            setButtonName("Change File");
            message.success(`${info?.file?.name} File Uploaded`);
        } else {
            if (info?._reactName !== "onDrop") {
                message.error(`${info?.file?.name} is not 'PDF'`);
            }
        }

        setPreLoader(false);
        return false;
    };

    const uploadProps = {
        name: "file",
        multiple: false,
        maxCount: 1,
        fileList: [],
        showUploadList: false,
        accept: "application/pdf",
        customRequest: customRequest,
        onDrop: customRequest
    };

    const handleTemplateTypesChange = (value) => {
        setTemplateTypes(value);
    };

    const onFinishHTMLForms = async (values) => {
        console.log("values: ", values);
        // if (values["html-form-type"] === staticFormTypes.IP_FORM.value) {
        const parser = new DOMParser();
        const htmlDocument = parser.parseFromString(HTML_FORMS[values["html-form-type"]], "text/html");

        // Access the body content
        const pages = htmlDocument.getElementsByClassName("page");

        let tabs = [];
        console.log("payload: ", tabs);
        for (let i = 0; i < pages.length; i++) {
            tabs.push({
                type: "html",
                pageNumber: i + 1,
                value: pages[i].outerHTML
            });
        }
        const payload = { name: values.name, tabs, type: TEMPLATES_TYPES.html, html_type: values["html-form-type"] };
        console.log("body: ", payload);
        await mutateAddTemplate(payload);
        navigate(authenticatedRoutesConstant.Templates);
        // }
    };

    return (
        <div className="add-template-container">
            <h2>{template_id ? "Update" : "Create"} Template</h2>
            {!template_id && (
                <div className="template-types-container">
                    <div>Templates Types:</div>
                    <Select
                        options={Object.values(createTemplateOptions)}
                        value={templateTypes}
                        onSelect={handleTemplateTypesChange}
                    />
                </div>
            )}
            {templateTypes === createTemplateOptions.FILE_UPLOAD.value ? (
                <Form name="basic" onFinish={onFinish} autoComplete="off" form={form}>
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your template name!"
                            }
                        ]}
                    >
                        <Input placeholder="Template Name" />
                    </Form.Item>

                    <Form.Item name="file">
                        <CustomUpload {...uploadProps}>
                            <p className="ant-upload-drag-icon"></p>
                            <p className="ant-upload-text">{fileName}</p>
                            <p className="gx-mt-2">
                                <strong>OR</strong>
                            </p>
                            <Button className="gx-my-3 gx-text-black">{buttonName}</Button>
                        </CustomUpload>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={
                                preLoading ||
                                addTemplateLoading ||
                                addMediaLoading ||
                                updateTemplateLoading ||
                                getTemplateByIdLoading
                            }
                        >
                            {template_id ? "Update" : "Create"} Template
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                !template_id && (
                    <div>
                        <Form name="html-forms" onFinish={onFinishHTMLForms} autoComplete="off" form={form}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your template name!"
                                    }
                                ]}
                            >
                                <Input placeholder="Template Name" />
                            </Form.Item>
                            <Form.Item name="html-form-type" initialValue={staticFormTypes.CNA_FORM.value}>
                                <Radio.Group
                                    options={Object.values(staticFormTypes)}
                                    // onChange={handleStaticFormTypes}
                                    // value={staticFormType}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={addTemplateLoading}>
                                    Create Template
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                )
            )}
        </div>
    );
}

export default TemplateCreateEdit;
