import { notification } from "antd";

const { useQuery, useMutation } = require("react-query");
const { SettingService } = require("services/setting.service");
const { QueryNames, globalReactQueryGetOption } = require("./queries.constant");

function useQuerySetting() {
    const queryName = QueryNames.setting;
    const queryFn = async () => await SettingService.getSetting();
    return useQuery(queryName, queryFn, globalReactQueryGetOption);
}

function useQueryAddSetting() {
    const queryFn = async (payload) => await SettingService.addSetting(payload);
    return useMutation(queryFn, {
        onSuccess: () => {
            notification.success({ message: "Setting successfully added" });
        }
        // onError: () => {
        //     notification.error({ message: "An error occurred while adding the envelope" });
        // }
    });
}

function useQueryUpdateSetting() {
    const queryFn = async (payload) => {
        const { userId, ...rest } = payload;
        await SettingService.updateSetting(userId, rest);
    };
    return useMutation(queryFn, {
        onSuccess: () => {
            notification.success({ message: "Setting successfully upadated" });
        }
        // onError: () => {
        //     notification.error({ message: "An error occurred while adding the envelope" });
        // }
    });
}

export { useQuerySetting, useQueryAddSetting, useQueryUpdateSetting };
