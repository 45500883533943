import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { PAGE_SIZE } from "./queries.constant";

function usePrefetchPaginationWithReactQuery(
    queryReturnedValues,
    page,
    queryKey,
    queryFn = () => {},
    isPagination = true
) {
    const { data, isPreviousData } = queryReturnedValues;
    const queryClient = useQueryClient();

    useEffect(() => {
        const hasMoreData = page * PAGE_SIZE < data?.totalCount;

        if (!isPreviousData && hasMoreData && isPagination) {
            queryClient.prefetchQuery({
                queryKey,
                queryFn
            });
        }
    }, [data, isPreviousData, page, queryClient, queryFn, queryKey, isPagination]);

    return queryReturnedValues;
}

export { usePrefetchPaginationWithReactQuery };

