import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import { useQueryTemplates } from "queries/template.queries";
import { useQueryAddEnvelope, useQueryGetEnvelopeById, useQueryUpdateEnvelope } from "queries/envelope.queries";
import { authenticatedRoutesConstant } from "util/util.constant";

function EnvelopeCreateEdit() {
    const { envelope_id: envelopeIdParam } = useParams();
    const [form] = Form.useForm();

    const { data: templates, isLoading: templateLoading } = useQueryTemplates({}, 1, false);

    const { isLoading: getEnvelopeByIdLoading } = useQueryGetEnvelopeById(
        envelopeIdParam,
        (data) => {
            if (getEnvelopeByIdLoading) {
                form.setFieldsValue({
                    name: data?.attributes?.name,
                    template_id: data?.attributes?.template?.id
                });
            }
        },
        Boolean(!envelopeIdParam)
    );

    const { mutateAsync: addEnvelopeMutateQuery, isLoading: addEnvelopeLoading } = useQueryAddEnvelope();
    const { mutateAsync: updateEnvelopeMutateQuery, isLoading: updateEnvelopeLoading } = useQueryUpdateEnvelope();

    const navigate = useNavigate();
    const onFinish = async (values) => {
        const payload = { ...values };

        if (values.template_id) {
            const templateTemp = [...templates?.data];
            const findTemplate = templateTemp.find((singleTemplate) => singleTemplate.id === values?.template_id);
            payload.tabs = findTemplate?.attributes?.templateDetail?.tabs || [];
        }

        if (envelopeIdParam) {
            updateEnvelopeMutateQuery(
                { payload, envelopeIdParam },
                {
                    onSuccess: () => {
                        navigate(authenticatedRoutesConstant.Envelopes);
                    }
                }
            );
        } else {
            addEnvelopeMutateQuery(payload);
        }
    };

    return (
        <div className="add-template-container">
            <h2>{envelopeIdParam ? "Update" : "Create"} Envelope</h2>

            <Form name="basic" onFinish={onFinish} autoComplete="off" form={form}>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input your template name!"
                        }
                    ]}
                >
                    <Input placeholder="Envelope Name" />
                </Form.Item>

                <Form.Item
                    required={false}
                    name="template_id"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            message: "please select your template!"
                        }
                    ]}
                >
                    <Select showSearch placeholder="--Select Template--" optionFilterProp="children">
                        {!!templates?.data &&
                            templates?.data?.map((singleTemplate, index) => (
                                <Select.Option value={singleTemplate?.id} key={index}>
                                    {singleTemplate?.attributes?.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={
                            getEnvelopeByIdLoading || templateLoading || addEnvelopeLoading || updateEnvelopeLoading
                        }
                    >
                        {envelopeIdParam ? "Update" : "Create"} Envelope
                        {templateLoading}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default EnvelopeCreateEdit;

