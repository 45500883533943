import React, { memo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./CustomOptimizeLazyImageLoad.less";

function CustomOptimizeLazyImageLoad(props) {
    const { src } = props;
    const [isLoaded, setLoaded] = useState(false);
    const [isLoadStarted, setLoadStarted] = useState(false);

    const handleOnLoad = () => {
        setLoaded(true);
    };

    const handleBeforeLoadStarted = () => {
        setLoadStarted(true);
    };

    return (
        <>
            <LazyLoadImage
                onLoad={handleOnLoad}
                beforeLoad={handleBeforeLoadStarted}
                src={src}
                effect="blur"
                {...props}
            />
            {!isLoaded && isLoadStarted && <div className="custom-blank-image-for-optimize ">&nbsp;</div>}
        </>
    );
}

export default memo(CustomOptimizeLazyImageLoad);
