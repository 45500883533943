import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TemplateService } from "services/templates.service";
import { usePrefetchPaginationWithReactQuery } from "./general.queries";
import {
    globalReactQueryGetOption,
    PAGE_SIZE,
    PAGINATION_STALE_TIME,
    QueryModuleNames,
    QueryNames
} from "./queries.constant";

function useQueryTemplates(queryParams, page = 1, isPagination = true) {
    const queryName = [QueryNames.templates, page];
    const queryFnParams = useMemo(() => {
        return {
            ...queryParams,
            ...(!isPagination
                ? { take: 1000 }
                : {
                      page: page,
                      take: PAGE_SIZE
                  })
        };
    }, [queryParams, page, isPagination]);

    const queryFn = async () => await TemplateService.getTemplates(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        staleTime: !isPagination ? 0 : PAGINATION_STALE_TIME,
        keepPreviousData: true
    });

    usePrefetchPaginationWithReactQuery(
        queryReturnedValues,
        page,
        QueryNames.templates,
        () =>
            TemplateService.getTemplates({
                ...queryFnParams,
                page: page + 1
            }),
        isPagination
    );

    return queryReturnedValues;
}

function useQueryGetTemplateById(templateId, onSuccess = () => {}, disabled = false) {
    const queryClient = useQueryClient();
    const queryName = [QueryModuleNames.templates, templateId];
    const queryFn = async () => await TemplateService.getTemplatesById(templateId);
    return useQuery(queryName, queryFn, {
        onSuccess: (data) => {
            queryClient.setQueryData(queryName, data);
        },
        onSettled: (data) => {
            onSuccess(data);
        },
        enabled: Boolean(templateId) && Boolean(!disabled),
        staleTime: 60000,
        ...globalReactQueryGetOption
    });
}

function useQueryDeleteTemplate() {
    const queryClient = useQueryClient();
    const queryFn = async (templateId) => await TemplateService.deleteTemplateById(templateId);
    return useMutation(queryFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryNames.templates);
        }
        // onError: () => {
        //     notification.error({ message: "An error occurred while deleting the envelope" });
        // }
    });
}

function useQueryAddTemplate() {
    const queryFn = async (payload) => await TemplateService.createTemplate(payload);
    return useMutation(queryFn, {
        // onError: () => {
        //     notification.error({ message: "An error occurred while adding the envelope" });
        // }
    });
}

function useQueryUpdateTemplate() {
    const queryFn = async ({ payload, templateIdParam }) =>
        await TemplateService.updateEnvelope(templateIdParam, payload);
    return useMutation(queryFn, {
        // onError: () => {
        //     notification.error({ message: "An error occurred while updating the envelope" });
        // }
    });
}

function useQueryGetTemplateByAuth(query, onSuccess, forDisabled = false) {
    const queryName = [QueryModuleNames.templateAuth];
    const queryFn = async () => await TemplateService.getTemplatesAuth(query);
    return useQuery(queryName, queryFn, {
        onSuccess: (data) => {
            onSuccess(data);
        },
        enabled: Boolean(query?.authorization) && Boolean(!forDisabled),
        staleTime: 0,
        ...globalReactQueryGetOption
    });
}

export {
    useQueryTemplates,
    useQueryGetTemplateById,
    useQueryDeleteTemplate,
    useQueryAddTemplate,
    useQueryUpdateTemplate,
    useQueryGetTemplateByAuth
};

