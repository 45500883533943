import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    signerFields: null
};

export const signerFieldSlice = createSlice({
    name: "signerFields",
    initialState,
    reducers: {
        saveSignerField: (state, { payload }) => {
            state.signerFields = { payload };
        }
    }
});

// Action creators are generated for each case reducer function
export const { saveSignerField } = signerFieldSlice.actions;

export default signerFieldSlice.reducer;
