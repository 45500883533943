import { configureStore } from "@reduxjs/toolkit";
import fieldReducer from "./fieldSlice";
import signerFieldsReducer from "./signerFieldSlice";
import tabReducer from "./tabSlice";

export default configureStore({
    reducer: {
        fields: fieldReducer,
        signerFields: signerFieldsReducer,
        tabs: tabReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
