import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";
import { UtilService } from "util/util.service";

const TemplateUrl = {
    getTemplate: "/template/get-all-templates",
    createTemplate: "/template/create-template",
    getTemplatesById: "/template/get-template/{id}",
    deleteTemplateById: "/template/delete-template/{id}",
    createBuilderData: "/template/create-template-tabs",
    getTemplatesAuth: "/template/authorization"
};

const getTemplates = async (query = {}) => {
    const response = await ApiService.get(TemplateUrl.getTemplate, {
        ...query,
        relations: ["template_images", "template_images.media"]
    });
    return ApiRequestUtil.handleApiRequestTwo(response);
};

const getTemplatesById = async (templateId) => {
    const url = UtilService.createDynamicUrlForApiService(TemplateUrl.getTemplatesById, {
        id: templateId
    });

    const response = await ApiService.get(url);
    return ApiRequestUtil.handleApiRequest(response);
};

const createTemplate = async (payload) => {
    const response = await ApiService.post(TemplateUrl.createTemplate, payload);

    return ApiRequestUtil.handleApiRequest(response);
};

const deleteTemplateById = async (templateId) => {
    const url = UtilService.createDynamicUrlForApiService(TemplateUrl.deleteTemplateById, {
        id: templateId
    });

    const response = await ApiService.deleteRequest(url);
    return ApiRequestUtil.handleApiRequest(response);
};

const createBuilderData = async (payload) => {
    const response = await ApiService.post(TemplateUrl.createBuilderData, payload);
    return ApiRequestUtil.handleApiRequest(response);
};

const getTemplatesAuth = async (query) => {
    const response = await ApiService.get(TemplateUrl.getTemplatesAuth, query);
    return ApiRequestUtil.handleApiRequest(response);
};
export const TemplateService = {
    getTemplates,
    createTemplate,
    getTemplatesById,
    deleteTemplateById,
    createBuilderData,
    getTemplatesAuth
};

