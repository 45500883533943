import React, { memo } from "react";
import "./CustomSelectHtml.less";

const CustomSelectHtml = (props) => {
    const { type, className } = props;
    let customClass = "";
    switch (type) {
        case "small":
            customClass = "small-dropdown";
            break;

        default:
            break;
    }
    return (
        <span className="kl-custom-select-container">
            <select {...props} className={`${customClass} ${className}`}>
                {props.children}
            </select>
        </span>
    );
};

export default memo(CustomSelectHtml);
