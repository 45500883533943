import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomButton from "Components/CustomButton/CustomButton";
import { addSignatureDateSigned, deleteFieldSigned } from "../../redux/fieldSlice";

function RepeaterFieldsForSignature() {
    const singleFieldId = useSelector((state) => state.fields.singleFieldId);
    const fields = useSelector((state) => state.fields.fields);
    const dispatch = useDispatch();

    const addOptionHandler = (event) => {
        event.preventDefault();

        dispatch(
            addSignatureDateSigned({
                id: singleFieldId
            })
        );
    };

    const removeField = (event, singleRepeaterField) => {
        event.preventDefault();

        dispatch(
            deleteFieldSigned({
                id: singleRepeaterField.id
            })
        );
    };

    const dropdownChildrens = fields.find((singleFieldRedux) => singleFieldRedux.id === singleFieldId)?.childrens || [];

    return (
        <>
            {dropdownChildrens.length > 0 &&
                dropdownChildrens.map((singleRepeaterField, index) => {
                    return (
                        <div
                            className="repeter-row field-sec-signature-signed-container"
                            align="baseline"
                            key={singleRepeaterField?.id || index}
                        >
                            <div className="field-sec-signature-signed">
                                <p>Signed</p>
                                <MinusCircleOutlined
                                    className="repeter-icon"
                                    onClick={(e) => removeField(e, singleRepeaterField)}
                                />
                            </div>
                        </div>
                    );
                })}

            <CustomButton
                type="dashed"
                className="add-field-btn"
                onClick={addOptionHandler}
                block
                icon={<PlusOutlined />}
            >
                Add Signed
            </CustomButton>
        </>
    );
}

export default memo(RepeaterFieldsForSignature);

