export const SIGNATURE_MODAL = `<div class="modal-backdrop" id="modalBackdrop"></div>
<div id="signatureModal" style="display: none;">
  <div class="modal-header">
    <div class="modal-heading">Signature</div>
    <button class="modal-close-button" onClick="closeModal()">X</button>
  </div>
  <div>
    <canvas id="signatureCanvas" width="500" height="200"></canvas>
  </div>
  <div>
    <button onClick="saveSignature(this)">Create</button>
    <button onClick="clearCanvas()">Clear</button>
  </div>
</div>
`