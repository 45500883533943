import { Button, Card } from "antd";
import React, { useMemo, useState } from "react";
import Draggable from "react-draggable";
import "./HTMLEnvelopeFormView.less";
import FormViewSignatureImage from "Container/EnvelopeFormView/FormViewFields/FormViewSignature/FormViewSignatureImage";
import { UtilService } from "util/util.service";
import CustomDropdown from "Components/CustomDropdown/CustomDropdown";
import FormViewSignatureWithoutImage from "Container/EnvelopeFormView/FormViewFields/FormViewSignature/FormViewSignatureWithoutImage";
import CustomResizeable from "Components/DraggableWithResize/CustomResizeable";

function PrintSignatureField({ field, showSignatureModal, trackPositions, setFields, fields }) {
    const isItemDraggable = false;
    const { width, height, isFormViewSignature = false } = field;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isResizeAllowed, setIsResizedAllowed] = useState(false);

    const { reviewer } = useMemo(() => UtilService.urlParameters(), [window.location.search]);
    const review = reviewer && JSON.parse(reviewer);
    const customDropdownProps = {
        options: [
            <Button onClick={(event) => showSignatureModal(event, field)}>Sign</Button>,
            <Button onClick={(event) => setIsResizedAllowed(true)}>Resize</Button>,
            <Button onClick={(event) => resetSignature(event, field)}>Delete</Button>
        ],
        // verticalPosition,
        isDropdownOpen,
        setIsDropdownOpen,
        isResizeAllowed
    };
    const formViewImageDropdownProps = {
        options: [
            <Button onClick={(event) => showSignatureModal(event, field)}>Sign</Button>,
            <Button onClick={(event) => setIsResizedAllowed(true)}>Resize</Button>
        ],
        // verticalPosition,
        isDropdownOpen,
        setIsDropdownOpen,
        isResizeAllowed
    };

    const renderDropDownComponent = !!review ? (
        field.value ? (
            <CustomDropdown {...customDropdownProps}>
                <FormViewSignatureImage width={width} height={height} singleField={field} />
            </CustomDropdown>
        ) : (
            <CustomDropdown {...customDropdownProps}>
                <FormViewSignatureWithoutImage width={width} height={height} singleField={field} />
            </CustomDropdown>
        )
    ) : field.value ? (
        <CustomDropdown {...formViewImageDropdownProps}>
            <FormViewSignatureImage
                width={width}
                height={height}
                singleField={field}
                // onClick={(event) => showSignatureModal(event, singleField)}
            />
        </CustomDropdown>
    ) : (
        <CustomDropdown {...customDropdownProps}>
            <FormViewSignatureWithoutImage
                singleField={field}
                width={width}
                height={height}
                // onClick={createSignatureHandler}
            />
        </CustomDropdown>
    );

    console.log("outside the defauklt");
    const handleCrossBtnSignature = (event) => {
        event.preventDefault();
        setIsResizedAllowed(!isResizeAllowed);
    };

    const handleUpdateField = (singleFieldParam, ref) => {
        const updateField = fields.map((field) => {
            if (field.id === singleFieldParam.id) {
                return {
                    ...field,
                    width: ref.clientWidth,
                    height: ref.clientHeight
                };
            }
            return field;
        });
        setFields(updateField);
    };
    const resetSignature = (event, { id }) => {
        // console.log("event, id", event, id);
        event.preventDefault();
        const updateField = fields.map((field) => {
            if (field.id === id) {
                return {
                    ...field,
                    value: null
                };
            }
            return field;
        });
        setFields(updateField);
    };
    const onResizeStart = (e, direction, ref, d, singleFieldParam) => {
        handleUpdateField(singleFieldParam, ref);
    };

    const onResize = (e, direction, ref, d, singleFieldParam) => {
        handleUpdateField(singleFieldParam, ref);
    };

    const onResizeStopHandle = (e, direction, ref, d, singleFieldParam) => {
        handleUpdateField(singleFieldParam, ref);
    };

    return (
        <>
            {isResizeAllowed ? (
                <Draggable
                    handle=".card-container-signature"
                    onDrag={(e, data) => {
                        e.preventDefault();
                        if (isItemDraggable) {
                            return false;
                        }
                    }}
                    onStop={(e, data) => {
                        e.preventDefault();

                        if (isItemDraggable) {
                            return true;
                        }
                        trackPositions(data, field);
                    }}
                    defaultPosition={{
                        x: field.x,
                        y: field.y
                    }}
                    bounds="parent"
                    // scale={adjustScaleForDraggableAndResize}
                    disabled={isDropdownOpen}
                >
                    <div className="box-draggable " style={{ width, height, zIndex: 1000, position: "absolute" }}>
                        <CustomResizeable
                            singleField={field}
                            onResizeStopHandle={onResizeStopHandle}
                            onResizeStart={onResizeStart}
                            onResize={onResize}
                            isResizeEnableFromProps={isResizeAllowed}
                        >
                            <div style={{ width, height }} className="card-container-signature">
                                <div
                                    className="cross-btn-signature"
                                    onClick={handleCrossBtnSignature}
                                    onTouchStart={handleCrossBtnSignature}
                                >
                                    X
                                </div>
                                {renderDropDownComponent}
                            </div>
                        </CustomResizeable>
                    </div>
                </Draggable>
            ) : (
                <Draggable
                    handle=".card-container-signature"
                    onDrag={(e, data) => {
                        e.preventDefault();
                        if (isItemDraggable) {
                            return false;
                        }
                    }}
                    onStop={(e, data) => {
                        console.log("data: ", data);
                        e.preventDefault();

                        if (isItemDraggable) {
                            return true;
                        }
                        trackPositions(data, field);
                    }}
                    defaultPosition={{
                        x: field.x,
                        y: field.y
                    }}
                    bounds="parent"
                    // scale={adjustScaleForDraggableAndResize}
                    disabled={true}
                >
                    <div className="box-draggable" style={{ width, height, position: "absolute" }}>
                        <div style={{ width, height }} className="card-container-signature">
                            {renderDropDownComponent}
                        </div>
                    </div>
                </Draggable>
            )}
        </>
    );
}

export default PrintSignatureField;
