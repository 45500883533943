import { Card } from "antd";
import React, { memo } from "react";
import Draggable from "react-draggable";
import { UtilService } from "util/util.service";
import "./FormViewSignedDate.less";

function FormViewSignedDate(props) {
    const { singleField } = props;

    const { width, height, isFormViewSignature = false } = singleField;

    const isItemDraggable = false;

    return (
        <Draggable
            handle=".create-signed-date-container"
            onDrag={(e, data) => {
                e.preventDefault();
                if (isItemDraggable) {
                    return false;
                }
            }}
            onStop={(e, data) => {
                e.preventDefault();

                if (isItemDraggable) {
                    return true;
                }
            }}
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            scale={1.8}
            disabled={!isFormViewSignature}
        >
            <div className="box-draggable " style={{ width, height }}>
                <Card style={{ width, height }}>
                    <div
                        className={`create-signed-date-container sign-${singleField.validationStatus}`}
                        style={{ width, height }}
                    >
                        {singleField?.value ? (
                            <span>{UtilService.convertDateToOurFormat(singleField?.value)}</span>
                        ) : (
                            "Signed Date"
                        )}
                    </div>
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewSignedDate);
