import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";

const userServiceUrl = {
    login: "/login",
    register: "/register",
    getUsers: "/users"
};

const login = async (data) => {
    const response = await ApiService.post(userServiceUrl.login, data);
    return ApiRequestUtil.handleApiRequest(response);
};

const register = async (data) => {
    const response = await ApiService.post(userServiceUrl.register, data);
    return ApiRequestUtil.handleApiRequest(response);
};

const getUsers = async () => {
    const response = await ApiService.get(userServiceUrl.getUsers);
    return ApiRequestUtil.handleApiRequest(response);
};

export const UserService = {
    login,
    register,
    getUsers
};
