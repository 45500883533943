import React, { memo } from "react";
import { Button } from "antd";
import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { saveField } from "redux/fieldSlice";
import { UtilService } from "util/util.service";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { defaultFieldObject, fieldDraggleType } from "./constant";

function AddCheckBox({ currentPdfObject }) {
    const dispatch = useDispatch();
    const generateCheckboxFields = () => {
        const unique_id = UtilService.generateUniqueId();
        const groupId = UtilService.generateUniqueId();

        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.checkbox,
            placeholder: "",
            pageNumber: currentPdfObject.pageNumber,
            groupId: `${FIELD_TYPES.checkbox} ${groupId}`,
            mainCheckbox: true,
            childrens: [unique_id],
            width: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.width,
            height: FIELD_STATIC_WIDTH.CHECKBOX_BUTTON.height,
            queryName: `${FIELD_TYPES.checkbox}-${unique_id}`,
            x: 0,
            y: 0
        };
    };
    const [, dragRef] = useDrag({
        type: fieldDraggleType,
        item: () => {
            const checkboxObj = generateCheckboxFields();
            return checkboxObj;
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const onFieldFinish = (values) => {
        const checkboxObj = generateCheckboxFields();
        dispatch(saveField(checkboxObj));
    };
    return (
        <>
            <Button
                ref={dragRef}
                onClick={onFieldFinish}
                className="common-button"
                icon={<i className="paso-checkbox"></i>}
            >
                Check Box
            </Button>
        </>
    );
}

export default memo(AddCheckBox);
