import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { useDrag } from "react-dnd";
import { saveField } from "redux/fieldSlice";
import { UtilService } from "util/util.service";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { FIELD_TYPES } from "util/util.constant";

function AddDate({ currentPdfObject }) {
    // const fields = useSelector((state) => state.fields.fields);
    const dispatch = useDispatch();
    const [, dragRef] = useDrag({
        type: fieldDraggleType,
        item: () => {
            const unique_id = UtilService.generateUniqueId();
            return {
                ...defaultFieldObject,
                id: unique_id,
                type: FIELD_TYPES.datepicker,
                placeholder: FIELD_TYPES.datepicker,
                pageNumber: currentPdfObject.pageNumber
            };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const onFieldFinish = () => {
        const unique_id = UtilService.generateUniqueId();
        const saveFieldData = {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.datepicker,
            placeholder: FIELD_TYPES.datepicker,
            pageNumber: currentPdfObject.pageNumber,
            queryName: `${FIELD_TYPES.datepicker}-${unique_id}`,
            x: 0,
            y: 0
        };
        dispatch(saveField(saveFieldData));
    };
    return (
        <Button ref={dragRef} onClick={onFieldFinish} className="common-button" icon={<i className="paso-date"></i>}>
            Date
        </Button>
    );
}

export default memo(AddDate);
