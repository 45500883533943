/* eslint-disable react-hooks/exhaustive-deps */
import { fieldDraggleType } from "Components/TemplateBuilderWrapper/FormFields/constant";
import React, { memo, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { saveField } from "redux/fieldSlice";
// import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import { UtilService } from "util/util.service";

function CustomDropComponent({ currentPdfObject, children, position }) {
    const dispatch = useDispatch();
    const [screenWidth] = useWindowSize();
    const [axis, setAxis] = useState({
        xAxis: 0,
        yAxis: 0
    });
    // const position = useSelector((state) => state.fields.scrollY);
    const { reviewer } = useMemo(() => UtilService.urlParameters(), [window.location.search]);
    const [, dropRef] = useDrop({
        accept: fieldDraggleType,
        drop: (item) => {
            const modifyFieldPass = {
                ...item,
                pageNumber: currentPdfObject.pageNumber,
                x: axis.xAxis,
                y: axis.yAxis
            };
            dispatch(saveField(modifyFieldPass));
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),

        hover: (item, monitor) => {
            const { x: xAxis, y: yAxis } = monitor.getSourceClientOffset();
            const y =
                currentPdfObject.pageNumber === 1
                    ? position / currentPdfObject.pageNumber
                    : position - 1480.6 * (currentPdfObject.pageNumber - 1) + currentPdfObject.pageNumber;

            // console.log("xAxis: ", xAxis, "yAxis: ", xAxis);
            // console.log("yAxis: ", yAxis, "Conditions: ", (yAxis - 133.140625 + y) / 1.8, y);
            // console.log("I'm Y: ", (yAxis - 133.140625 + y) / 1.8);
            setAxis({
                // xAxis: (xAxis - 460) / 1,
                // yAxis: (yAxis - 133.140625 + y) / 1

                // xAxis:
                //     screenWidth <= 1440 ? xAxis - 440 : screenWidth <= 1880 ? (xAxis - 392) / 1.3 : (xAxis - 460) / 1.8,
                // yAxis:
                //     screenWidth <= 1440
                //         ? yAxis - 130 + y
                //         : screenWidth <= 1880
                //         ? (yAxis - 130 + y) / 1.3
                //         : (yAxis - 133.140625 + y) / 1.8

                xAxis: reviewer
                    ? screenWidth <= 1580
                        ? (xAxis - 210) / 1.4
                        : (xAxis - 310) / 1.8
                    : screenWidth <= 1440
                    ? xAxis - 440
                    : screenWidth <= 1880
                    ? (xAxis - 392) / 1.3
                    : (xAxis - 460) / 1.8,
                yAxis: reviewer
                    ? screenWidth <= 1580
                        ? (yAxis - 110 + y) / 1.4
                        : (yAxis - 110 + y) / 1.8
                    : screenWidth <= 1440
                    ? yAxis - 130 + y
                    : screenWidth <= 1880
                    ? (yAxis - 130 + y) / 1.3
                    : (yAxis - 133.140625 + y) / 1.8
            });
            // const tempXAxis = xAxis - 880 < 0 ? 0 : xAxis - 780;
            // const pageNumberYAxisSetting = 10 * currentPdfObject.pageNumber;

            // const tempYAxis = yAxis - 100 < 0 ? 0 : yAxis - (90 + pageNumberYAxisSetting);

            // setAxis({
            //     xAxis: tempXAxis,
            //     yAxis: tempYAxis
            // });
        }
    });

    return (
        <div style={{ height: "100%", width: "100%" }} className="checking-drop-div" ref={dropRef}>
            {children}
        </div>
    );
}

export default memo(CustomDropComponent);
