import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tabData: null,
    signingData: null
};

export const tabSlice = createSlice({
    name: "tabs",
    initialState,
    reducers: {
        setTabData: (state, action) => {
            state.tabData = action.payload;
        },
        clearTabData: (state) => {
            state.tabData = initialState.tabData;
        },
        setSigningData: (state, action) => {
            state.signingData = action.payload;
        },
        clearSigningData: (state) => {
            state.signingData = initialState.signingData;
        }
    }
});

export const { setTabData, clearTabData, setSigningData, clearSigningData } = tabSlice.actions;
export const selectTabData = (state) => state.tabs.tabData;
export const signData = (state) => state.tabs.signingData;
export default tabSlice.reducer;
