import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import CustomHeading from "Components/CustomHeading/CustomHeading";
import "./ThankYou.less";

const ThankYou = () => {
    return (
        <div className="thankyou">
            <FontAwesomeIcon icon={faCircleCheck} />
            <CustomHeading>Thank You</CustomHeading>
        </div>
    );
};

export default memo(ThankYou);
