import React, { memo } from "react";
import CustomFormItem from "Components/CustomFormItem/CustomFormItem";
import CustomInput from "Components/CustomInput/CustomInput";
import RepeaterFieldForCheckbox from "Components/RepeaterFields/RepeaterFieldForCheckbox";

function RightCheckBoxOption({ singleField }) {
    return (
        <div className="repeter-sec">
            <CustomFormItem
                name="groupName"
                required={false}
                colon={false}
                rules={[
                    {
                        required: true,
                        message: "Please add checkbox group Name!"
                    }
                ]}
            >
                <CustomInput disabled placeholder="Checkbox group name" />
            </CustomFormItem>
            <CustomFormItem name="checkbox-option">
                <RepeaterFieldForCheckbox singleField={singleField} />
            </CustomFormItem>
        </div>
    );
}

export default memo(RightCheckBoxOption);
