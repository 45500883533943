/* eslint-disable react-hooks/exhaustive-deps */
import CustomDropdown from "Components/CustomDropdown/CustomDropdown";
import CustomResizeable from "Components/DraggableWithResize/CustomResizeable";
import { Button, Card } from "antd";
import useWindowSize from "hooks/useWindowSize";
import { memo, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { UtilService } from "util/util.service";
import FormViewSignatureImage from "./FormViewSignatureImage";
import FormViewSignatureWithoutImage from "./FormViewSignatureWithoutImage";

const FormViewSignature = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const {
        trackPositions,
        singleField,
        showSignatureModal,
        addFirstSign,
        firstSign,
        fields,
        updateField,
        disabled = false
    } = props;

    const [isResizeAllowed, setIsResizedAllowed] = useState(false);
    const [signDimensions, setSignDimensions] = useState({
        width: singleField?.width,
        height: singleField?.height
    });

    const [screenWidth] = useWindowSize();

    const adjustScaleForDraggableAndResize = screenWidth <= 1440 ? 1 : screenWidth <= 1880 ? 1.8 : 1.8;
    const { reviewer } = useMemo(() => UtilService.urlParameters(), [window.location.search]);
    const review = reviewer && JSON.parse(reviewer);

    const { isFormViewSignature = false } = singleField;

    const canvas = useRef("");
    const isItemDraggable = false;

    const deleteSignature = (event, field) => {
        event.preventDefault();
        if (field.id) {
            updateField({
                ...field,
                value: ""
            });
        }
    };

    const resetSignature = (event, field) => {
        console.log("deleteSignature", field);
        event.preventDefault();
        if (field.id) {
            updateField({
                ...field,
                value: ""
            });
        }
    };
    const verticalPosition = singleField.y;

    const onResizeStart = (e, direction, ref, d, singleFieldParam) => {
        console.log("onResizeStart", singleFieldParam);
        updateField({
            ...singleFieldParam,
            disableDraggable: true,
            identifier: "resize"
        });
    };

    const onResize = (e, direction, ref, d, singleFieldParam) => {
        updateField({
            ...singleFieldParam,
            disableDraggable: false,
            width: ref.clientWidth,
            height: ref.clientHeight,
            identifier: "resize"
        });
    };

    const onResizeStopHandle = (e, direction, ref, d, singleFieldParam) => {
        const dataPayload = {
            ...singleFieldParam,
            disableDraggable: false,
            width: ref.clientWidth,
            height: ref.clientHeight,
            identifier: "resize"
        };
        updateField(dataPayload);
    };

    const handleCrossBtnSignature = (event) => {
        event.preventDefault();
        setIsResizedAllowed(!isResizeAllowed);
    };
    const dropdownProps = {
        options: disabled
            ? []
            : [
                  <Button onClick={(event) => showSignatureModal(event, singleField)}>Signature</Button>,
                  <Button onClick={(event) => setIsResizedAllowed(true)}>Resize</Button>,
                  <Button onClick={(event) => resetSignature(event, singleField)}>Delete</Button>
              ],
        verticalPosition,
        isDropdownOpen,
        setIsDropdownOpen,
        isResizeAllowed
    };

    if (Boolean(isFormViewSignature)) {
        dropdownProps.options.push(
            <Button onClick={(event) => deleteSignature(event, singleField)}>Delete Field</Button>
        );
    }
    if (Boolean(firstSign)) {
        dropdownProps.options.unshift(
            <Button onClick={(event) => addFirstSign(event, singleField)}>Use Previous Sign</Button>
        );
    }

    const formViewImageDropdownProps = {
        options: disabled
            ? []
            : [
                  <Button onClick={(event) => showSignatureModal(event, singleField)}>Signature</Button>,
                  <Button onClick={(event) => setIsResizedAllowed(true)}>Resize</Button>,
                  <Button onClick={(event) => resetSignature(event, singleField)}>Delete</Button>
              ],
        verticalPosition,
        isDropdownOpen,
        setIsDropdownOpen,
        isResizeAllowed
    };

    if (Boolean(firstSign)) {
        formViewImageDropdownProps.options.unshift(
            <Button onClick={(event) => addFirstSign(event, singleField)}>Use Previous Sign</Button>
        );
    }

    const renderDropDownComponent = (
        <CustomDropdown {...(review ? dropdownProps : formViewImageDropdownProps)}>
            {singleField?.value ? (
                <FormViewSignatureImage
                    width={singleField.width}
                    height={singleField.height}
                    singleField={singleField}
                />
            ) : (
                <FormViewSignatureWithoutImage
                    width={singleField.width}
                    height={singleField.height}
                    singleField={singleField}
                />
            )}
        </CustomDropdown>
    );

    return (
        <>
            <div style={{ display: "none" }}>
                <canvas id="myCanvas" height="100px" ref={canvas}></canvas>
            </div>
            {isResizeAllowed ? (
                <Draggable
                    handle=".card-container-signature"
                    onDrag={(e, data) => {
                        e.preventDefault();
                        if (isItemDraggable) {
                            return false;
                        }
                    }}
                    onStop={(e, data) => {
                        e.preventDefault();

                        if (isItemDraggable) {
                            return true;
                        }
                        trackPositions(data, singleField);
                    }}
                    defaultPosition={{
                        x: singleField.x,
                        y: singleField.y
                    }}
                    bounds="parent"
                    scale={adjustScaleForDraggableAndResize}
                    disabled={isDropdownOpen}
                >
                    <div
                        className="box-draggable "
                        style={{
                            width: singleField.width,
                            height: singleField.height,
                            zIndex: Number(1000 - singleField.y).toFixed(0)
                        }}
                    >
                        <CustomResizeable
                            singleField={singleField}
                            onResizeStopHandle={onResizeStopHandle}
                            onResizeStart={onResizeStart}
                            onResize={onResize}
                            isResizeEnableFromProps={isResizeAllowed}
                        >
                            <Card
                                style={{ width: singleField.width, height: singleField.height }}
                                className="card-container-signature"
                            >
                                <div className="cross-btn-signature" onClick={handleCrossBtnSignature}>
                                    X
                                </div>
                                {renderDropDownComponent}
                            </Card>
                        </CustomResizeable>
                    </div>
                </Draggable>
            ) : (
                <Draggable
                    handle=".card-container-signature"
                    onDrag={(e, data) => {
                        e.preventDefault();
                        if (isItemDraggable) {
                            return false;
                        }
                    }}
                    onStop={(e, data) => {
                        e.preventDefault();

                        if (isItemDraggable) {
                            return true;
                        }
                        trackPositions(data, singleField);
                    }}
                    defaultPosition={{
                        x: singleField.x,
                        y: singleField.y
                    }}
                    bounds="parent"
                    scale={adjustScaleForDraggableAndResize}
                    disabled={true}
                >
                    <div
                        className="box-draggable "
                        style={{
                            width: singleField.width,
                            height: singleField.height,
                            zIndex: Number(1000 - singleField.y).toFixed(0)
                        }}
                    >
                        <Card
                            style={{ width: singleField.width, height: singleField.height }}
                            className="card-container-signature"
                        >
                            {renderDropDownComponent}
                        </Card>
                    </div>
                </Draggable>
            )}
        </>
    );
};

export default memo(FormViewSignature);

