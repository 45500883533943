import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useDrag } from "react-dnd";
import { Button } from "antd";

import { saveField } from "redux/fieldSlice";
import { defaultFieldObject, fieldDraggleType } from "./constant";
import { FIELD_STATIC_WIDTH, FIELD_TYPES } from "util/util.constant";
import { UtilService } from "util/util.service";

function AddRadioButton({ currentPdfObject }) {
    const dispatch = useDispatch();

    const generateRadioBtnField = () => {
        const unique_id = UtilService.generateUniqueId();
        const groupId = UtilService.generateUniqueId();

        return {
            ...defaultFieldObject,
            id: unique_id,
            type: FIELD_TYPES.radiobtn,
            placeholder: "",
            pageNumber: currentPdfObject.pageNumber,
            groupId: `${FIELD_TYPES.radiobtn} ${groupId}`,
            mainRadio: true,
            childrens: [unique_id],
            width: FIELD_STATIC_WIDTH.RADIO_BUTTON.width,
            height: FIELD_STATIC_WIDTH.RADIO_BUTTON.height,
            queryName: `${FIELD_TYPES.radiobtn}-${unique_id}`,
            x: 0,
            y: 0
        };
    };

    const [, dragRef] = useDrag({
        type: fieldDraggleType,
        item: () => {
            const radiobtnObj = generateRadioBtnField();
            return radiobtnObj;
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const onFieldFinish = () => {
        const radiobtnObj = generateRadioBtnField();
        dispatch(saveField(radiobtnObj));
    };
    return (
        <Button
            ref={dragRef}
            onClick={onFieldFinish}
            className="common-button"
            icon={<i className="paso-radiobutton"></i>}
        >
            Radio Button
        </Button>
    );
}

export default memo(AddRadioButton);
