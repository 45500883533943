import { useLayoutEffect, useState } from "react";

function useWindowSize() {
    const [screenWidth, setScreenWidth] = useState(0);
    const [innerWidth, setInnerWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setScreenWidth(window.screen.width);
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener("load", updateSize);
        window.addEventListener("resize", updateSize);
        window.addEventListener("onresize", updateSize);
        updateSize();
        return () => {
            window.removeEventListener("resize", updateSize);
            window.removeEventListener("load", updateSize);
            window.removeEventListener("onresize", updateSize);
        };
    }, []);
    return [screenWidth, innerWidth];
}

export default useWindowSize;
