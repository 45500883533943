import { Button } from "antd";
import React, { memo } from "react";

function CustomButton(props) {
    return (
        <div className="custom-button-container">
            <Button {...props}>{props?.children}</Button>
        </div>
    );
}

export default memo(CustomButton);
