/* eslint-disable react-hooks/exhaustive-deps */
import SignerForm from "Components/SignerForm/SignerForm";
import TemplateBuilderWrapper from "Components/TemplateBuilderWrapper/TemplateBuilderWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FIELD_TYPES, FormViewSec, normalRoutesConstant } from "util/util.constant";
import { UtilService } from "util/util.service";
import "./EnvelopeFormView.less";
import EnvelopeFormViewInner from "./EnvelopeFormViewInner";

function EnvelopeFormView() {
    const signerFields = useSelector((state) => state.signerFields.signerFields);
    const [getParams, setGetParams] = useState({
        name: "",
        email: ""
    });

    const location = useLocation();
    const { data, send_email, is_document_edit = "", showButtons } = UtilService.urlParameters();

    useEffect(() => {
        if (data) {
            const queryNameFields = UtilService.base64toArray(data);
            const findNameField = queryNameFields.find((singleField) => singleField.queryName === FIELD_TYPES.name);
            const findEmailField = queryNameFields.find((singleField) => singleField.queryName === FIELD_TYPES.email);
            setGetParams({
                name: findNameField?.value,
                email: findEmailField?.value
            });
            // console.log("")
            console.log("queryNameFields", findNameField);
            console.log("findEmailField", findEmailField);
        }
    }, []);

    const isEmailAndNameIsExist =
        Boolean(signerFields?.payload?.name || getParams.name) &&
        Boolean(signerFields?.payload?.email || getParams.email);

    const checkCondition = location.pathname === normalRoutesConstant.EnvelopeUserFormView && Boolean(signerFields);

    const multipleCondition = checkCondition || isEmailAndNameIsExist;
    // Boolean(send_email)
    console.log("condition", multipleCondition, Boolean(send_email), Boolean(is_document_edit));
    return multipleCondition || Boolean(send_email) || Boolean(is_document_edit) ? (
        <TemplateBuilderWrapper
            isSignatureDeleteEnable
            className={`template-form-view-container-new-main ${
                location.pathname === normalRoutesConstant.EnvelopeUserFormView ? "" : FormViewSec
            }`}
        >
            <EnvelopeFormViewInner />
        </TemplateBuilderWrapper>
    ) : (
        <SignerForm />
    );
}

export default EnvelopeFormView;

