import { useQueryGetTemplateByAuth } from "queries/template.queries";
import { UtilService } from "util/util.service";
import { notification } from "antd";
import { useEffect, useMemo } from "react";
import { useQueryGetEnvelopeByAuthorization } from "queries/envelope.queries";
import FullPageLoader from "Components/FullPageLoader/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearSigningData, setSigningData } from "../../redux/tabSlice";

function TemplateSigning() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        authorization,
        env: envelopeIdParam,
        action: actionParam,
        data: dataParam,
        isFormEditible,
        reviewer,
        client_api_key = "",
        send_email = "",
        restrictions = "",
        webhookData = "",
        is_document_edit = "",
        showButtons = true
    } = useMemo(() => UtilService.urlParameters(), [window.location.search]);

    useEffect(() => {
        getReq();
    }, []);
    const getReq = () => {
        dispatch(clearSigningData());
    };

    const onSuccessAuthorization = (data) => {
        const { redirectUrl } = data;
        if (!redirectUrl) {
            notification.warning({
                message: "your url is not authorized"
            });
        }
        dispatch(setSigningData(data));

        let redirectUrlTemp = false;

        if (redirectUrl) {
            redirectUrlTemp = `/user${redirectUrl.split("/user")[1]}`;
        }

        if (envelopeIdParam) {
            redirectUrlTemp += `&env=${envelopeIdParam}`;
        }

        if (actionParam) {
            redirectUrlTemp += `&action=${actionParam}`;
        }

        if (dataParam) {
            redirectUrlTemp += `&data=${dataParam}`;
        }

        if (reviewer) {
            redirectUrlTemp += `&reviewer=${reviewer}`;
        }

        if (isFormEditible) {
            redirectUrlTemp += `&isFormEditible=${isFormEditible}`;
        }

        if (Boolean(send_email)) {
            redirectUrlTemp += `&send_email=${send_email}`;
        }

        if (Boolean(client_api_key)) {
            redirectUrlTemp += `&client_api_key=${client_api_key}`;
        }

        if (restrictions) {
            redirectUrlTemp += `&restrictions=${restrictions}`;
        }

        if (is_document_edit) {
            redirectUrlTemp += `&is_document_edit=${is_document_edit}`;
        }
        if (webhookData) {
            redirectUrlTemp += `&webhookData=${webhookData}`;
        }

        redirectUrlTemp += `&showButtons=${showButtons}`;

        // data=W3sicXVlcnlOYW1lIjoiZW1haWwiLCJ2YWx1ZSI6ImVtYWlsQGdtYWlsLmNvbSJ9LHsicXVlcnlOYW1lIjoibmFtZSIsInZhbHVlIjoiTXV6YW1taWwgUmFmYXkifV0=

        navigate(redirectUrlTemp);
        // }
    };

    console.log(" Boolean(envelopeIdParam): ", Boolean(envelopeIdParam));
    const { isLoading: templateAuthLoading } = useQueryGetTemplateByAuth(
        { authorization, client_api_key },
        onSuccessAuthorization,
        Boolean(envelopeIdParam)
    );
    const { isLoading: envelopeAuthLoading } = useQueryGetEnvelopeByAuthorization(
        { authorization, client_api_key },
        onSuccessAuthorization,
        !Boolean(envelopeIdParam)
    );

    if (envelopeAuthLoading || templateAuthLoading) {
        return <FullPageLoader />;
    }

    return null;
}

export default TemplateSigning;
