import React, { memo } from "react";
import { Button, Col, Row } from "antd";
import CustomUpload from "Components/CustomUpload/CustomUpload";

function UploadSign({ getSignatureFromCreateSign, setIsModalOpen, allRecentSign, setAllRecentSign }) {
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const customRequest = async (info) => {
        const basesixtyFourFile = await toBase64(info.file);
        getSignatureFromCreateSign(basesixtyFourFile);
        setAllRecentSign([...allRecentSign, basesixtyFourFile]);
        setIsModalOpen(false);
    };

    const uploadProps = {
        name: "customFile",
        multiple: false,
        isImageUrl: true,
        maxCount: 1,
        showUploadList: false,
        // accept: "application/pdf",
        accept: "*",
        customRequest: customRequest,
        onDrop: customRequest
    };

    return (
        <Row>
            <Col span={24}>
                <CustomUpload {...uploadProps}>
                    <p className="ant-upload-drag-icon"></p>
                    <p className="ant-upload-text">Drag & Drop to Upload File</p>
                    <p className="gx-mt-2">
                        <strong>OR</strong>
                    </p>
                    <Button className="gx-my-3 gx-text-black">Choose File</Button>
                </CustomUpload>
            </Col>
        </Row>
    );
}

export default memo(UploadSign);

