import { Card, Radio } from "antd";
import React, { memo } from "react";
import Draggable from "react-draggable";
import { QUERY_KEYS_NAMES } from "util/util.constant";

function FormViewRadio(props) {
    const {
        singleField,
        trackPositions,
        onRadioBtnChanged,
        errorClassName,
        reviewer,
        onRadioBtnClickHandler,
        disabled = false
    } = props;
    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div className="box-draggable ">
                <Card
                    style={{
                        ...(singleField?.fontSize && { fontSize: singleField?.fontSize })
                    }}
                >
                    <Radio
                        title={QUERY_KEYS_NAMES[singleField?.queryName] || singleField?.queryName}
                        onChange={(event) => onRadioBtnChanged(event, singleField)}
                        value={singleField.placeholder || singleField.id}
                        className={`${errorClassName}`}
                        disabled={disabled || !!reviewer}
                        onClick={(event) => onRadioBtnClickHandler(event, singleField)}
                    >
                        {/* {singleField.placeholder} */}
                    </Radio>
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewRadio);

