import React, { memo } from "react";
import { Card, TimePicker } from "antd";
import Draggable from "react-draggable";
import { FIELD_TYPES, DEFAULT_FONT_SIZE } from "util/util.constant";
import { UtilService } from "util/util.service";
import moment from "moment";

function FormViewTimePicker(props) {
    const { singleField, trackPositions, onTimePickerChangeHandler, type, reviewer, disabled = false } = props;
    const { width, height, fontSize = DEFAULT_FONT_SIZE } = singleField;
    const format = "HH:mm:ss a";
    const textStyle = { width, height, fontSize };
    const zoomreset = () => {
        var viewport = document.querySelector("meta[name='viewport']");
        viewport.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
    };
    const setViewport = () => {
        var viewport = document.querySelector("meta[name='viewport']");
        viewport.content = "width=device-width, initial-scale=1,";
    };
    return (
        <Draggable
            onDrag={(e, data) => trackPositions(data, singleField)}
            handle=".ant-input"
            defaultPosition={{
                x: singleField.x,
                y: singleField.y
            }}
            bounds="parent"
            key={singleField.id}
            disabled
        >
            <div className="box-draggable " style={{ width, height }}>
                <Card style={{ width, height, ...(singleField?.fontSize && { fontSize }) }}>
                    {reviewer ? (
                        <p className="m-0 line-height">
                            <b>
                                {type === FIELD_TYPES.time
                                    ? singleField.value && UtilService.timeFormatFormView(singleField.value)
                                    : singleField.value && UtilService.dateFormatFormView(singleField.value)}
                            </b>
                        </p>
                    ) : (
                        <TimePicker
                            defaultOpenValue={UtilService.timePickerFormatEnvelope()}
                            // picker={type}
                            format="h:mm a"
                            // onFocus={zoomreset}
                            // onBlur={setViewport}
                            use12Hours
                            onChange={(date, dateString) => onTimePickerChangeHandler(date, dateString, singleField)}
                            status={singleField.validationStatus}
                            value={singleField?.value ? moment(singleField?.value, format) : ""}
                            // value={UtilService.timePickerFormatEnvelope(singleField?.value)}
                            disabled={disabled}
                            style={reviewer ? { ...textStyle, pointerEvents: "none" } : textStyle}
                        />
                    )}
                </Card>
            </div>
        </Draggable>
    );
}

export default memo(FormViewTimePicker);

